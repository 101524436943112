import { Stack, Typography } from "@mui/material"
import { t } from "i18next";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Link } from "react-router-dom";
const OrdersHistory = () => {
    return (
        <Link to="/orders">
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant="subtitle1" component="h1" color="text.title">
                    {t('orders_history')}
                </Typography>
                <KeyboardBackspaceOutlinedIcon sx={{ color: 'white' }} />
            </Stack>
        </Link>
    )
}

export default OrdersHistory