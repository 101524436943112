import {useEffect, useRef, useState} from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import {Box} from "@mui/material";

export default  function CurrencyChangesAreaChart(props: {slug: string, symbol: string, duration: number, type: 'pump' | 'dump'}){
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const {type} = props;

    const gradiantColors = type === 'pump' ? [
        [0, 'rgba(22, 199, 132, 0.81)'],
        [1, 'rgba(34, 38, 49, 0.15)']
    ] as any: [
        [0, 'rgba(235, 0, 41, 1)'],
        [1, 'rgba(34, 38, 49, 0.4)']
    ] as any;
    const lineColor = type === 'pump' ? '#16C784' : '#EB0029';

    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: gradiantColors
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },
        chart: {
            backgroundColor: 'rgba(0,0,0,0)',
        },
        series: [{
            type: 'area',
            lineColor: lineColor,
            name: ``,
            data: data
        }]
    }

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);


    useEffect(function(){
        let duration = 'histominute';
        let limit = 288;
        if(props.duration == 1){
            duration = 'histominute'
            limit = 288
        }else if(props.duration == 7){
            duration = 'histohour'
            limit = 168
        }else if(props.duration == 30){
            duration = 'histohour'
            limit = 720
        }else if(props.duration == 360){
            duration = 'histoday'
            limit = 365
        }
        let url = `https://min-api.cryptocompare.com/data/v2/${duration}?fsym=${props.symbol.toUpperCase()}&tsym=USD&limit=${limit}`
        if(props.duration == 1){
            url = url + '&aggregate=5';
        }
        setLoading(true)
        axios.get(url).then(function(resp){
            setData(resp.data.Data.Data.map((r: {time: string, high: string}) => {
                return [r.time, r.high];
            }))
        }).catch(function(err){

        }).finally(function(){
            setLoading(false)
        })
    }, [props.slug, props.duration])
    return (
        <>
            {loading && (
                <Box sx={{textAlign: 'center',}}>در حال بارگذاری...</Box>
            )}
            {!loading && (
                <HighchartsReact
                    containerProps={{ style: { height: "80px" } }}
                    highcharts={Highcharts}
                    options={options}
                    ref={chartComponentRef}
                />
            )}
        </>
    )
}