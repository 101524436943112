import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';
import BitcoinIcon from '../../../assets/bitcoin.png'
import EthIcon from '../../../assets/eth.png'
import CardanoIcon from '../../../assets/cardano.png'
import DogeIcon from '../../../assets/doge.png'
import TronIcon from '../../../assets/tron.png'
import { Stack, Box } from '@mui/system';
import { Typography } from '@mui/material';
function createData(
    level: string,
    volume: string,
    order1: string,
    order2: string,
) {
    return { level, volume, order1, order2 };
}

const rows = [
    createData('سطح 1', 'کمتر از 10 میلیون تومان', '0.25%', '0.25%'),
    createData('سطح 2', 'بین 10 تا 50 میلیون تومان', '0.3%', '0.3%'),
    createData('سطح 3', 'بین 50 تا 100 میلیون تومان', '0.25%', '0.25%'),
    createData('سطح 4', 'بیشتر از 100 میلیون تومان', '0.3%', '0.3%'),
];

export default function Table2Wage() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300, width: 900 }} aria-label="simple table">
                <TableHead sx={{ bgcolor: '#383839', borderRadius: '10px 10px 0px 0px', color: '#fafafa' }}>
                    <TableRow>
                        <TableCell sx={{ color: 'text.secondary', py: 1.5, px: 3 }}>{t('currency')}</TableCell>
                        <TableCell sx={{ color: 'text.secondary', py: 1.5, px: 3 }} align="left">{t('network')}</TableCell>
                        <TableCell sx={{ color: 'text.secondary', py: 1.5, px: 3 }} align="left">{t('low_harvest')}</TableCell>
                        <TableCell sx={{ color: 'text.secondary', py: 1.5, px: 3 }} align="left">{t('wage_harvest')}</TableCell>
                        <TableCell sx={{ color: 'text.secondary', py: 1.5, px: 3 }} align="left">{t('wage_deposit')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'center'} direction={'row'} spacing={1} >
                                <Box sx={{ width: 25 }}>
                                    <img src={BitcoinIcon} alt="bitcoin icon" width="100%" />
                                </Box>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    {t('btc')}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            BITCOIN
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            BTC 0.0015
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            BTC 0.0005
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            BTC 0.0005
                        </TableCell>
                    </TableRow>
                    <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'center'} direction={'row'} spacing={1} >
                                <Box sx={{ width: 25 }}>
                                    <img src={EthIcon} alt="EthIcon" width="100%" />
                                </Box>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    {t('eth')}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 1.5, px: 3 }}>
                            <Stack alignItems={'start'} direction={'column'} spacing={0} >
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    ERC20
                                </Typography>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    BEP20
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            ETH 0.0015
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            ETH 0.0005
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            ETH 0.0005
                        </TableCell>
                    </TableRow>
                    <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'center'} direction={'row'} spacing={1} >
                                <Box sx={{ width: 25 }}>
                                    <img src={CardanoIcon} alt="bitcoin icon" width="100%" />
                                </Box>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    {t('cardano')}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'start'} direction={'column'} spacing={0} >
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    BEP2
                                </Typography>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    BSC
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            ADA 0.0015
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            ADA 0.0005
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            ADA 0.0005
                        </TableCell>
                    </TableRow>
                    <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'center'} direction={'row'} spacing={1} >
                                <Box sx={{ width: 25 }}>
                                    <img src={DogeIcon} alt="bitcoin icon" width="100%" />
                                </Box>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    {t('doge')}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'start'} direction={'column'} spacing={0} >
                                DOGECOIN
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            DOGE 0.0015
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            DOGE 0.0005
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            DOGE 0.0005
                        </TableCell>
                    </TableRow>
                    <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'center'} direction={'row'} spacing={1} >
                                <Box sx={{ width: 25 }}>
                                    <img src={TronIcon} alt="bitcoin icon" width="100%" />
                                </Box>
                                <Typography variant="subtitle1" component="p" color="text.title">
                                    {t('tron')}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            <Stack alignItems={'start'} direction={'column'} spacing={0} >
                                TRON
                            </Stack>
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            TRX 0.0015
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            TRX 0.0005
                        </TableCell>
                        <TableCell align="left" sx={{ py: 3, px: 3 }}>
                            TRX 0.0005
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    );
}
