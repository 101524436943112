import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import KycAlert from "../../components/compound/KycAlert";
import PageTitle from "../../components/compound/PageTitle";
import {Grid} from "@mui/material";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import LoginHistoriesWidget from "../../features/login-histories-widget";

const Logins = () => {
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('login_history')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('login_history')} link={'/dashboard'} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <LoginHistoriesWidget />
                    </Grid>
                </Grid>
            </BackgroundPaper>
        </Page>
    )
}

export default Logins;