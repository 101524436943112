import {Box, Button, Chip, CircularProgress, Container, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Stack} from "@mui/system";
//import images
import BitcoinIcon from '../../assets/bitcoinicon.png';
import BuyFiatIcon from '../../assets/buyfiat.png';
import BankTransferIcon from '../../assets/banktransfer.png';
import TradeIcon from '../../assets/trade-buybtc.png';
import GooglePalyIcon from '../../assets/googlepaly.png';
import AndroidIcon from '../../assets/android.png';
import IosIcon from '../../assets/ios.png';
import QrCodeIcon from '../../assets/qrcode.png';
import BuybitcoinAcc from "../../components/atomic/accardion-buybitcoin";
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {useEffect, useState} from "react";
import {makeFull} from "../../features/footer/FooterSlice";
import {Link as RouterLink, useParams} from 'react-router-dom';
import axios from "axios";
import Toman from "../../components/compound/Toman";
import Percentage from "../../components/compound/Percentage";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";


interface CurrencyInterface{
    cmcId: string;
    cmcRank: string;
    slug: string;
    name: string;
    nameFa: string;
    symbol: string;
    price: string;
    price_toman: string;
    percentChange1h: string;
    percentChange24h: string;
    percentChange7d: string;
    percentChange30d: string;
    percentChange60d: string;
    percentChange90d: string;
    percentChange180d: string;
    maxSupply: string;
    circulatingSupply: string;
    ath: string;
    atl: string;
    high24h: string;
    low24h: string;
    volume24h: string;
    marketCap: string;
    dominance: string;
    raise?: -1 | 0 | 1;
}


const Coin = () => {
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    const [currency, setCurrency] = useState<CurrencyInterface>({
        cmcId: '',
        cmcRank: '',
        slug: '',
        name: '',
        nameFa: '',
        symbol: '',
        price: '',
        price_toman: '',
        percentChange1h: '',
        percentChange24h: '',
        percentChange7d: '',
        percentChange30d: '',
        percentChange60d: '',
        percentChange90d: '',
        percentChange180d: '',
        maxSupply: '',
        circulatingSupply: '',
        ath: '',
        atl: '',
        high24h: '',
        low24h: '',
        volume24h: '',
        marketCap: '',
        dominance: '',
        raise: 0
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [wsUrl, setWsUrl] = useState<string>("");
    const {slug} = useParams();

    useEffect(() => {
        setWsUrl(`wss://ws.ramzarz.news/prices?assets=${slug}`);
    }, [slug])


    useEffect(() => {
        if(!!slug){
            loadCurrency(slug)
        }
    }, [slug]);
    useEffect(() => {

        if(!!wsUrl){
            const ws = new WebSocket(wsUrl);
            ws.onmessage = (event) => {
                try{
                    const updates = JSON.parse(event.data);

                    let currentCurrency = Object.assign({}, currency);
                    Object.keys(updates).forEach((currencyName) => {
                        if(currentCurrency.name.toLowerCase() === currencyName.toLowerCase()){
                            currentCurrency.price_toman = (parseFloat(updates[currencyName]) * (parseFloat(currentCurrency.price_toman) / parseFloat(currentCurrency.price))).toString()

                            currentCurrency.price = updates[currencyName];
                        }
                    })

                    setCurrency(currentCurrency);

                }catch (err){
                    //console.log(err);
                }


            }
            return () => {
                ws.close();
            }
        }
    }, [wsUrl, currency]);


    function loadCurrency(slug: string){
        setLoading(true);
        axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/single/${slug.toLowerCase()}`).then(response => response.data)
            .then((data) => {
                setCurrency(data)
            }).catch((err) => {

        }).finally(() => setLoading(false))
    }
    useEffect(function(){
        dispath(makeFull())
    }, [])

    return loading ? (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
    ) : (
        <Page title={t('buy_currency', {currency: currency.nameFa})}>
            <Box sx={{ bgcolor: 'background.default', pt: 6, pb: 8, textAlign: "center" }}>
                <Container>
                    <Typography variant="h5" component="h1" sx={{ color: "text.title" }}>
                        {t('buy_sell_currency', {currency: currency.nameFa})}
                    </Typography>
                    <Typography variant="subtitle1" component="p" sx={{ color: "text.title", mt: 1 }}>
                        {t('coin_page_subtitle')}
                    </Typography>
                    <Box >
                        <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', mt: .5 }}>
                            <Grid item >
                                <Stack alignItems={'center'} direction={'row'} spacing={2}>
                                    <Box>
                                        <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${currency.cmcId}.png`} alt="bit coin icon" width="48" />
                                    </Box>
                                    <Typography variant="h6" component="p" sx={{ color: "text.title", mt: 1 }}>
                                        {currency.nameFa} / {currency.symbol}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item >
                                <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={2}>
                                    <Typography variant="h6" component="p" sx={{ color: "text.title" }}>
                                        <Toman
                                            irt={currency.price_toman}
                                            usd={currency.price}
                                            symbol='تومان'
                                        />
                                    </Typography>
                                    <Typography color={parseFloat(currency.percentChange24h) > 0 ? 'green' : 'red'}><Percentage value={currency.percentChange24h} /></Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                    </Box>
                    <Stack sx={{ mt: 4 }} direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" justifyContent="center" >
                        <Button component={RouterLink} to={'/exchange/btc'} variant="contained" sx={{ width: '8rem', px: 2 }}>{t('easy_sell_buy')}</Button>
                        <Button component={RouterLink} to={'/trade/btc'} variant="contained" sx={{ width: '8rem', px: 2 }}>{t('professional_transaction')}</Button>
                    </Stack>
                </Container>
            </Box>
            <Box sx={{ bgcolor: 'background.darkBlack', py: 10, px: 2 }}>
                <Container sx={{ bgcolor: 'background.paper', py: 5, borderRadius: '10px' }}>
                    <Typography variant="h6" component="h4" sx={{ color: "text.title" }}>
                        {t('what_is_bitcoin')}
                    </Typography>
                    <Typography variant="subtitle2" component="p" sx={{ color: "text.secondary", mt: 1.5 }}>
                        {t('what_is_bitcoin_paragraph')}
                    </Typography>
                    <Typography variant="h6" component="p" sx={{ color: "text.title", textAlign: 'center', mt: 5, mb: 1 }}>
                        {t('how_buy_btc')}
                    </Typography>
                    <Grid container spacing={3} sx={{ px: { xs: 0, md: 10 }, py: 2 }}>
                        <Grid item sm={6} xs={12} md={4} >
                            <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={BankTransferIcon} />
                                    </Box>
                                    <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center', ml: 2 }}>
                                        {t('bank_transfer')}
                                    </Typography>
                                </Stack>
                                <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                    {t('lorem').substr(0, 150)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4}>
                            <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={BuyFiatIcon} />
                                    </Box>
                                    <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center', ml: 2 }}>
                                        {t('buy_fiat')}
                                    </Typography>
                                </Stack>
                                <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                    {t('lorem').substr(0, 150)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4}>
                            <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={TradeIcon} />
                                    </Box>
                                    <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center', ml: 2 }}>
                                        {t('trade')}
                                    </Typography>
                                </Stack>
                                <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                    {t('lorem').substr(0, 150)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'space-between'} mt={5.5} spacing={2}>
                        <Grid item>
                            <Typography variant="h5" component="h4" sx={{ color: "text.title" }}>
                                {t('buy_bitcoin_app')}
                            </Typography>
                            <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                {t('lorem').substr(0, 92)}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <Typography variant="subtitle2" component="p" sx={{ color: "text.title", textAlign: 'justify' }}>
                                    {t('download_from')}
                                </Typography>
                                <Box>
                                    <img src={GooglePalyIcon} alt={'social media icon'} width="100%" style={{ borderRadius: '10px' }} />
                                </Box>
                                <Box>
                                    <img src={AndroidIcon} alt={'social media icon'} width="100%" style={{ borderRadius: '10px' }} />
                                </Box>
                                <Box>
                                    <img src={IosIcon} alt={'social media icon'} width="100%" style={{ borderRadius: '10px' }} />
                                </Box>
                                <Box>
                                    <img src={QrCodeIcon} alt={'social media icon'} width="100%" style={{ borderRadius: '10px' }} />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 5 }}>
                        <Typography variant="h6" component="h4" sx={{ color: "text.title", mt: 1.5 }}>
                            {t('benefits_bitcoin')}
                        </Typography>
                        <Typography variant="subtitle2" component="p" sx={{ color: "text.secondary", textAlign: 'justify' }}>
                            {t('benefits_bitcoin_paragraph')}
                        </Typography>

                        <Typography variant="h6" component="h4" sx={{ color: "text.title", mt: 1.5 }}>
                            {t('change_acc')}
                        </Typography>
                        <Typography variant="subtitle2" component="p" sx={{ color: "text.secondary", textAlign: 'justify' }}>
                            {t('change_acc_paragraph')}
                        </Typography>

                        <Typography variant="h6" component="h4" sx={{ color: "text.title", mt: 1.5 }}>
                            {t('no_auth_transaction')}
                        </Typography>
                        <Typography variant="subtitle2" component="p" sx={{ color: "text.secondary", textAlign: 'justify' }}>
                            {t('no_auth_transaction_paragraph')}
                        </Typography>

                        <Typography variant="h6" component="h4" sx={{ color: "text.title", mt: 1.5 }}>
                            {t('delete_states')}
                        </Typography>
                        <Typography variant="subtitle2" component="p" sx={{ color: "text.secondary", textAlign: 'justify' }}>
                            {t('delete_states_paragraph')}
                        </Typography>

                        <Typography variant="h6" component="h4" sx={{ color: "text.title", mt: 1.5 }}>
                            {t('easy_cash')}
                        </Typography>
                        <Typography variant="subtitle2" component="p" sx={{ color: "text.secondary", textAlign: 'justify' }}>
                            {t('easy_cash_paragraph')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 5 }}>
                        <Typography variant="h5" component="h4" sx={{ color: "text.title" }}>
                            {t('start_for_buy_btc')}
                        </Typography>
                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: .75, textAlign: 'justify' }}>
                            {t('lorem').substr(0, 93)}
                        </Typography>
                        <Button component={RouterLink} to={`/trade/${currency.symbol.toLowerCase()}`} variant="contained" sx={{ width: '8rem', px: 2, mt: 2 }}>{t('start_now')}</Button>
                    </Box>
                </Container>
                <Container>
                    <Box sx={{mt:7}}>
                        <Typography variant="h4" component="h5" color="text.title" sx={{ textAlign: 'center' }}>
                            {t('faq')}
                        </Typography>
                        <Typography variant="subtitle1" component="h5" color="text.title" sx={{ textAlign: 'center', mt: 2 }}>
                            {t('lorem').substr(0, 58)}
                        </Typography>
                        <BuybitcoinAcc />
                    </Box>
                </Container>
            </Box>
        </Page>
    )
}

export default Coin;