import React from 'react';

//import {Button} from '../../atomic/Button/Button';
import {DirectedLinkButton} from '../../atomic/DirectedLinkButton/DirectedLinkButton';
import './intro.css';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EthersIcon from '../../../assets/ethers.png'
import HomeIntroImg from '../../../assets/home-intro.png'
import {useTranslation} from "react-i18next";
import {Box, Button, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface IntroProps {
  /**
   * What background color to use
   */
  backgroundColor?: string;
  dir?: 'rtl' | 'ltr'
}

export  function Intro(){
    const { t } = useTranslation();
    const theme = useTheme();
    const isFullWidthButtons = useMediaQuery('(max-width:400px)');
    const navigate = useNavigate();
    return (
        <Paper elevation={0} sx={{borderRadius: 0, mx: 0, px: 0, bgcolor: '#212226'}}>
            <Grid container maxWidth='xl' sx={{py: 5, px: 3, mx: 'auto'}}>
                <Grid item md={6} xs={12} sx={{my: 'auto'}}>
                    <Typography variant='h3' sx={{mb: 2}}>
                        <Box component='span' sx={{mr: 1.5}}>{ t('home_page_intro_message') }</Box>
                        <Box component='span' color="primary.main" sx={{whiteSpace: 'nowrap'}}>{ t('home_page_intro_brand') }</Box>
                    </Typography>
                    <Typography variant='h5' color='text.secondary' sx={{mb: 2}}>{ t('home_page_intro_subtitle') }</Typography>
                    <Stack direction={isFullWidthButtons ? 'column' : 'row'} spacing={1}>
                        <Button onClick={() => navigate('/trade')} variant='contained' size='large'>{t('start_buy_sell')}</Button>
                        <DirectedLinkButton onClick={() => navigate('/referral')} variant='outlined' label={t('introduce_us_to_your_friends')}/>
                    </Stack>
                </Grid>
                <Grid item md={6} xs={12} sx={{textAlign: {xs: 'center', md: 'right'}, pt: {xs: 2, md: 0}}}>
                    <Box component='img' sx={{maxWidth: '90%', maxHeight: 'auto'}} src={HomeIntroImg} />
                </Grid>
            </Grid>
        </Paper>
    );
}
