import {Box, Grid, Stack, Typography} from "@mui/material"
import {Container} from "@mui/system";
import TwoSectionTabsWage from "../../components/atomic/twosectiontab-wage";
import ChartCirclePicture from '../../assets/chart-circle.png'
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";

const Wage = () => {
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('wage')}>
            <Box sx={{ bgcolor: 'background.default', textAlign: 'center', pt: 2, pb: 4, position: 'relative' }}>
                <Typography variant="h5" component="h1" color="text.title">
                    {t('wage')}
                </Typography>
            </Box>
            <Box sx={{ bgcolor: 'background.darkBlack', py:4 , px:{xs:1 , sm:4} }}>
                <Container>
                    <Box sx={{ bgcolor: 'background.paper', borderRadius: '10px', py: 4, px: 6 }}>
                        <Grid container spacing={1}>
                            <Grid item md={1.5} sx={{width:'100%' , display:'flex' , justifyContent:{xs:'center' , sm:'start'}}}>
                                <Box sx={{width:'117px'}}>
                                    <img src={ChartCirclePicture} alt="chart icon" width="100%" />
                                </Box>
                            </Grid>
                            <Grid item md={10.5}>
                                <Stack direction={'column'} spacing={2}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ alignItems: { xs: 'start', sm: 'center' } }} spacing={.5}>
                                        <Typography variant="subtitle1" component="p" color="text.title">
                                            {t('wage_level')}
                                        </Typography>
                                        <Typography variant="subtitle1" component="p" color="primary.main">
                                            {t('level1')}
                                        </Typography>
                                        <Typography variant="subtitle2" component="p" color="text.title">
                                            {t('according_level')}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'center'} spacing={.5}>
                                        <Typography variant="subtitle1" component="p" color="text.title">
                                            {t('wage_level_trades_now')}
                                        </Typography>
                                        <Typography variant="subtitle1" component="p" color="primary.main">
                                            {t('number_wage_level_trades_now')}
                                        </Typography>
                                    </Stack>
                                    <Grid container >
                                        <Grid item >
                                            <Typography variant="subtitle2" component="p" color="text.secondary">
                                                {t('Volume_trades_toman')}
                                            </Typography>
                                            <Stack direction={'row'} alignItems={'center'} spacing={.5}>
                                                <Typography variant="subtitle2" component="p" color="text.title">
                                                    {t('Volume_trades_toman_numbers1')}
                                                </Typography>
                                                <Typography variant="subtitle2" component="p" color="primary.main">
                                                    {t('Volume_trades_toman_numbers2')}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item sx={{ ml: { xs: 0, sm: 3 } }}>
                                            <Typography variant="subtitle2" component="p" color="text.secondary">
                                                {t('needed_volume_next_level')}
                                            </Typography>
                                            <Typography variant="subtitle2" component="p" color="text.title" sx={{ textAlign: { xs: 'start', sm: 'end' } }}>
                                                {t('needed_volume_next_level_numbers')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ bgcolor: 'background.paper', borderRadius: '10px', py: 4, px:{xs:1.5 , sm:6} , mt:2 }}>
                        <Box>
                            <Typography variant="subtitle1" component="p" color="text.title">
                                {t('wage_deposit_harvest')}
                            </Typography>
                            <ul className='ul_section_terms'>
                                <li>
                                    <Typography variant="subtitle2" component="p" color="text.title">
                                        {t('wage_deposit_harvest_p1')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2" component="p" color="text.title">
                                        {t('wage_deposit_harvest_p2')}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                        <TwoSectionTabsWage />
                    </Box>
                </Container>
            </Box>
        </Page>
    )
}

export default Wage;