import {Alert, Box, Divider, Paper, Stack} from "@mui/material";
import {ErrorOutline} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {FiatInterface} from "./types";
import client from "../../config/client";
import RImage from "../../assets/r.png";
import {number_format} from "../../config/helpers";

export default function FiatTips(){
    const [t] = useTranslation();
    const [items, setItems] = useState<FiatInterface[]>([]);

    useEffect(() => {
        client.get('/v4/assets/fiats').then((resp) => {
            const data = resp.data.data;

            setItems(data.map((r: any) => {
                return {
                    value: r.symbol,
                    country: 'IRAN',
                    label: r.name,
                    balance: r.total,
                    icon: RImage
                }
            }))
        })
    }, [])

    return (
        <Paper sx={{bgcolor: '#212226', p: 2}}>
            <Stack spacing={1}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>{t('total_balance')}:</Box>
                    <Box>
                        <Box component={'span'} sx={{mx: 1}}>{number_format(items.length > 0 && items[0].balance)}</Box>
                        <Box component={'span'}>{t('toman')}</Box>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>{t('trading')}:</Box>
                    <Box>
                        <Box component={'span'} sx={{mx: 1}}>0</Box>
                        <Box component={'span'}>{t('toman')}</Box>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>{t('min_depositable_value')}:</Box>
                    <Box>
                        <Box component={'span'} sx={{mx: 1}}>10,000</Box>
                        <Box component={'span'}>{t('toman')}</Box>
                    </Box>
                </Box>
            </Stack>
            <Divider sx={{my: 2}} />
            <Alert
                sx={{bgcolor: 'rgba(254, 176, 25, 0.1)', color: '#FEB019'}}
                icon={<ErrorOutline fontSize="inherit" />}
                severity="warning"
                variant="filled">{t('fiat_deposit_tips')}</Alert>
            <ul style={{paddingRight: '16px'}}>
                <li>{t('fiat_rule_1')}</li>
            </ul>
        </Paper>
    );
}