import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Avatar, Divider, Grid, IconButton, Paper} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import client from "../../config/client";
import InternetImg from '../../assets/internet.png';
import SafariImg from '../../assets/safari.png';
import FirefoxImg from '../../assets/firefox.png';
import ChromeImg from '../../assets/chrome.png';
import MicrosoftEdgeImg from '../../assets/microsoft-edge.png';
import {Link as RouterLink} from 'react-router-dom';

interface LoginHistoryInterface{
    agent: string;
    device: string;
    country: string;
    ip: string;
    date: string;
    time: string;
    browser: string;
}
interface LoginHistoryProps extends LoginHistoryInterface{}

function LoginHistory(props: LoginHistoryProps){
    return (
        <Grid container sx={{my: 1}}>
            <Grid item xs={12} sm={6} md={8} sx={{display: 'flex'}}>
                <Box sx={{alignSelf: 'center'}}>
                    <Avatar variant="rounded" sx={{ bgcolor: 'rgba(252,213, 52, 0.1)', borderRadius: '4px' }}>
                        <BrowserIcon name={props.browser} />
                    </Avatar>
                </Box>
                <Box sx={{ml: 1}}>
                    <Typography sx={{overflowX: 'hidden', whiteSpace: 'nowrap'}}>{props.agent}({props.device})</Typography>
                    <Typography sx={{overflowX: 'hidden', whiteSpace: 'nowrap'}}>
                        {!!props.country ? props.country.toUpperCase() : '-'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Box sx={{direction: 'rtl'}}>
                    <Typography>{props.ip}</Typography>
                    <Typography>
                        <span>{props.date}</span>
                        &nbsp;&nbsp;
                        <span>{props.time}</span>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

function BrowserIcon(props: {name: string}){
    const name = props.name.toLowerCase();
    let browser = InternetImg;
    if(name.indexOf('firefox') !== -1){
        browser = FirefoxImg;
    }else if(name.indexOf('chrome') !== -1){
        browser = ChromeImg;
    }else if(name.indexOf('safari') !== -1){
        browser = SafariImg;
    }else if(name.indexOf('edge') !== -1){
        browser = MicrosoftEdgeImg;
    }

    return <img src={browser} />
}

export default function LoginHistoriesWidget(){
    const [t] = useTranslation();
    const [logins, setLogins] = useState<LoginHistoryInterface[]>([]);
    useEffect(() => {
        client.get(`v1/logs/logins-history?perPage=5`).then(function(resp){
           setLogins(resp.data.data.map((record: any) => {
               return {
                   ip: record.ip,
                   agent: record.agent_info.platform,
                   device: record.agent_info.deviceType,
                   browser: record.agent_info.browser,
                   country: record.ip_info.country,
                   date: record.jcreated_at.split(' ')[0],
                   time: record.jcreated_at.split(' ')[1]
               };
           }))
        });
    }, [])
    return (
        <Paper sx={{p: 3, display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Typography>{ t('login_history') }</Typography>
                <IconButton component={RouterLink} to={'/logins'} ><KeyboardBackspaceIcon /></IconButton>
            </Stack>
            <Divider />
            {
                logins.map((login, index) => (
                    <LoginHistory key={index} {...login} />
                ))
            }
            {
                logins.length === 0 && (
                    <Grid container sx={{my: 1}}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography fontSize={'small'} color={'text.secondary'}>{t('no_records_found')}</Typography>
                        </Grid>
                    </Grid>
                )
            }
        </Paper>
    );
}