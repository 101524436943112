import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Box, Button, Grid, MenuItem, Select, Stack, Typography} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Link as RouterLink} from 'react-router-dom';
import {useAppDispatch} from "../../app/hooks";
import {setAccount} from "./depositFormSlice";

interface ItemInterface{
    value: string,
    label: string,
    icon: string
}

export default function CreditCardSelector(props: {items: Array<ItemInterface>, label?: string}){
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<ItemInterface>(() => {
        if(props.items.length) return props.items[0];
        return {
            value: '',
            label: '',
            icon: ''
        };
    });

    function SelectedItem(){
        return (
            <Grid container spacing={1}>
                <Grid item>
                    <img src={value.icon} width="16" />
                </Grid>
                <Grid item flexGrow={1} sx={{textAlign: 'center', my: 'auto'}}>
                    <Typography>{ value?.label }</Typography>
                </Grid>
            </Grid>
        );
    }
    return (
        <Box sx={{mb: 2}}>
            <Stack direction={'row'} sx={{mb: 1}} justifyContent={'space-between'}>
                <Typography sx={{mt: 'auto'}} color={'text.secondary'}><CreditCardIcon />{props.label}</Typography>
                <Button
                    startIcon={<AddCircleIcon />}
                    component={RouterLink}
                    to={'/cards'}
                    size={'small'}>
                    <Box sx={{color: 'text.primary'}} component={'span'}>{t('add')}</Box>
                </Button>
            </Stack>
            <Select
                fullWidth
                value={value}
                size="small"
                renderValue={SelectedItem}
                IconComponent={KeyboardArrowDownIcon}
            >

                {props.items.map((option) => (
                    <MenuItem
                        onClick={(event: any) => {
                            setValue(option)
                            dispatch(setAccount(option.value))
                        }}
                        key={option.value} value={option as any}>
                        <Grid container spacing={1}>
                            <Grid item sx={{alignSelf: 'center'}}>
                                <img src={option.icon} width={32} />
                            </Grid>
                            <Grid item flexGrow={1} sx={{alignSelf: 'center', textAlign: 'right'}}>
                                <Typography>{option.label}</Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}