import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import KycAlert from "../../components/compound/KycAlert";
import PageTitle from "../../components/compound/PageTitle";
import {Avatar, Box, Button, Divider, Grid, Paper, Switch, Typography} from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import GoogleAuthenticatorImage from '../../assets/google-authenticator.png';
import PlainPaper from "../../components/atomic/PlainPaper";
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import {Link as RouterLink} from 'react-router-dom';

function TwoFASetting(){
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Paper sx={{p: 2}}>
            <Box sx={{mb: 2}}>
                <Typography variant={'h5'}>2FA</Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item>
                    <Box>
                        <img src={GoogleAuthenticatorImage} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{mb: 1}}>
                        <Typography variant={'h6'}>{t('google_authenticator')}</Typography>
                    </Box>
                    <Box>
                        <Typography color={'text.secondary'}>{t('google_authenticator_description')}</Typography>
                    </Box>
                </Grid>
                <Grid item flexGrow={1} sx={{textAlign: 'right', mt: 'auto'}}>
                    <Box>
                        <Button size={'large'} variant={'contained'} color={'warning'}>{t('activate')}</Button>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{my: 2}} />
            <Grid container spacing={3}>
                <Grid item>
                    <Box>
                        <PhoneIphoneIcon />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{mb: 1}}>
                        <Typography variant={'h6'}>{t('phone_number')}</Typography>
                    </Box>
                    <Box>
                        <Typography color={'text.secondary'}> فعال سازی ارسال کد تایید هنگام ثبت سفارش به شماره موبایل</Typography>
                    </Box>
                </Grid>
                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                    <Box sx={{ml: 4}}><Switch /></Box>
                </Grid>
            </Grid>
            <Divider sx={{my: 2}} />
            <Grid container spacing={3}>
                <Grid item>
                    <Box>
                        <EmailIcon />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{mb: 1}}>
                        <Typography variant={'h6'}>{t('email')}</Typography>
                    </Box>
                    <Box>
                        <Typography color={'text.secondary'}>فعال سازی ارسال کد تایید هنگام ثبت سفارش به ایمیل</Typography>
                    </Box>
                </Grid>
                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                    <Box sx={{ml: 4}}><Switch defaultChecked/></Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

function ChangePassword(){
    const { t } = useTranslation();
    return (
        <Paper sx={{p: 2}}>
            <Grid container spacing={3}>
                <Grid item>
                    <Box sx={{mb: 1}}>
                        <Typography variant={'h6'}>{t('password')}</Typography>
                    </Box>
                    <Box>
                        <Typography color={'text.secondary'}>{t('change_password')}</Typography>
                    </Box>
                </Grid>
                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                    <Box>
                        <Button component={RouterLink} to={'/change-password'} size={'large'} variant={'contained'} color={'warning'}>{t('change_password')}</Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

function ActiveSessions(){
    const { t } = useTranslation();
    return (
        <Paper sx={{p: 2}}>
            <Box sx={{mb: 2}}>
                <Typography variant={'h6'}>{t('user_account_activities')}</Typography>
            </Box>
            <Paper sx={{bgcolor: 'background.light', py: 0.5, px: 1}}>
                <Grid container>
                    <Grid xs={3}>{t('device')}</Grid>
                    <Grid xs={3}>{t('last_activity_time')}</Grid>
                    <Grid xs={2}>{t('location')}</Grid>
                    <Grid xs={2}>{t('ip')}</Grid>
                    <Grid xs={2}>{t('options')}</Grid>
                </Grid>
            </Paper>
            <PlainPaper >
                <Grid container sx={{my: 2}}>
                    <Grid xs={3}>
                        <Typography><Avatar sx={{display: 'inline', width: 24, height: 24 }} variant="rounded"><PersonalVideoIcon /></Avatar> Chrome v94.0.4606.61 (Windows)</Typography>
                    </Grid>
                    <Grid xs={3}>
                        <Typography>9/3/1401 10:02</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography>IRAN-SHIRAZ</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography>127.0.0.1</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Button size={'small'} variant={'contained'} color={'error'}>{t('delete')}</Button>
                    </Grid>
                </Grid>
            </PlainPaper>
        </Paper>
    );
}

const Security = () => {
    const { t } = useTranslation();
    return (
        <Page title={t('security')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('security')} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <TwoFASetting />
                    </Grid>
                    <Grid item xs={12}>
                        <ChangePassword />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <ActiveSessions />
                    </Grid>*/}
                </Grid>
            </BackgroundPaper>
        </Page>
    )
}

export default Security;