import {Alert, Button, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import client from "../../config/client";
import {Link as RouterLink} from 'react-router-dom';

function Step0(){
    const [t] = useTranslation();
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>{t('loading_please_wait')}</Typography>
        </Stack>
    );
}

function Step1(){
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography>برای ثبت کارت بانکی دکمه زیر را کلیک کنید</Typography>
            <Button component={RouterLink} to={'/cards'}>ثبت کارت بانکی</Button>
        </Stack>
    );
}

function Step2(){
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Alert
                severity="warning"
                icon={false}
                sx={{bgcolor: 'rgba(252, 213, 53, 0.1)', color: '#FAFAFA', textAlign: 'center'}}
                variant="outlined">
                <Typography>اطلاعات کارت بانکی شما با موفقیت تایید شد</Typography>

            </Alert>
        </Stack>
    );
}
export default function BankAccountVerification(){
    const [step, setStep] = useState(0);

    useEffect(() => {
        client.get('/v4/verifications/bank').then((resp)=>{
            if(resp.data.data.status){
                if(resp.data.data.status === 'verified'){
                    setStep(2);
                }else {
                    setStep(1);
                }
            }
        })
    }, [])


    if(step === 0){
        return <Step0 />
    }else if(step === 1){
        return <Step1 />
    }else if(step === 2){
        return <Step2 />
    }

    return <div></div>
}