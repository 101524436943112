import React from 'react';
import { Box, Container, Typography, Stack, Grid, Divider } from "@mui/material";
import { t } from "i18next";
import CoolWalletPro from '../../assets/product_pic.png'
import './shop.css'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';

const Shop = () => {
    return (
        <>
            <Box sx={{ bgcolor: 'background.default', py: 4, textAlign: "center" }}>
                <Typography variant="h4" component="h1" sx={{ color: "text.title" }}>
                    {t('shop')}
                </Typography>
            </Box>
            <Container sx={{ my: 5 }}>
                <Typography variant="h4" component="p" sx={{ color: "text.title", my: 3 }}>
                    {t('all_products')}
                </Typography>
                <Grid container spacing={2.5}>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('cool_wallet_pro')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Shop;