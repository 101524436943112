import {Divider, Grid, IconButton, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import OpenOrders from '../../components/compound/OpenOrders';
import BalanceStat from "../../components/compound/BalanceStat";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import KycAlert from "../../components/compound/KycAlert";
import LoginHistoriesWidget from "../../features/login-histories-widget";
import CurrencyStat from "../../components/CurrencyStat";
import UserInfoCard from "../../components/compound/UserInfoCard";
import {Link as RouterLink} from 'react-router-dom';
import P2pLevel from "../../components/compound/P2pLevel";
import AnnouncementWidget from "../../components/compound/AnnouncementWidget";


export default function Dashboard(){
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('dashboard')}>
            <BackgroundPaper>
                <KycAlert />
                <Box sx={{px: 2}}>
                    <Grid container spacing={2}  sx={{pb: 5, pt: 2}}>
                        <Grid item xs={12}>
                            <UserInfoCard />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{bgcolor: '#222631', borderRadius: '10px', p: 2}}>
                                <Grid container spacing={2}>
                                    <Grid item key={'pym'} xs={12} md={6} lg={3}><CurrencyStat symbol={'PYM'} /></Grid>
                                    <Grid item key={'btc'} xs={12} md={6} lg={3}><CurrencyStat symbol={'BTC'} /></Grid>
                                    <Grid item key={'eth'} xs={12} md={6} lg={3}><CurrencyStat symbol={'ETH'}/></Grid>
                                    <Grid item key={'bnb'} xs={12} md={6} lg={3}><CurrencyStat symbol={'BNB'} /></Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <BalanceStat />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <P2pLevel />
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{display: 'flex'}}>
                            <LoginHistoriesWidget />
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{display: 'flex'}}>
                            <Paper sx={{p: 2, display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography>{ t('account_security') }</Typography>
                                    <IconButton component={RouterLink} to={'/security'}><KeyboardBackspaceIcon /></IconButton>
                                </Stack>
                                <Divider />
                                <Grid container sx={{my: 2}}>
                                    <Grid item xs={12} sx={{display: 'flex'}}>
                                        <Box>
                                            <Brightness1Icon sx={{color: 'white', fontSize: 20}}/>
                                        </Box>
                                        <Box sx={{ml: 1}}>
                                            <Typography>مدارک هویتی ثبت نشده است</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider sx={{borderBottomWidth: '1px'}}/>
                                <Grid container sx={{my: 2}}>
                                    <Grid item xs={12} sx={{display: 'flex'}}>
                                        <Box>
                                            <Brightness1Icon sx={{color: 'red', fontSize: 20}}/>
                                        </Box>
                                        <Box sx={{ml: 1}}>
                                            <Typography>ورود دو عاملی غیرفعال است</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider sx={{borderBottomWidth: '1px'}}/>
                                <Grid container sx={{my: 2}}>
                                    <Grid item xs={12} sx={{display: 'flex'}}>
                                        <Box>
                                            <Brightness1Icon sx={{color: 'green', fontSize: 20}}/>
                                        </Box>
                                        <Box sx={{ml: 1}}>
                                            <Typography>کلید امنیتی</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{display: 'flex'}}>
                            <AnnouncementWidget />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{p: 2}}>
                                <OpenOrders />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </BackgroundPaper>
        </Page>
    );
}
