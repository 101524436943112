import * as React from 'react';
import {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import {useTranslation} from "react-i18next";
import {Box, Tab, Tabs} from "@mui/material";

const MaterialUISwitch = styled(Switch)(({ theme }) => {
    const { t } = useTranslation();
    return {
        width: 164,
        height: 70,
        color: 'white',
        '& .MuiSwitch-switchBase': {
            margin: 1,
            marginTop: '15px',
            padding: 0,
            transform: 'translateX(4px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(70px)',
                '& .MuiSwitch-thumb:before': {
                    content: `"${t('mobile')}"`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#202325' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-input': {
          top: '0px'
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? '#FCD535' : '#001e3c',
            color: 'black',
            width: 80,
            height: 40,
            borderRadius: '20px',
            '&:before': {
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: '25%',
                content: `"${t('email')}"`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#202325' : '#aab4be',
            borderRadius: '30px',
        },
    }
});

function DisabledIcon(){
    return (
        <Box sx={{

        }}>Foo</Box>
    );
}
function CheckedIcon(){
    return (
        <Box sx={{

        }}>Bar</Box>
    );
}

const StyledTabs = styled(Tabs)(({theme}) => {
    return {
        display: 'inline-flex',
        '& .MuiTabs-indicator': {
            display: 'none'
        },
        '& 	.MuiTabs-flexContainer': {
            backgroundColor: '#1B1B1D',
            borderRadius: '50px',
        }
    };
});
const StyledTab = styled(Tab)(({theme}) => {
    return {
        borderRadius: '50px',
        minHeight: '40px',
        padding: '10px!important',
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: 'black'
        }
    };
});

interface TextSwitchProps{
    onLabel: string,
    offLabel: string,
    handleChange: (newVal: 0 | 1) => void,
    defaultValue?: 0 | 1
}
export default function TextSwitch(props: TextSwitchProps) {
    const {defaultValue} = props;
    const [value, setValue] = useState<0 | 1>(function(){
        if(typeof props.defaultValue === "undefined") return 0;
        return props.defaultValue
    });

    useEffect(() => {
        if(typeof defaultValue === "undefined"){
            setValue(0)
        }else{
            setValue(defaultValue)
        }
    }, [defaultValue])

    const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
        setValue(newValue);
        props.handleChange(newValue);
    };

    return (
        <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <StyledTab disableRipple label={props.offLabel} />
            <StyledTab disableRipple label={props.onLabel} />
        </StyledTabs>
    );
}
