import {Button, Container, IconButton, Stack, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import AccardionFaq from "../../components/atomic/accardion-faq";
import './faq.css'
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";


const Faq = () => {
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('faqs')}>
            <Box sx={{ bgcolor: 'background.default', textAlign: 'center', pt: 2, position: 'relative' }}>
                <Typography variant="h5" component="h1" color="text.title">
                    {t('faq')}
                </Typography>
                <Box sx={{ mt: 4 }}>
                    <TextField
                        className="search-input-faq"
                        sx={{ width: { md: '30%', xs: 'auto' } }}
                        placeholder={`${t('ask_your_question')}`}
                        InputProps={{
                            style: {
                                padding: 0
                            },
                            endAdornment: (
                                <IconButton sx={{ transform: 'rotate(85deg)' }}>
                                    <SearchIcon />
                                </IconButton>
                            ),

                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ pb: 8, pt: 2 }} className='background_boxes'>
                <Container >
                    <AccardionFaq />
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography variant="h6" component="h3" color="text.title" sx={{ textAlign: 'center' }}>
                            {t('not_found_answer')}
                        </Typography>
                        <Typography variant="h6" component="h4" color="text.title" sx={{ textAlign: 'center', fontWeight: 300, mt: 1 }}>
                            {t('not_found_answer_paragraph')}
                        </Typography>
                        <Stack sx={{ mt: 2 }} direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="end" justifyContent="end" >
                            <Button variant="contained" sx={{ width: '8rem', px: 2 }}>{t('call_us')}</Button>
                            <Button variant="contained" sx={{ width: '8rem', px: 2 }}>{t('question_form')}</Button>
                        </Stack>
                    </Box>
                </Container>
            </Box>

        </Page>
    )
}

export default Faq;