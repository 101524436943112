import {Grid} from "@mui/material";
import React from "react";
import UserNameCard from "./UserNameCard";
import OtpCard from "./OtpCard";
import {useAppSelector} from "../../app/hooks";
import {selectStep} from "./LoginSlice";
import SecureLink from "../../components/SecureLink";

export default function LoginForm(){
    const step = useAppSelector(selectStep);
    return (
        <Grid container>
            <Grid item xs={12} sx={{textAlign: 'center', mb: 2}}>
                <SecureLink url={'pouyam.com/login'} />
            </Grid>
            <Grid item container xs={12} sm={8} md={6} lg={4} xl={3} sx={{mx: {xs: 2, sm: 'auto'}}}>
                <Grid item xs={12}>
                    { step === 'username' && <UserNameCard />}
                    { step === 'otp' && <OtpCard />}
                </Grid>
            </Grid>
        </Grid>
    );
}