import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import PageTitle from "../../components/compound/PageTitle";
import {
    Alert,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    Paper,
    Snackbar,
    Typography,
    useTheme
} from "@mui/material";
import {useAppDispatch} from "../../app/hooks";
import React, {useEffect, useState} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import MetallicAlert from "../../components/atomic/metallic-alert";
import InfoIcon from '@mui/icons-material/Info';
import PasswordField from "../../components/atomic/PasswordField";
import Page from "../Page";
import {useFormik} from "formik";
import client from "../../config/client";
import {setReferrer, setStep, setTerms, setToken, setUsername} from "../../features/signup-form/SignupSlice";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";


function ValidationMessages(props: {password: string}){
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    const theme = useTheme();
    return (
        <List sx={{fontSize: '10px'}}>
            <ListItem disablePadding disableGutters>
                <ListItemIcon sx={{minWidth: '20px'}}>
                    <CheckCircleOutlineIcon color={'success'} sx={{fontSize: 15}} />
                </ListItemIcon>
                <Typography component={'span'} fontSize={'12px'} color={theme.palette.success.main}>{t('lorem').slice(0, 50)}</Typography>
            </ListItem>
            <ListItem disablePadding disableGutters>
                <ListItemIcon sx={{minWidth: '20px'}}>
                    <HighlightOffIcon color={'error'} sx={{fontSize: 15}} />
                </ListItemIcon>
                <Typography component={'span'} fontSize={'12px'} color={theme.palette.error.main}>{t('lorem').slice(0, 50)}</Typography>
            </ListItem>
            <ListItem disablePadding disableGutters>
                <ListItemIcon sx={{minWidth: '20px'}}>
                    <RadioButtonUncheckedIcon sx={{fontSize: 15}} />
                </ListItemIcon>
                <Typography component={'span'} fontSize={'12px'}>{t('lorem').slice(0, 50)}</Typography>
            </ListItem>
        </List>
    );
}
const ChangePassword = () => {
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    const [errorsSnackbarIsOpen, setErrorsSnackbarIsOpen] = useState(false);
    const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);
    const [errors, setErrors] = useState<Array<string>>([]);

    useEffect(function(){
        dispath(makeDense())
    }, [])

    const validationSchema = yup.object({
        old_password: yup
            .string()
            .required(t('please_enter_old_password') as string),
        password: yup
            .string()
            .required(t('please_enter_password') as string)
            .min(6, t('password_at_least_6_chars') as string),
        password_confirmation: yup
            .string()
            .required(t('please_enter_password_again') as string)
            .oneOf([yup.ref('password')], t('passwords_mismatched') as string),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: '',
            old_password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            setErrors([]);
            client.post('/v1/user/profile/changepassword', values).then(function(resp){
                setSuccessSnackbarIsOpen(true)
            }).catch(function(err){
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors);
                    setErrorsSnackbarIsOpen(true);
                }
            }).finally(function(){
                setSubmitting(false);
            })
        },
    });


    const handleCloseErrorsSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setErrorsSnackbarIsOpen(false);
    };

    return (
        <Page title={t('change_password')}>
            <BackgroundPaper>
                <PageTitle header={t('change_your_password') as string} link={'/security'} title={t('security')} />
                <MetallicAlert icon={<InfoIcon />} sx={{borderRadius: 0, justifyContent: 'center'}} variant={'standard'} color={'warning'}>{t('change_password_desc')}</MetallicAlert>
                <Grid container sx={{pb: 2, px: 1, pt: 4}} spacing={1}>
                    <Grid item xs={12} sm={7} md={5} lg={3} sx={{mx: 'auto'}}>
                        <form onSubmit={formik.handleSubmit}>
                            <Paper sx={{p: 4}}>
                            <Box sx={{mb: 2}}>
                                <Typography sx={{mb: 1}}><LockIcon /> {t('current_password')}</Typography>
                                <PasswordField
                                    id={'old_password'}
                                    name={'old_password'}
                                    onChange={formik.handleChange}
                                    value={formik.values.old_password}
                                    error={formik.touched.old_password && Boolean(formik.errors.old_password)}
                                    helperText={formik.touched.old_password && formik.errors.old_password}
                                />
                            </Box>
                            <Box sx={{mb: 2}}>
                                <Typography sx={{mb: 1}}><LockIcon /> {t('new_password')}</Typography>
                                <PasswordField
                                    id={'password'}
                                    name={'password'}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </Box>
                            <Box sx={{mb: 2}}>
                                <Typography sx={{mb: 1}}><LockIcon /> {t('confirm_new_password')}</Typography>
                                <PasswordField
                                    id={'password_confirmation'}
                                    name={'password_confirmation'}
                                    onChange={formik.handleChange}
                                    value={formik.values.password_confirmation}
                                    error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                    helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                />
                            </Box>
                            <Box sx={{mt: 2}}>
                                {!formik.isSubmitting && <Button type="submit" variant="contained" fullWidth color={'warning'} size='large'>{ t('confirm') }</Button>}
                                {formik.isSubmitting && (
                                    <LoadingButton loading variant="outlined" fullWidth size='large' loadingIndicator={t('validating')}>
                                        { t('loading') }
                                    </LoadingButton>
                                )}
                            </Box>
                        </Paper>
                        </form>
                    </Grid>
                </Grid>
            </BackgroundPaper>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={errorsSnackbarIsOpen} autoHideDuration={6000} onClose={handleCloseErrorsSnackbar}>
                <Alert onClose={handleCloseErrorsSnackbar} severity="error" sx={{ width: '100%', bgcolor: 'error.dark' }}>
                    {errors.length > 0 && errors[0]}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={successSnackbarIsOpen} autoHideDuration={6000} >
                <Alert  severity="success" sx={{ width: '100%', bgcolor: 'success.dark' }}>
                    {t('password_changed_successfully')}
                </Alert>
            </Snackbar>
        </Page>
    )
}

export default ChangePassword;