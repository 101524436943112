import {Outlet} from "react-router-dom";
import React from "react";
import {Box, Stack} from "@mui/material";
import Drawer from "../features/drawer";

export default function WithSidebarPages(){
    return (
        <Stack direction={'row'} flexGrow={1}>
            <Box>
                <Drawer />
            </Box>
            <Box flexGrow={1}>
                <Outlet />
            </Box>
        </Stack>
    );
}