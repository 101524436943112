import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {t} from "i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Avatar, Card, CardContent, Grid} from "@mui/material";
import Level1Icon from "../../assets/user-auth.png";
import Level2Icon from "../../assets/level2-auth.png";
import Level3Icon from "../../assets/level3-auth.png";
import Level4Icon from "../../assets/level4-auth.png";
import TabPanel from "./TabPanel";
import Level1 from "./Level1";
import Level2 from "./Level2";
import Level3 from "./Level3";
import Level4 from "./Level4";
import * as React from "react";
import {useEffect, useState} from "react";
import KycBgImg from "../../assets/kyc-bg.png";
import Percent from "./Percent";
import client from "../../config/client";

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
export default function LevelsContainer(){
    const [value, setValue] = useState(0);
    const [currentLevel, setCurrentLevel] = useState(1);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        client.get('v4/verifications/level').then((resp)=>{
            if(resp?.data?.data?.current_level){
                setCurrentLevel(resp.data.data.current_level)
            }
        })
    }, [])
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={3} sx={{height: '100%'}}>
                    <Card sx={{
                        backgroundImage: `url(${KycBgImg})`,
                        height: '100%'
                    }}>
                        <CardContent>
                            <Box textAlign={'center'} mb={2}>
                                <Typography variant={'h5'} fontWeight={'bold'}>{t('your_current_level_is_zero', {level: currentLevel})}</Typography>
                            </Box>
                            <Box textAlign={'center'}>
                                <Percent level={currentLevel} />
                            </Box>
                            <Tabs
                                sx={{mt: {xs: 4, sm: 3}}}
                                orientation="vertical"
                                value={value}
                                onChange={handleChange}
                                aria-label="icon position tabs example"

                            >
                                <Tab icon={<Avatar sx={{width: 'auto', height: 'auto', borderRadius: '0'}} alt="icon"
                                                   src={Level1Icon}/>} iconPosition="end"
                                     label={`${t('level1')}`} {...a11yProps(0)} />
                                <Tab
                                    icon={<Avatar sx={{width: 'auto', height: 'auto', borderRadius: '0'}} alt="icon"
                                                  src={Level2Icon}/>}
                                    iconPosition="end"
                                    label={`${t('level2')}`}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    icon={<Avatar sx={{width: 'auto', height: 'auto', borderRadius: '0'}} alt="icon"
                                                  src={Level3Icon}/>}
                                    iconPosition="end"
                                    label={`${t('level3')}`}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    icon={<Avatar sx={{width: 'auto', height: 'auto', borderRadius: '0'}} alt="icon"
                                                  src={Level4Icon}/>}
                                    iconPosition="end"
                                    label={`${t('level4')}`}
                                    {...a11yProps(0)}
                                />
                            </Tabs>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8} md={9} sx={{height: '100%'}}>
                    <Card sx={{
                        height: '100%'
                    }}>
                        <CardContent sx={{mb: 10}}>
                            <TabPanel value={value} index={0} >
                                <Level1 />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Level2 />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Level3 />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Level4 />
                            </TabPanel>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}