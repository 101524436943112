import React, {useEffect, useState} from 'react';


import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, CardActionArea} from '@mui/material';
import Button from "@mui/material/Button";
import {Container} from '@mui/system';
import {useTranslation} from 'react-i18next';
import News1Icon from '../../../assets/news1.png';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import axios from "axios";



interface PostInterface{
    id: number;
    link: string;
    title: {
        rendered: string;
    },
    date: string;
    featured_media: number;
}

interface WodpressMediaInterface{
    source_url: string;
}
function WordpressMedia(props: {mid: number}){
    const [media, setMedia] = useState<WodpressMediaInterface>({source_url: ''});

    useEffect(() => {
        axios.get(`https://pouyam.com/blog/wp-json/wp/v2/media/${props.mid}`).then((resp) => {
            setMedia(resp.data)
        })
    }, [])
    return (
        <CardMedia
            component="img"
            height="140"
            image={media.source_url ? media.source_url : News1Icon}
            alt="green iguana"
        />
    );
}
export function GridLatestPosts() {
    const { t } = useTranslation();
    const [posts, setPosts] = useState<PostInterface[]>([])

    useEffect(() => {
        axios.get('https://pouyam.com/blog/wp-json/wp/v2/posts?per_page=12&page=1&_embed=1').then((resp) => {
            setPosts(resp.data)
        })
    }, [])
    return (
        <Paper elevation={0} sx={{px: 0, mx: 0, borderRadius: 0, bgcolor: '#1B1B1D'}}>
            <Container maxWidth='xl'>
                <Stack direction='column'>
                    <Grid>
                        <Grid xs item>
                            <Paper elevation={0} component='h2' sx={{textAlign: 'center', mb: 0, bgcolor: 'inherit'}}>{t('crypto_news')}</Paper>
                        </Grid>
                        <Grid xs item>
                            <Paper elevation={0} component='h3' sx={{textAlign: 'center', color: 'text.secondary', mt: 0, bgcolor: 'inherit'}}>{t('crypo_news_subtitle')}</Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        {
                            posts.map((post) =>
                                <Grid key={post.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
                                    <Card sx={{borderRadius: '10px'}}>
                                        <CardActionArea href={post.link}>
                                            <WordpressMedia mid={post.featured_media} />
                                            <CardContent>
                                                <Typography variant="body2">
                                                    {post.title.rendered}
                                                </Typography>
                                                <Typography sx={{mt: 1}} gutterBottom color="text.secondary" variant="subtitle2" fontSize='small' component="div">
                                                    {post.date}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Box  justifyContent="center" alignItems="center" sx={{display: 'flex', my: 2}}>
                    <Button href={'/blog'} target={'_blank'} sx={{color: '#1E88E5', fontWeight: 'bold', border: 'none'}} variant='text' endIcon={<ArrowBackIos />}>{ t('view_more') }</Button>
                    </Box>
                </Stack>
            </Container>
        </Paper>
    );
}
