import {Box, Stack} from "@mui/system"
import {Grid, Typography} from "@mui/material"
import {t} from "i18next";
import DottedBorderIcon from '../../assets/dotted-auth-boder.png'
import IdCardIcon from '../../assets/id-card.png'
import AcceptIcon from '../../assets/ok_icon.png'
import PhoneIcon from '../../assets/auth-phone-ring.png'


const Level4 = () => {
    return (
        <Box sx={{ bgcolor: 'background.paper', py: 4, px: 4 }}>
            <Grid container alignItems={'center'} direction={'row'} justifyContent={{ xs: 'start', sm: 'center' }} spacing={3} sx={{ borderBottom: '1px solid #fff', py: 2 }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Box >
                        <img src={IdCardIcon} alt='phone icon' />
                    </Box>
                    <Typography variant="subtitle2" component="h4" sx={{ color: "text.title" }}>
                        {t('id_card')}
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1.5}>
                    <Box>
                        <img src={AcceptIcon} alt="accept icon" width='100%' />
                    </Box>
                    <Typography variant="subtitle1" component="h4" sx={{ color: "text.title", textAlign: 'center', pb: 1 }}>
                        {t('your_id_card_accepted')}
                    </Typography>
                </Stack>
            </Box>
        </Box>
    )
}

export default Level4