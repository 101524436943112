import {useTranslation} from "react-i18next";
import {Box, Button, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {setAmount} from "./depositFormSlice";

export default function DepositAmount(){
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<string | number>('' );
    function handleChangeValue(value: string){
        setValue(value);
        dispatch(setAmount(value));
    }

    return (
        <Box sx={{mb: 2}}>
            <Stack direction={'row'} sx={{mb: 1}} justifyContent={'space-between'}>
                <Typography sx={{mt: 'auto'}} color={'text.secondary'}><AttachMoneyIcon /> {t('deposit_amount')}</Typography>
            </Stack>
            <TextField
                fullWidth
                size={'small'}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeValue(event.target.value);
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">{t('toman')}</InputAdornment>,
                }}
                variant="outlined" />
            <Stack direction={'row'} spacing={2} sx={{mt: 1}}>
                <Button onClick={() => handleChangeValue('50000')} fullWidth size={'small'} variant={'outlined'}>50,000</Button>
                <Button onClick={() => handleChangeValue('100000')} fullWidth size={'small'} variant={'outlined'}>100,000</Button>
                <Button onClick={() => handleChangeValue('500000')} fullWidth size={'small'} variant={'outlined'}>500,000</Button>
            </Stack>
        </Box>
    );
}