import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import Home from "../pages/home";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Wallet from "../pages/wallet";
import Deposit from "../pages/deposit";
import AboutUs from "../pages/about-us";
import Contact from "../pages/contact";
import Terms from "../pages/terms";
import Faq from "../pages/faq";
import Settings from "../pages/settings";
import Security from "../pages/security";
import Notifications from "../pages/notifications";
import Markets from "../pages/markets";
import Wage from "../pages/wage";
import ChangePassword from "../pages/change-password";
import Referral from "../pages/referral";
import Guid from "../pages/guid";
import GuidSections from "../pages/guid-sections";
import Trade from "../pages/trade";
import WithoutSidebarPages from "../components/WithoutSidebarPages";
import WithSidebarPages from "../components/WithSidebarPages";
import Signup from "../pages/signup";
import Forget from "../pages/forget";
import Product from "../pages/product";
import Shop from "../pages/shop";
import AuthUser from "../components/AuthUser";
import AuthGuest from "../components/AuthGuest";
import Coin from "../pages/coin";
import Exchange from "../pages/exchange";
import Logins from "../pages/logins";
import Cards from "../pages/cards";
import Transactions from "../pages/transactions";
import Withdraw from "../pages/withdraw";
import Kyc from "../pages/kyc";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
              path: "/",
              element: <WithoutSidebarPages />,
              children: [
                  {
                      path: "/",
                      element: <Home />
                  },
                  {
                      path: "/",
                      element: <AuthGuest />,
                      children: [
                          {
                              path: "/login",
                              element: <Login />
                          },
                          {
                              path: "/forget",
                              element: <Forget />
                          },
                          {
                              path: "/signup",
                              element: <Signup />
                          }
                      ]
                  },
                  {
                      path: "/",
                      element: <AuthUser />,
                      children: [
                          {
                              path: "/kyc",
                              element: <Kyc />
                          },
                          {
                              path: "/change-password",
                              element: <ChangePassword />
                          },
                      ]
                  },
                  {
                      path: "/aboutus",
                      element: <AboutUs />
                  },
                  {
                      path: "/contact",
                      element: <Contact />
                  },
                  {
                      path: "/terms",
                      element: <Terms />
                  },
                  {
                      path: "/faq",
                      element: <Faq />
                  },
                  {
                      path: "/coins/:slug",
                      element: <Coin />
                  },
                  {
                      path: "/markets",
                      element: <Markets />
                  },
                  {
                      path: "/wage",
                      element: <Wage />
                  },

                  {
                      path: "/referral",
                      element: <Referral />
                  },
                  {
                      path: "/guid",
                      element: <Guid />
                  },
                  {
                      path: "/guidsections",
                      element: <GuidSections />
                  },
                  {
                      path: "/trade/:abbr?",
                      element: <Trade />
                  },
                  {
                      path: "/exchange/:slug",
                      element: <Exchange />
                  },
                  {
                      path: "/shop",
                      element: <Shop />
                  },
                  {
                      path: "/product",
                      element: <Product />
                  }
              ]
            },
            {
                path: "/",
                element: <WithSidebarPages />,
                children: [
                    {
                        path: "/",
                        element: <AuthUser />,
                        children: [
                            {
                                path: "/deposit/:symbol?",
                                element: <Deposit />
                            },
                            {
                                path: "/withdraw/:symbol?",
                                element: <Withdraw />
                            },
                            {
                                path: "/dashboard",
                                element: <Dashboard />
                            },
                            {
                                path: "/logins",
                                element: <Logins />
                            },
                            {
                                path: "/wallet",
                                element: <Wallet />
                            },
                            {
                                path: "/settings",
                                element: <Settings />
                            },
                            {
                                path: "/security",
                                element: <Security />
                            },
                            {
                                path: "/cards",
                                element: <Cards />
                            },
                            {
                                path: "/notifications",
                                element: <Notifications />
                            },
                            {
                                path: "/transactions",
                                element: <Transactions />
                            },
                        ]
                    },
                ]
            },
        ]
    },
]);

export default router;
