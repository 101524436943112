import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import {Container, Divider, Paper} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {t} from "i18next";


interface CategorizedFaqsProps {
    /**
     * What background color to use
     */
    backgroundColor?: string;
    dir?: 'rtl' | 'ltr'
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };



    return (
        <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={9}  >
                <Accordion disableGutters sx={{ borderRadius: '10px !important', p: 1, m: 2 }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ borderRadius: '10px' }}
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 },{'&::before': { height: 0},}]} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 },{'&::before': { height: 0},}]} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 },{'&::before': { height: 0},}]} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default function BuybitcoinAcc() {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Paper elevation={0} sx={{ mx: 0, px: 0, bgcolor: 'inherit' }} className="CategorizedFaqs">
            <Container maxWidth='lg' >
                <Box sx={{ pb: 3 , pt:0}}>
                    <TabPanel value={value} index={0}>
                        <ControlledAccordions />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ControlledAccordions />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ControlledAccordions />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <ControlledAccordions />
                    </TabPanel>
                   
                </Box>
            </Container>
        </Paper>
    );
}

