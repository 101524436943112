import React from "react";
import Paper, {PaperProps} from "@mui/material/Paper";
import {styled} from "@mui/material/styles";

const BackgroundPaper = styled(Paper)<PaperProps>(( theme ) => ({
    mx: 0,
    px: 0,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: '#1B1B1D'
}));

export default BackgroundPaper;