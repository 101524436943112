import {Container, Stack, Typography} from "@mui/material";
import {Box} from "@mui/system";
import Grid from '@mui/material/Grid';
//import pictures
import SecurityImage from '../../assets/security.png';
import GoodTeamImage from '../../assets/users 1-aboutus.png';
import CustomersImage from '../../assets/handshake-simple 1-aboutus.png';
import SupportImage from '../../assets/support.png';
import ListCoinsImage from '../../assets/bitcoin-sign 2-aboutus.png';
import EasyuseImage from '../../assets/easyuse.png';
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";


const AboutUs = () => {
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('about_us')}>
                <Box sx={{bgcolor: 'background.default' , textAlign:'center' , py:2}}>
                    <Typography variant="h5" component="h1" color="text.title">
                        {t('about_us')}
                    </Typography>
                </Box>
            <Box sx={{ bgcolor: "background.darkBlack", pb: 4 }}>
                <Container>
                    <Box sx={{ py: 7 }}>
                        <Typography variant="h5" component="h2" sx={{ color: "text.title", textAlign: 'center' }}>
                            {t('more_about_pouyam')}
                        </Typography>
                        <Typography  sx={{ color: "text.title", mt: 2 }} >
                            {t('introduce_pouyam_paragraph')}
                        </Typography>
                    </Box>

                    <Box sx={{ bgcolor: 'background.paper', borderRadius: '10px', py: 2 }}>
                        <Typography variant="h5" sx={{ color: "text.title", p: 3, textAlign: 'center' }}>
                            {t('why_pouyam')}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2} sx={{ px: 4, py: 2 }}>
                                <Grid item sm={6} xs={12} md={4}>
                                    <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                        <Stack direction="row" spacing={2}   alignItems="center">
                                            <Box>
                                                <img src={SecurityImage} />
                                            </Box>
                                            <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3',  textAlign: 'center', ml: 2 }}>
                                                {t('security')}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                            {t('security_paragraph')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12} md={4}>
                                    <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                        <Stack direction="row" spacing={2}  alignItems="center">
                                            <Box>
                                                <img src={EasyuseImage} />
                                            </Box>
                                            <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center', ml: 2 }}>
                                                {t('easy_use')}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                            {t('security_paragraph')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12} md={4}>
                                    <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                        <Stack direction="row" spacing={2}   alignItems="center">
                                            <Box>
                                                <img src={ListCoinsImage} />
                                            </Box>
                                            <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center' , ml: 2 }}>
                                                {t('list_of_coins')}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                            {t('security_paragraph')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12} md={4}>
                                    <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                        <Stack direction="row" spacing={2}   alignItems="center">
                                            <Box>
                                                <img src={SupportImage} />
                                            </Box>
                                            <Typography variant="h6" component="span" sx={{ color: "text.title",  p: '3', textAlign: 'center', ml: 2 }}>
                                                {t('support')}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                            {t('security_paragraph')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12} md={4}>
                                    <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                        <Stack direction="row" spacing={2}   alignItems="center">
                                            <Box>
                                                <img src={CustomersImage} />
                                            </Box>
                                            <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center', ml: 2 }}>
                                                {t('customers_Satisfaction')}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                            {t('security_paragraph')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12} md={4}>
                                    <Box sx={{ bgcolor: 'background.default', p: 4, borderRadius: '10px' }}>
                                        <Stack direction="row" spacing={2}   alignItems="center">
                                            <Box>
                                                <img src={GoodTeamImage} />
                                            </Box>
                                            <Typography variant="h6" component="span" sx={{ color: "text.title", p: '3', textAlign: 'center', ml: 2 }}>
                                                {t('good_team')}
                                            </Typography>
                                        </Stack>
                                        <Typography variant="subtitle2" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                            {t('security_paragraph')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Box sx={{ pl: 2, mt: 4 }}>
                                    <Typography variant="h5" sx={{ color: "text.title", mt: 2, textAlign: 'center' }}>
                                        {t('how_pouyam_works')}
                                    </Typography>
                                    <Typography variant="h6"  component="p" sx={{ color: "#B7BDC6", mt: 2, fontSize: '16px' }}>
                                        {t('how_pouyam_works_paragraph')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>

                    </Box>

                </Container>
            </Box>
        </Page>
    )
}

export default AboutUs;
