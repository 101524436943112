import React, {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useAppSelector} from "../app/hooks";
import {selectIsLoggedIn} from "../features/auth/authSlice";
export default function AuthUser(){
    const isLoggedIn = useAppSelector(selectIsLoggedIn)
    const navigate = useNavigate();
    useEffect(function(){
        if(!isLoggedIn){
            navigate('/login');
        }
    }, [isLoggedIn])
    return (
        <React.Fragment>
            <Outlet />
        </React.Fragment>
    );
}