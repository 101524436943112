import React from "react";
import './AuthPage3.css'
import {Box, Stack} from "@mui/system";
import PhoneIcon from "../../assets/auth-phone-ring.png";
import {Typography} from "@mui/material";
import {t} from "i18next";
import IdentityVerification from "./IdentityVerification";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const Level3 = () => {
    return (
        <Box sx={{ bgcolor: 'background.paper', py: 4, px: 4 }}>
            <Stack alignItems={'center'} direction={'row'} justifyContent={'center'} spacing={3} sx={{ borderBottom: '1px solid #fff', py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Box >
                        <CameraAltIcon />
                    </Box>
                    <Typography variant="subtitle2" component="h4" sx={{ color: "text.title" }}>
                        سلفی
                    </Typography>
                </Box>
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
                <IdentityVerification />
            </Box>
        </Box>
    )
}

export default Level3