import {useTranslation} from "react-i18next";
import {Avatar, Grid, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import GoldButton from "../../atomic/GoldButton";
import {useEffect, useState} from "react";
import client from "../../../config/client";

interface InfoInterface{
    name: string;
    email: string;
    username: string;
    level: string;
    last_login_date: string;
    last_login_ip: string;
    last_login_country: string;
}
export default function UserInfoCard(){
    const { t } = useTranslation();
    const [info, setInfo] = useState<InfoInterface>({
        name: '-',
        email: '-',
        username: '-',
        level: '-',
        last_login_date: '-',
        last_login_ip: '-',
        last_login_country: '-'
    });
    useEffect(() => {
        client.get(`/v4/users/info`).then(function(resp){
            setInfo(resp.data.data);
        })
    }, [])
    return (
        <Paper sx={{bgcolor: '#222631', borderRadius: '10px', p: 3}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3} sx={{display: 'flex'}}>
                    <Box sx={{mr: 1}}>
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                    </Box>
                    <Box>
                        <Typography sx={{mb: 1}}>{info.name}</Typography>
                        <Typography>{info.email}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography sx={{mb: 1}}>
                        <Box component={'span'} color={'text.secondary'}>{ t('username') }:</Box> {info.username}
                    </Typography>
                    <Typography>
                        <Box component={'span'} color={'text.secondary'}>{ t('level') }:</Box> {info.level}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography sx={{mb: 1}}>
                        <Box component={'span'} color={'text.secondary'}>{ t('last_login_time') }</Box>: <span>{info.last_login_date}</span>
                    </Typography>
                    <Typography>
                        <Box component={'span'} color={'text.secondary'}>{ t('ip') }</Box>: <span>{info.last_login_ip}</span> <span>{info.last_login_country}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{my: 'auto', textAlign: 'right'}}>
                    <GoldButton href={'/referral'} size={'large'} variant={'contained'}>{ t('introduce_us_to_your_friends') }</GoldButton>
                </Grid>
            </Grid>
        </Paper>
    );
}