import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';

function createData(
    level: string,
    volume: string,
    order1: string,
    order2: string,
) {
    return { level, volume, order1, order2 };
}

const rows = [
    createData('سطح 1', 'کمتر از 10 میلیون تومان', '0.25%', '0.25%'),
    createData('سطح 2', 'بین 10 تا 50 میلیون تومان', '0.3%','0.3%'),
    createData('سطح 3', 'بین 50 تا 100 میلیون تومان', '0.25%', '0.25%'),
    createData('سطح 4', 'بیشتر از 100 میلیون تومان', '0.3%','0.3%'),
];

export default function Table1Wage() {
    return (
        <TableContainer component={Paper} sx={{ mt:1}}>
            <Table sx={{ }} aria-label="simple table">
                <TableHead sx={{ bgcolor: '#383839', borderRadius:'10px 10px 0px 0px' , color:'#fafafa'}}>
                    <TableRow>
                        <TableCell sx={{color:'text.secondary' , py:1.5 , px:3}}>{t('level')}</TableCell>
                        <TableCell sx={{color:'text.secondary' , py:1.5 , px:3}} align="left">{t('colume_trades_last_30_days')}</TableCell>
                        <TableCell sx={{color:'text.secondary' , py:1.5 , px:3}} align="left">{t('wage_order_1')}</TableCell>
                        <TableCell sx={{color:'text.secondary' , py:1.5 , px:3}} align="left">{t('wage_order_2')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.level}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" sx={{py:3 , px:3}}>{row.level}</TableCell>
                            <TableCell align="left" sx={{py:3 , px:3}}>{row.volume}</TableCell>
                            <TableCell align="left" sx={{py:3 , px:3}}>{row.order1}</TableCell>
                            <TableCell align="left" sx={{py:3 , px:3}}>{row.order2}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
