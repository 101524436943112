import React from 'react';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {default as Accordion, AccordionProps} from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import {Container, Divider, Paper} from '@mui/material';
import './CategorizedFaqs.css';
import {useTranslation} from 'react-i18next';
import styled from "@emotion/styled";

interface CategorizedFaqsProps {
  /**
   * What background color to use
   */
  backgroundColor?: string;
  dir?: 'rtl' | 'ltr'
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const RoundedAccordion = styled(Accordion)<AccordionProps>(({theme}) => {
    return {
        borderRadius: '10px',
        padding: '8px',
        margin: '16px',
        '&:before': {height: '0px!important'},
        '&:last-of-type':{
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px'
        },
        '&:first-of-type':{
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px'
        }
    }
})

export function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

        

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <RoundedAccordion disableGutters expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                        چه رمز ارزهایی را میتوانیم به یکدیگر تبدیل کنیم؟
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{borderColor: 'text.primary', borderBottomWidth: '1px'}} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                        در حال حاضر در پویام امکان تبدیل به بیت کوین، لایت کوین، اتریوم ،تتر ریپل، بایننس کوین، استلار، ایاس و بالعکس وجود دارد. همچنین تبدیل رمز ارزها به رمزارز تتر و بالعکس نیز در قالب بازار حرفه ای خواهد بود. آینده نزدیک، رمزارزهای بیشتری به پویام اضافه خواهد شد
                        </Typography>
                    </AccordionDetails>
                </RoundedAccordion>
                <RoundedAccordion disableGutters expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                        چه رمز ارزهایی را میتوانیم به یکدیگر تبدیل کنیم؟
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{borderColor: 'text.primary', borderBottomWidth: '1px'}} />
                    <AccordionDetails>
                    <Typography variant='body2'>
                        در حال حاضر در پویام امکان تبدیل به بیت کوین، لایت کوین، اتریوم ،تتر ریپل، بایننس کوین، استلار، ایاس و بالعکس وجود دارد. همچنین تبدیل رمز ارزها به رمزارز تتر و بالعکس نیز در قالب بازار حرفه ای خواهد بود. آینده نزدیک، رمزارزهای بیشتری به پویام اضافه خواهد شد
                        </Typography>
                    </AccordionDetails>
                </RoundedAccordion>
            </Grid>
            <Grid item xs={12} md={6}>
                <RoundedAccordion disableGutters  expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                        چه رمز ارزهایی را میتوانیم به یکدیگر تبدیل کنیم؟
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{borderColor: 'text.primary', borderBottomWidth: '1px'}} />
                    <AccordionDetails>
                    <Typography variant='body2'>
                        در حال حاضر در پویام امکان تبدیل به بیت کوین، لایت کوین، اتریوم ،تتر ریپل، بایننس کوین، استلار، ایاس و بالعکس وجود دارد. همچنین تبدیل رمز ارزها به رمزارز تتر و بالعکس نیز در قالب بازار حرفه ای خواهد بود. آینده نزدیک، رمزارزهای بیشتری به پویام اضافه خواهد شد
                        </Typography>
                    </AccordionDetails>
                </RoundedAccordion>
                <RoundedAccordion disableGutters  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                        چه رمز ارزهایی را میتوانیم به یکدیگر تبدیل کنیم؟
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{borderColor: 'text.primary', borderBottomWidth: '1px'}} />
                    <AccordionDetails>
                    <Typography variant='body2'>
                        در حال حاضر در پویام امکان تبدیل به بیت کوین، لایت کوین، اتریوم ،تتر ریپل، بایننس کوین، استلار، ایاس و بالعکس وجود دارد. همچنین تبدیل رمز ارزها به رمزارز تتر و بالعکس نیز در قالب بازار حرفه ای خواهد بود. آینده نزدیک، رمزارزهای بیشتری به پویام اضافه خواهد شد
                        </Typography>
                    </AccordionDetails>
                </RoundedAccordion>
            </Grid>
        </Grid>
    );
}

export function CategorizedFaqs(){
    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Paper elevation={0} sx={{mx: 0, px: 0, borderRadius: 0, bgcolor: '#1B1B1D'}} className="CategorizedFaqs">
            <Container maxWidth='lg'>
                <Box sx={{py: 3}}>
                    <Paper sx={{textAlign: 'center', bgcolor: 'inherit'}} elevation={0} component="h2">{t('faqs')}</Paper>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="گروه اول" {...a11yProps(0)} />
                            <Tab label="گروه دوم" {...a11yProps(1)} />
                            <Tab label="گروه سوم" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ControlledAccordions />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ControlledAccordions />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ControlledAccordions />
                    </TabPanel>
                </Box>
            </Container>
        </Paper>
    );
}

