import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Divider, Grid, IconButton, Paper} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import CurrentP2pLevel from "./CurrentP2pLevel";

export default function P2pLevel(props: {expanded?: boolean}){
    const { t } = useTranslation();
    const {expanded} = props;


    return (
        <Paper sx={{p: 2}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={{xs:1, sm: 2}}
            >
                <Typography variant={'h6'}>{ t('trade_commissions') }</Typography>
                <IconButton component={RouterLink} to={'/wage'} sx={{color: 'text.secondary'}}><KeyboardBackspaceIcon /></IconButton>
            </Stack>
            <Divider />
            <Grid container sx={{py: 2}} spacing={3}>
                <Grid item xs={12}>
                    <Typography component={'h6'} fontWeight={'bold'} textAlign={'center'}>{t('your_current_level')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                        <Box>
                            <Typography>{t('taker')}:</Typography>
                            <Typography textAlign={'center'} color={'rgba(254, 176, 25, 1)'}>0.02%</Typography>
                        </Box>
                        <Box>
                            <Typography>{t('maker')}:</Typography>
                            <Typography textAlign={'center'} color={'rgba(254, 176, 25, 1)'}>0.01%</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'} component={'h5'}>{t('go_to_gold_level')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box>
                            <Typography color={'text.secondary'}>{t('trade_volume_monthly')}</Typography>
                            <Typography>540,000 {t('toman')} / 10%</Typography>
                        </Box>
                        <Box>
                            <Typography color={'text.secondary'}>{t('volume_need_for_next_level')}</Typography>
                            <Typography>1,000,000,000 {t('toman')}</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <CurrentP2pLevel />
                </Grid>
            </Grid>
        </Paper>
    );
}