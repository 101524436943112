import React from 'react';


import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Container} from '@mui/system';
import {useTranslation} from 'react-i18next';
import HeadPhoneIcon from '../../../assets/headphone.png';
import PhoneIcon from '../../../assets/phone.png';
import ChatIcon from '../../../assets/chat.png';
import TelegramIcon from '../../../assets/telegram.png';

export function ContactChannels(){
    const { t } = useTranslation();    
    return (
        <Paper elevation={0} sx={{borderRadius: 0, mx: 0, px: 0, bgcolor: '#1B1B1D'}}>
            <Container maxWidth='xl'>
                <Stack direction="column" sx={{py: 5}}>
                    <Grid>
                        <Typography variant='h4' sx={{textAlign: 'center', mb: 2}}>{t('contact_channels')}</Typography>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{borderRadius: '10px', pt: 3, minHeight: '100%'}}>
                                <CardContent>
                                    <Grid container justifyContent="center" sx={{mb: 2}}>
                                        <img src={HeadPhoneIcon} />
                                    </Grid>
                                    <Typography gutterBottom variant='h5' sx={{textAlign: 'center', mb: 1}}>
                                        {t('full_time_support')}
                                    </Typography>
                                    <Typography color="text.secondary" sx={{textAlign: 'center'}}>
                                        تیم فنی و پشتیبانی پویام در هر ثانیه از روز در کنار شماست که مشکلات شمارو حل کنه . ما کنار شما هستیم
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} >
                            <Card sx={{borderRadius: '10px', pt: 3, minHeight: '100%'}}>
                                <CardContent>
                                    <Grid container justifyContent="center" sx={{mb: 2}}>
                                        <img src={PhoneIcon} />
                                    </Grid>
                                    <Typography gutterBottom variant='h5' sx={{textAlign: 'center', mb: 1}}>
                                        {t('phone_call')}
                                    </Typography>
                                    <Typography color="text.secondary" sx={{textAlign: 'center'}}>
                                        به زودی به اپ پویام تماس آنلاین مجازی برای پشتیبانی بهتر اضافه خواهد شد
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{borderRadius: '10px', pt: 3, minHeight: '100%'}}>
                                <CardContent>
                                    <Grid container justifyContent="center" sx={{mb: 2}}>
                                        <img src={ChatIcon} />
                                    </Grid>
                                    <Typography gutterBottom variant='h5' sx={{textAlign: 'center', mb: 1}}>
                                        {t('live_chat')}
                                    </Typography>
                                    <Typography color="text.secondary" sx={{textAlign: 'center'}}>
                                        به راحتی به صورت آنی سوالات خودتون رو از طریق چت آنلاین میتونید بعدا ثبت نام بپرسید
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card sx={{borderRadius: '10px', pt: 3, minHeight: '100%'}}>
                                <CardContent>
                                    <Grid container justifyContent="center" sx={{mb: 2}}>
                                        <img src={TelegramIcon} />
                                    </Grid>
                                    <Typography gutterBottom variant='h5' sx={{textAlign: 'center', mb: 1}}>
                                        {t('messenger_apps')}
                                    </Typography>
                                    <Typography color="text.secondary" sx={{textAlign: 'center'}}>
                                        کانال تلگرام پویام تمامی اطلاعیه های مهم خبر های مهم پویام رو براتون هر لحظه انتشار میده و برای ارتباط بهتر میتونید جوین بشید
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </Paper>
    );
}
