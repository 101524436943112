import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {useEffect, useState} from "react";
import {makeDense} from "../footer/FooterSlice";
import {Box, Grid} from "@mui/material";
import TextSwitchEasySellBuy from "../../components/atomic/textswitcheasysellbuy/TextSwitchEasySellBuy";
import SimpleChart from "../../components/SimpleChart";
import BuyForm from "./BuyForm";
import SellForm from "./SellForm";

export default function ExchangeForm(props: {symbol: string}){
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])

    const [value, setValue] = useState<0 | 1>(0);

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={4} sx={{ bgcolor: 'background.paper', py: 3, px: 2, borderRadius: '10px', mt: 2, height: 'fit-content' }}>
                    <TextSwitchEasySellBuy
                        offLabel={t('buy')}
                        onLabel={t('sell')}
                        handleChange={(v) => setValue(v)}
                        defaultValue={value}
                    />

                    <BuyForm value={value} index={0} onSwitch={setValue} symbol={props.symbol} />
                    <SellForm value={value} index={1} onSwitch={setValue} symbol={props.symbol} />

                </Grid>
                <Grid item xs={12} sm={12} md={8} mt={{ xs: 2, sm: 0 }}>
                    <Box>
                        <SimpleChart pair={'BTCUSDT'}/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}