import {useEffect, useState} from "react";
import client from "../config/client";
import {Chip, Grid, Paper, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ChartIcon from "../assets/chart.png";
import CurrencyChangesAreaChart from "./CurrencyChangesAreaChart";
import {number_format} from "../config/helpers";

interface CurrencyStatInterface{
    price_usd: string;
    price_irt: string;
    icon_url: string;
    changes: string
}

function ChangesChip(props: {v: string}){
    let color = '#fff';
    let bgcolor = 'rgba(255, 255, 255, 0.16)';
    if(parseFloat(props.v) > 0){
        color = '#16C784';
        bgcolor = 'rgba(22, 199, 132, 0.1)';
    }else if(parseFloat(props.v) < 0){
        color = '#EB0029';
        bgcolor = 'rgba(255, 0, 0, 0.1)';
    }

    return (
        <Chip size="small" sx={{color: color, bgcolor: bgcolor}}  label={Math.abs(parseFloat(props.v)) + '%'} />
    );
}
export default function CurrencyStat(props: {symbol: string}){
    const {symbol} = props;
    const [asset, setAsset] = useState<CurrencyStatInterface>({
        price_usd: '',
        price_irt: '',
        icon_url: '',
        changes: '0'
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if(!!symbol){
            client.get(`/v4/currencies/mini-stats/${symbol.toLowerCase()}`).then(function(resp){
                setAsset(resp.data.data);
            }).finally(() => setLoading(false))
        }
    }, [symbol])

    return (
        <Paper sx={{p: 2, borderRadius: '10px', bgcolor: '#212226'}}>
            {!loading && (
                <Grid container>
                    <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                        <Box sx={{mr: 1}}>
                            <img src={asset.icon_url} />
                        </Box>
                        <Box>
                            <span>{symbol.toUpperCase()}</span>
                            <span><SyncAltIcon /></span>
                            <span>IRT</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex'}}>
                        <Stack spacing={1}>
                            <div><ChangesChip v={asset.changes} /></div>
                            <div>{number_format(asset.price_irt)} تومان</div>
                        </Stack>
                        <Box sx={{flexGrow: 1, mt: 'auto', textAlign: 'right', ml: 5}}>
                            <CurrencyChangesAreaChart slug={'bitcoin'} symbol={symbol.toLowerCase()} duration={1} type={parseFloat(asset.changes) > 0 ? 'pump' : 'dump'} />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Paper>
    )
}