import React, {useEffect} from 'react';
import {Box, Container, ThemeProvider, Typography, useTheme} from "@mui/material";
import './terms.css'
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {makeFull} from "../../features/footer/FooterSlice";

const Terms = () => {
    const [t] = useTranslation()
    const theme = useTheme();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('terms')}>
            <ThemeProvider theme={theme}>

                <Box sx={{ bgcolor: 'background.default', py:4 , textAlign:"center"}}>
                    <Typography variant="h5" component="h1" sx={{ color: "text.title"  }}>
                        {t('terms')}
                    </Typography>
                </Box>
                <Box sx={{ bgcolor: 'background.darkBlack', pb: 8 }}>
                    <Container>
                        <Box sx={{ py: 7}}>
                            <Typography variant="h5" component="p" sx={{ color: "text.title", textAlign: 'center'}}>
                                {t('terms_in_pouyam')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('terms_in_pouyam_paragraph')}
                            </Typography>
                        </Box>
                        <Container  sx={{ bgcolor: 'background.paper', p: 4, pb: 5, borderRadius: '10px' }}>
                            <Typography variant='h6' component='span' sx={{ color: "text.title", mt: 2 }}>
                                {t('words_in_terms')}
                            </Typography>
                            <Typography variant='h6' component='span'  sx={{ color: "text.title", mt: 2 }}>
                                {t('pouyam')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('introduce_pouyam')}
                            </Typography>
                            <Typography variant='h6' component='span'  sx={{ color: "text.title", mt: 2 }}>
                                {t('user')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('user_introduce')}
                            </Typography>
                            <Typography variant='h6' component='span'  sx={{ color: "text.title", mt: 2 }}>
                                {t('crypto_currency')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('introduce_crypto')}
                            </Typography>
                            <Typography variant='h6' component='span' sx={{ color: "text.title", mt: 2 }}>
                                {t('managment_acc')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('managment_acc_introduce')}
                            </Typography>
                            <ul className='ul_section_terms'>
                                <li>{t('text-term-1')}</li>
                                <li>{t('text-term-2')}</li>
                                <li>{t('text-term-3')}</li>
                                <li>{t('text-term-4')}</li>
                                <li>{t('text-term-5')}</li>
                                <li>{t('text-term-6')}</li>
                                <li>{t('text-term-7')}</li>

                            </ul>
                            <Typography variant='h6' component='span' sx={{ color: "text.title", mt: 2 }}>
                                {t('Fees_and_charges')}
                            </Typography>

                            <ul className='ul_section_terms'>
                                <li>{t('text-term-8')}</li>
                                <li>{t('text-term-9')}</li>

                            </ul>

                            <Typography variant='h6' component='span'  sx={{ color: "text.title", mt: 2 }}>
                                {t('user_accepts')}
                            </Typography>
                            <ul className='ul_section_terms ul_section_terms_counted'>
                                <li>{t('text-terms-10')}</li>
                                <li>{t('text-terms-11')}</li>
                                <li>{t('text-terms-12')}</li>
                                <li>{t('text-terms-13')}</li>
                                <li>{t('text-terms-14')}</li>
                                <li>{t('text-terms-15')}</li>
                                <li>{t('text-terms-16')}</li>
                                <li>{t('text-terms-17')}</li>
                                <li>{t('text-terms-18')}</li>
                                <li>{t('text-terms-19')}</li>
                                <li>{t('text-terms-20')}</li>
                                <li>{t('text-terms-21')}</li>
                                <li>{t('text-terms-22')}</li>

                            </ul>

                            <Typography variant='h6' component='span'  sx={{ color: "text.title", mt: 2 }}>
                                {t('difference')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('difference_paragraph')}

                            </Typography>
                            <Typography variant='h6' component='span'  sx={{ color: "text.title", mt: 2 }}>
                                {t('property_rights')}
                            </Typography>
                            <Typography variant='subtitle1' component='p' sx={{ color: "text.title", mt: 2 }}>
                                {t('property_rights_paragraph')}
                            </Typography>
                        </Container>
                    </Container>
                </Box>
            </ThemeProvider>
        </Page>
    )
}

export default Terms;