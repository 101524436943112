import React from "react";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Slider,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import {useTranslation} from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export default function TradeForm(){
    const [t] = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={1} sx={{
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                bgcolor: 'background.default'}}>
                <Box flexGrow={1}>
                    <Tabs
                        sx={{
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            '& .MuiButtonBase-root.MuiTab-root': {
                                minWidth: '50px'
                            }
                        }}
                        value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label={t('limit') as string}  />
                        <Tab label={t('immediate') as string} />
                        <Tab label={t('stop_limit') as string} />
                    </Tabs>
                </Box>
                <Box sx={{pt: 1, pr: 1}}>
                    <Button endIcon={<InfoOutlinedIcon />} variant="outlined" color={'inherit'} sx={{mr: 1}}>{t('trade_terms')}</Button>
                    <IconButton sx={{borderRadius: '8px', border: '1px solid white', p: '5px'}}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
            </Stack>
            <Box sx={{py: 4, px: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Stack direction={'row'} sx={{mb: 1}}>
                            <Typography sx={{alignSelf: 'flex-end'}}>{t('buy_bitcoin')}</Typography>
                            <Box flexGrow={1} />
                            <Button sx={{mx: 1}} size={'small'} endIcon={<AccountBalanceWalletOutlinedIcon />} color='inherit' variant={'outlined'}>143,011,000 {t('toman')}</Button>
                            <Button size={'small'} endIcon={<FileDownloadOutlinedIcon />} color='inherit' variant={'outlined'}>{t('deposit')}</Button>
                        </Stack>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                size={'small'}
                                endAdornment={<InputAdornment position="start">{t('toman')}</InputAdornment>}
                                fullWidth placeholder={t('buy_price') as string} />
                            <FormHelperText id="component-helper-text">
                                {t('min_sell_price')}: 145,412,000
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                size={'small'}
                                endAdornment={<InputAdornment position="start">BTC</InputAdornment>}
                                fullWidth placeholder={t('value') as string} />
                            <FormHelperText>
                                <Slider color={'secondary'} defaultValue={30} step={25} marks min={0} max={100} valueLabelDisplay="auto" />
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                disabled
                                value={''}
                                size={'small'}
                                endAdornment={<InputAdornment position="start">{t('toman')}</InputAdornment>}
                                fullWidth placeholder={t('total_price') as string} />
                        </FormControl>
                        <Button sx={{mt: 2}} fullWidth size={'large'} variant={'contained'} color={'success'}>{t('buy')}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction={'row'} sx={{mb: 1}}>
                            <Typography sx={{alignSelf: 'flex-end'}}>{t('sell_bitcoin')}</Typography>
                            <Box flexGrow={1} />
                            <Button sx={{mx: 1}} size={'small'} endIcon={<AccountBalanceWalletOutlinedIcon />} color='inherit' variant={'outlined'}>0.42 {t('bitcoin')}</Button>
                            <Button size={'small'} endIcon={<FileDownloadOutlinedIcon />} color='inherit' variant={'outlined'}>{t('deposit')}</Button>
                        </Stack>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                size={'small'}
                                endAdornment={<InputAdornment position="start">{t('toman')}</InputAdornment>}
                                fullWidth placeholder={t('sell_price') as string} />
                            <FormHelperText id="component-helper-text">
                                {t('max_buy_price')}: 145,412,000
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                size={'small'}
                                endAdornment={<InputAdornment position="start">BTC</InputAdornment>}
                                fullWidth placeholder={t('value') as string} />
                            <FormHelperText>
                                <Slider color={'secondary'} defaultValue={30} step={25} marks min={0} max={100} valueLabelDisplay="auto" />
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <OutlinedInput
                                disabled
                                value={''}
                                size={'small'}
                                endAdornment={<InputAdornment position="start">{t('toman')}</InputAdornment>}
                                fullWidth placeholder={t('total_price') as string} />
                        </FormControl>
                        <Button sx={{mt: 2}} fullWidth size={'large'} variant={'contained'} color={'error'}>{t('sell')}</Button>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}