import * as React from "react";
import Box from "@mui/material/Box";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%', height: '100%' }}
        >
            {value === index && (
                <Box sx={{ pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 3 } }}>
                    <Box sx={{ mt: { xs: 2, sm: 0 } }}>{children}</Box>
                </Box>
            )}
        </div>
    );
}