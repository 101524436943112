import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import NightlightIcon from '@mui/icons-material/Nightlight';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Header.css'
import Logo from '../../assets/logo.png';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {logout, selectIsLoggedIn} from "../auth/authSlice";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RuleIcon from '@mui/icons-material/Rule';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PollIcon from '@mui/icons-material/Poll';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GppGoodIcon from '@mui/icons-material/GppGood';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {Divider, Link} from "@mui/material";
import AppQrCodeImage from '../../assets/app-qrcode.png';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import MobileDrawer from "./MobileDrawer";
import {useEffect, useState} from "react";
import client from "../../config/client";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface PageInterface{
    text: string,
    link: string
}
interface NotificationInterface{
    id: string;
    created_at: string;
    data: {
        subject: string;
        message: string;
    }
}
const pages:Array<PageInterface> = [
    {
        text: 'market',
        link: '/markets'
    },
    {
        text: 'trade',
        link: '/trade'
    },
    {
        text: 'easy_order',
        link: '/exchange/btc'
    },
    {
        text: 'shop',
        link: '/shop'
    }
];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function WalletMenu(){
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleNavigate = (to: string) => {
        navigate(to)
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [t] = useTranslation();

    return (
        <div>
            <Button
                id="wallet-customized-button"
                aria-controls={open ? 'wallet-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color="inherit"
                disableElevation
                disableRipple
                disableFocusRipple
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {t('wallet')}
            </Button>
            <Menu
                id="wallet-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'wallet-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >

                <MenuItem onClick={() => handleNavigate('/wallet')} disableRipple>
                    <ListItemIcon>
                        <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText>{t('assets')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/deposit')} disableRipple>
                    <ListItemIcon>
                        <SaveAltIcon />
                    </ListItemIcon>
                    <ListItemText>{t('deposit')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/withdraw')} disableRipple>
                    <ListItemIcon>
                        <SaveAltIcon />
                    </ListItemIcon>
                    <ListItemText>{t('withdraw')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/transactions')} disableRipple>
                    <ListItemIcon>
                        <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText>{t('deposit_withdraw_history')}</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

function OrdersMenu(){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [t] = useTranslation();

    return (
        <div>
            <Button
                id="orders-customized-button"
                aria-controls={open ? 'orders-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                color="inherit"
                disableElevation
                disableRipple
                disableFocusRipple
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {t('orders')}
            </Button>
            <Menu
                id="orders-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'orders-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >

                <MenuItem onClick={handleClose} disableRipple>
                    <ListItemIcon>
                        <RuleIcon />
                    </ListItemIcon>
                    <ListItemText>{t('open_orders')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText>{t('orders_history')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple>
                    <ListItemIcon>
                        <PollIcon />
                    </ListItemIcon>
                    <ListItemText>{t('trade_history')}</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

function AccountMenu(){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const dispatch = useAppDispatch();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [t] = useTranslation();
    const handleNavigate = (to: string) => {
        navigate(to)
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="account-customized-button"
                onClick={handleClick}
                color='primary'>
                <PersonIcon />
            </IconButton>
            <Menu
                id="account-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'account-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box>
                    <Typography variant={'h6'} fontWeight={'bold'} sx={{px: 2}}>کاربر تست</Typography>
                </Box>
                <Stack direction={'row'} spacing={2} sx={{mb: 3}} justifyContent={'space-between'}>
                    <Typography fontSize={'small'} sx={{alignSelf: 'center', px: 2}} color={'text.secondary'}>{t('user_level_1')}</Typography>
                    <Box>
                        <Button sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} size={'small'} color={'error'} variant={'outlined'}>{t('not_verified')}</Button>
                    </Box>
                </Stack>
                <MenuItem onClick={()=>handleNavigate('/dashboard')} disableRipple>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText>{t('dashboard')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>handleNavigate('/security')} disableRipple>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText>{t('user_account')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>handleNavigate('/kyc')} disableRipple>
                    <ListItemIcon>
                        <NoAccountsIcon />
                    </ListItemIcon>
                    <ListItemText>{t('kyc')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>handleNavigate('/security')} disableRipple>
                    <ListItemIcon>
                        <GppGoodIcon />
                    </ListItemIcon>
                    <ListItemText>{t('security')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={()=>handleNavigate('/referral')} disableRipple>
                    <ListItemIcon>
                        <PersonAddIcon />
                    </ListItemIcon>
                    <ListItemText>{t('invite_friends')}</ListItemText>
                </MenuItem>
                <Box sx={{px: 2, pt: 4}}>
                    <Button onClick={() => dispatch(logout())} fullWidth size={'large'} color={'error'} variant={'outlined'}>{t('exit')}</Button>
                </Box>
            </Menu>
        </div>
    );
}

function NotificationsMenu(){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [t] = useTranslation();
    const [notifications, setNotifications] = useState<NotificationInterface[]>([])

    useEffect(() => {
        client.get('/v1/notifications/unread').then((resp) => {
            setUnreadNotificationsCount(resp.data.data.unread_notifications)
        })
    }, [])

    useEffect(() => {
        client.get('/v4/notifications/latest').then((resp) => {
            setNotifications(resp.data.data.data.notifications)
        })
    }, [])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };




    return (
        <div>
            <IconButton
                id="notifications-customized-button"
                onClick={handleClick}
                color='primary'>
                <Badge badgeContent={unreadNotificationsCount} color='error'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="notifications-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'notifications-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >

                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} sx={{ml: 2}}>
                    <Typography fontWeight={'bold'} sx={{alignSelf: 'center'}}>{t('you_have_messages', {count: unreadNotificationsCount})}</Typography>
                    <Box>
                        <Button size={'small'} variant={'text'} color={'info'}>{t('show_all')}</Button>
                    </Box>
                </Stack>
                <Divider />
                {notifications.map((notification) => (
                    <Box key={notification.id}>
                        <MenuItem onClick={handleClose} disableRipple>
                            <ListItemText>
                                <Typography>{notification.data.subject}</Typography>
                                <Typography color={'text.secondary'} fontSize={'small'} fontWeight={'bold'}>{notification.created_at}</Typography>
                            </ListItemText>
                        </MenuItem>
                        <Divider sx={{mx: 2}} />
                    </Box>
                ))}
            </Menu>
        </div>
    );
}

function DownloadAppMenu(){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [t] = useTranslation();

    return (
        <div>
            <Button
                id="download-app-customized-button"
                onClick={handleClick}
            >
                <FileDownloadOutlinedIcon />
            </Button>
            <Menu
                id="download-app-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'download-app-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box sx={{mx: 2}}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <img src={AppQrCodeImage} />
                    </Box>
                    <Typography sx={{display: 'flex', justifyContent: 'center', mt: 2}}>{t('scan_for_download')}</Typography>
                    <Typography sx={{display: 'flex', justifyContent: 'center'}}>Android - IOS</Typography>
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 3, mb: 1}}>
                        <Button size={'large'} color={'warning'} variant={'contained'}>{t('more_details')}</Button>
                    </Box>
                </Box>
            </Menu>
        </div>
    );
}

const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const isLoggedIn = useAppSelector(selectIsLoggedIn)
    const { t } = useTranslation();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (to: string) => {
        navigate(to);
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const UserMenu = () => {
        return (
            <>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                        <Avatar src="/static/images/avatar/2.jpg"/>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{mt: '45px'}}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    return (
        <AppBar position="static" sx={{bgcolor: 'background.darkBlack'}}>
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Link component={RouterLink} to='/'><img src={Logo} width={110} /></Link>
                    <Box sx={{flexGrow: 1, mx: 2}}>
                        <Stack direction='row' sx={{display: {xs: 'none', sm: 'flex'}}}>
                            {pages.map((page) => (
                                <Button
                                    color='inherit'
                                    variant={'text'}
                                    key={page.text}
                                    onClick={() => handleCloseNavMenu(page.link)}
                                    sx={{my: 2, display: 'block'}}
                                >
                                    {t(page.text)}
                                </Button>
                            ))}
                        </Stack>
                    </Box>
                    <Box sx={{flexGrow: 0, display: 'flex'}}>
                        {
                            isLoggedIn ? (
                                <Stack direction='row' mx={2}>
                                    <Box sx={{display: {xs: 'none', md: 'inline-flex'}}}>
                                        <WalletMenu />
                                        <OrdersMenu />
                                    </Box>
                                    <Box sx={{display: {xs: 'inline-flex'}}}>
                                        <AccountMenu />
                                        <NotificationsMenu />
                                    </Box>
                                </Stack>
                            ) : (
                                <Stack spacing={1} direction='row' sx={{display: {xs: 'none', md: 'inline-flex'}}} mx={2}>
                                    <Button component={RouterLink} to="/login" variant='outlined'>{ t('login') }</Button>
                                    <Button component={RouterLink} to="/signup" variant='contained'>{ t('sign_up') }</Button>
                                </Stack>
                            )
                        }
                        <ButtonGroup sx={{display: {xs: 'none', lg: 'flex'}}} color='inherit' size='small' variant="text" aria-label="text button group">
                            <Button>{t('persian')}</Button>
                            <Button>{t('toman')}</Button>
                            <DownloadAppMenu />
                            <Button>
                                <NightlightIcon />
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <Box sx={{display: {xs: 'flex', lg: 'none'}}}>
                        <MobileDrawer />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
