import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface LoginState {
    step: 'username' | 'otp';
    username: string;
    type: 'email' | 'mobile';
    password: string;
    token: string
}

const initialState: LoginState = {
    step: 'username',
    username: '',
    type: 'email',
    password: '',
    token: ''
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setStep: (state, action: PayloadAction<'username' | 'otp'>) => {
            state.step = action.payload;
        },
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setType: (state, action: PayloadAction<'email' | 'mobile'>) => {
            state.type = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    },
});

export const {
    setUsername,
    setStep,
    setType,
    setPassword,
    setToken
} = loginSlice.actions;

export const selectUsername = (state: RootState) => state.login.username;
export const selectStep = (state: RootState) => state.login.step;
export const selectType = (state: RootState) => state.login.type;
export const selectPassword = (state: RootState) => state.login.password;
export const selectToken = (state: RootState) => state.login.token;

export default loginSlice.reducer;
