import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import footerReducer from "../features/footer/FooterSlice";
import drawerReducer from "../features/drawer/DrawerSlice";
import signupReducer from "../features/signup-form/SignupSlice";
import loginReducer from "../features/login-form/LoginSlice";
import forgetReducer from "../features/forget-form/ForgetSlice";
import depositFormReducer from "../features/deposit-form/depositFormSlice";
import withdrawFormSlice from "../features/withdraw-form/withdrawFormSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    footer: footerReducer,
    drawer: drawerReducer,
    signup: signupReducer,
    login: loginReducer,
    forget: forgetReducer,
    deposit_form: depositFormReducer,
    withdraw_form: withdrawFormSlice,
  },
  preloadedState: {
    auth: {
      username: '-',
      token: localStorage.getItem('access_token') as string
    }
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
