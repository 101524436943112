import React from 'react';


import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Box, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';
import SecureIcon from '../../../assets/secure.png';
import FeaturesIcon from '../../../assets/features.png';
import DashboardIcon from '../../../assets/dashboard.png';
import AppPreviewIcon from '../../../assets/pouyam-app.jpeg';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import QrCodeIcon from '@mui/icons-material/QrCode';
import GooglePlayIcon from '../../../assets/google-play.png'

export function DownloadApplication(){
    const { t } = useTranslation();
    return (
        <Paper elevation={0} sx={{borderRadius: 0, mx: 0, px: 0, bgcolor: '#212226', py: 3}}>
            <Container maxWidth='xl'>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Stack direction='column' sx={{mb: 3}}>
                            <Grid>
                                <Typography variant={'h4'}>{t('download_app_title')}</Typography>
                                <Typography sx={{color: 'text.secondary'}}>{t('download_app_subtitle')}</Typography>
                            </Grid>
                            <Grid>
                                <List>
                                    <ListItem disableGutters>
                                        <ListItemIcon sx={{minWidth: '38px'}}>
                                            <img src={SecureIcon} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('easy_secure_trade')} />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <ListItemIcon sx={{minWidth: '38px'}}>
                                            <img src={FeaturesIcon} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('rich_features')} />
                                    </ListItem>
                                    <ListItem disableGutters>
                                        <ListItemIcon sx={{minWidth: '38px'}}>
                                            <img src={DashboardIcon} />
                                        </ListItemIcon>
                                        <ListItemText primary={t('fast_order_handling')} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid>
                                <Typography variant='subtitle1' sx={{mb: 2}}>{t('download_from')}</Typography>
                                <Stack direction={'row'} spacing={1}>
                                    <Paper component='a' href="/" sx={{width: '90px', display: 'inline-block', p: 2, textAlign: 'center', textDecoration: 'none', borderRadius: '10px'}}>
                                        <Box sx={{mb: 1}}><img src={GooglePlayIcon} /></Box>
                                        <Typography>Google Play</Typography>
                                    </Paper>
                                    <Paper component='a' href="/" sx={{width: '90px', display: 'inline-block', p: 2, textAlign: 'center', textDecoration: 'none', borderRadius: '10px'}}>
                                        <Box sx={{mb: 1}}><AndroidIcon /></Box>
                                        <Typography>Android</Typography>
                                    </Paper>
                                    <Paper component='a' href="/" sx={{width: '90px', display: 'inline-block', p: 2, textAlign: 'center', textDecoration: 'none', borderRadius: '10px'}}>
                                        <Box sx={{mb: 1}}><AppleIcon /></Box>
                                        <Typography>IOS</Typography>
                                    </Paper>
                                    <Paper component='a' href="/" sx={{width: '90px', display: 'inline-block', p: 2, textAlign: 'center', textDecoration: 'none', borderRadius: '10px'}}>
                                        <Box sx={{mb: 1}}><QrCodeIcon /></Box>
                                        <Typography>QR Code</Typography>
                                    </Paper>
                                </Stack>
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{alignSelf: 'center'}}>
                        <Box component='img' src={AppPreviewIcon} sx={{maxWidth: '100%'}} />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}
