import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Container, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { t } from "i18next";
import OrdersHistory from '../ordershistory/OrdersHistory';


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function BreadCrumbEasySellBuy() {
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            {t('home')}
        </Link>,
        <Typography key="3" color="text.primary">
            {t('easy_sell_and_buy')}
        </Typography>,
    ];

    return (
        <Box sx={{ bgcolor: "background.darkBlack", py: 2 }}>
            <Container>
                <Grid container sx={{justifyContent:'space-between'}} spacing={1}>
                    <Grid item>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                       <OrdersHistory />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
