import {Button, Container, Link, Stack, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import Grid from '@mui/material/Grid';
//import images
import MapImage from '../../assets/map.png';
import LocationIcon from '../../assets/location.png';
import EmailIcon from '../../assets/email.png';
import PhoneIcon from '../../assets/phone-contactus.png';
import SocialMediaIcon from '../../assets/socialmedia-contactus.png';
import InstaIcon from '../../assets/instaIcon.png';
import TelegramIcon from '../../assets/telegramicon.png';
import TwitterIcon from '../../assets/twitter.png';
import LnkedinIcon from '../../assets/linkedinicon.png'
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";


const Contact = () => {
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('contact_us')}>
            <Box sx={{ bgcolor: "background.default", textAlign: 'center' }}>
                <Typography variant="h6" component="h1" sx={{ color: "text.title", py: 3 }}>
                    {t('contact_us')}
                </Typography>
            </Box>
            <Box sx={{ bgcolor: 'background.darkBlack', py: 9 }}>
                <Container>
                    <Grid container spacing={4}>
                        <Grid item sm={12} xs={12} md={6}>
                            <Box sx={{ borderRadius: '10px' }}>
                                <img src={MapImage} width='100%' style={{ borderRadius: '10px' }} alt='icon' />
                            </Box>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6}>
                            <Typography sx={{ color: "text.secondary" }}>
                                {t('lorem3')}
                            </Typography>
                            <Box sx={{ mt: 3 }}>
                                <Stack sx={{ mt: 2 }} direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={LocationIcon} width='100%' alt='icon' />
                                    </Box>
                                    <Typography variant="subtitle2" component="span" sx={{ color: "text.secondary", ml: 2 }}>
                                        {t('lorem2')}
                                    </Typography>
                                </Stack>
                                <Stack sx={{ mt: 2 }} direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={EmailIcon} width='100%' alt='icon' />
                                    </Box>
                                    <Link href='mailto: pouyam@gmail.com' underline="none" sx={{ ml: 2, color: 'text.secondary' }}>
                                        info[AT]pouyam[DOT]com
                                    </Link>
                                </Stack>
                                <Stack sx={{ mt: 2 }} direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={PhoneIcon} width='100%' alt='icon' />
                                    </Box>
                                    <Link href='phone: 09123345671' underline="none" sx={{ ml: 2, color: 'text.secondary' }}>
                                        09123345671
                                    </Link>
                                </Stack>
                                <Stack sx={{ mt: 2 }} direction="row" spacing={2} alignItems="center">
                                    <Box>
                                        <img src={SocialMediaIcon} width='100%' alt='icon' />
                                    </Box>
                                    <Stack direction="row" spacing={3} alignItems="center">
                                        <Link href="#" >
                                            <img src={InstaIcon} width=' 100%' alt='icon' />
                                        </Link>
                                        <Link href="#">
                                            <img src={TelegramIcon} width=' 100%' alt='icon' />
                                        </Link>
                                        <Link href="#">
                                            <img src={TwitterIcon} width=' 100%' alt='icon' />
                                        </Link>
                                        <Link href="#">
                                            <img src={LnkedinIcon} width=' 100%' alt='icon' />
                                        </Link>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container sx={{ bgcolor: 'background.default', py: 6, px: 2, mt: 10, display: 'flex', justifyContent: "center", borderRadius: '10px' }}>
                    <Box sx={{ width: { xs: "auto", sm: 600 } }}>
                        <Typography variant="h6" component="p" sx={{ color: "text.secondary", py: 3, textAlign: 'center' }}>
                            {t('contact_form_title')}
                        </Typography>
                        <Stack sx={{ mt: 2 }} spacing={2} alignItems="center" justifyContent="center" direction={{ xs: 'column', sm: 'row' }}>
                            <TextField sx={{ bgcolor: 'background.paper', "& fieldset": { border: 'none' }, width: "100%" }} id="outlined-basic" label={`${t('name')}`} margin="dense" />
                            <TextField sx={{ bgcolor: 'background.paper', "& fieldset": { border: 'none' }, width: "100%" }} id="outlined-basic-2" label={`${t('full_name')}`} variant="outlined" margin="dense" />
                        </Stack>
                        <Stack sx={{ mt: 2 }} spacing={2} alignItems="center" justifyContent="center" direction={{ xs: 'column', sm: 'row' }}>
                            <TextField sx={{ bgcolor: 'background.paper', "& fieldset": { border: 'none' }, width: "100%" }} id="outlined-basic" label={`${t('title_of_message')}`} variant="outlined" margin="dense" />
                            <TextField sx={{ bgcolor: 'background.paper', "& fieldset": { border: 'none' }, width: "100%" }} id="outlined-basic-2" label={`${t('email')}`} variant="outlined" margin="dense" />
                        </Stack>
                        <Stack sx={{ mt: 2 }} spacing={2} alignItems="center" justifyContent="center">
                            <TextField multiline fullWidth minRows={5} sx={{ bgcolor: 'background.paper', "& fieldset": { border: 'none' }}} label={`${t('message_text')}`} id="fullWidth" />
                        </Stack>
                        <Stack sx={{ mt: 2 }} alignItems="end" justifyContent="end" >
                            <Button variant="contained" sx={{ py: 1, px: 4 }}>{t('send_message')}</Button>
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </Page>
    )
}

export default Contact