import {Button, Container, Divider, Grid, Link, Paper, Stack, TextField, Typography} from '@mui/material'
import btcBackground from '../../assets/btc.png'
import {useTranslation} from "react-i18next";
import SocialMediaRoundedBtnGroup from "../../components/SocialMediaRoundedBtnGroup";
import Logo from '../../assets/logo.png';
import {Link as RouterLink} from 'react-router-dom';

interface FooterProps{
    dense?: boolean
}

const FullFooter = () => {
    const { t } = useTranslation();
    return (
        <Paper elevation={0} sx={{py: 2, borderRadius: 0, bgcolor: '#212226', mt: 'auto'}}>
            <Container>
                <Stack>
                    <Paper elevation={0} sx={{py: 4, px: 2, backgroundImage: `url(${btcBackground})`, backgroundRepeat: 'repeat-x'}}>
                        <Typography variant="h5" component="h5" sx={{mb: 2}} textAlign="center">{t('start_from_today_now')}!</Typography>
                        <Grid container spacing={1} justifyContent={'center'}>
                            <Grid item xs={9} sm={4} md={4}>
                                <TextField id="outlined-basic" color="secondary" placeholder={t('enter_your_number') as string} variant="outlined" size="small" fullWidth/>
                            </Grid>
                            <Grid item xs={3} sm={2} md={1}>
                                <Button variant="contained" fullWidth sx={{px: 1}}>{t('sign_up')}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Stack>
                                <h3>
                                    <img src={Logo} />
                                </h3>
                                <Grid item xs={12} sm={9}>
                                    <Typography variant="h6" component="h6" sx={{mb: 2}}>{ t('footer_description') }</Typography>
                                </Grid>
                                <SocialMediaRoundedBtnGroup />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Grid container>
                                <Grid item xs={6} sm={6} lg={3}>
                                    <h4>{t('about_us')}</h4>
                                    <Link component={RouterLink} to='/contact' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('contact_us')}</Link>
                                    <Link component={RouterLink} to='/aboutus' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('about_us')}</Link>
                                    <Link component={RouterLink} to='/' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('our_team')}</Link>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={3}>
                                    <h4>{t('services')}</h4>
                                    <Link component={RouterLink} to='/shop' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('shop')}</Link>
                                    <Link  href='/blog' sx={{display: 'block', mb: 1}} underline="none" color="text.secondary">{t('blog')}</Link>
                                    <Link component={RouterLink} to='/referral' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('affiliate')}</Link>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={3}>
                                    <h4>{t('guide')}</h4>
                                    <Link component={RouterLink} to='/faq' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('faqs')}</Link>
                                    <Link component={RouterLink} to='/terms' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">{t('trade_terms')}</Link>
                                    <Link component={RouterLink} underline="none" to='/wage' sx={{display: 'block', mb: 1}} color="text.secondary">{t('commission')}</Link>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={3}>
                                    <h4>{t('learn_more')}</h4>
                                    <Link component={RouterLink} to='/coins/bitcoin' underline="none" sx={{display: 'block', mb: 1}} color="text.secondary">خرید بیت کوین</Link>
                                    <Link component={RouterLink} underline="none" to='/coins/ethereum' sx={{display: 'block', mb: 1}} color="text.secondary">خرید اتریوم</Link>
                                    <Link component={RouterLink} underline="none" to='/coins/tron' sx={{display: 'block', mb: 1}} color="text.secondary">خرید ترون</Link>
                                    <Link component={RouterLink} underline="none" to='/coins/shiba-inu' sx={{display: 'block', mb: 1}} color="text.secondary">خرید شیبا</Link>
                                    <Link component={RouterLink} underline="none" to='/coins/cardano' sx={{display: 'block', mb: 1}} color="text.secondary">خرید کاردانو</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider sx={{mt: 5}} />
                    <Typography textAlign="center" component='p' sx={{my: 1}}>{ t('all_rights_reserved') }</Typography>
                </Stack>
            </Container>
        </Paper>
    );
}

const DenseFooter = () => {
    const { t } = useTranslation();

    return (
        <Paper elevation={0} sx={{py: 2, borderRadius: 0, bgcolor: '#212226', mt: 'auto'}}>
            <Container>
                <Grid container>
                    <Grid item xs={12} sm={6} sx={{my: 'auto'}} color={'text.secondary'}>{ t('all_rights_reserved') }</Grid>
                    <Grid item xs={12} sm={6} sx={{display: 'flex', justifyContent: 'end'}}>
                        <SocialMediaRoundedBtnGroup />
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}
const Footer = (props: FooterProps) => {
    return props.dense ? <DenseFooter /> : <FullFooter />
}

export default Footer
