import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface SignupState {
    step: 'username' | 'otp' | 'password';
    username: string;
    type: 'email' | 'mobile';
    password: string;
    referrer: string;
    hasReferrer: boolean;
    terms: boolean,
    token: string
}

const initialState: SignupState = {
    step: 'username',
    username: '',
    type: 'email',
    password: '',
    referrer: '',
    hasReferrer: false,
    terms: false,
    token: ''
};

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setStep: (state, action: PayloadAction<'username' | 'otp' | 'password'>) => {
            state.step = action.payload;
        },
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setType: (state, action: PayloadAction<'email' | 'mobile'>) => {
            state.type = action.payload;
        },
        setPassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        setReferrer: (state, action: PayloadAction<string>) => {
            state.referrer = action.payload;
        },
        setHasReferrer: (state, action: PayloadAction<boolean>) => {
            state.hasReferrer = action.payload;
        },
        toggleHasReferrer: (state) => {
            state.hasReferrer = !state.hasReferrer;
        },
        setTerms: (state, action: PayloadAction<boolean>) => {
            state.terms = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
    },
});

export const {
    setUsername,
    setStep,
    setType,
    setPassword,
    setReferrer,
    setHasReferrer,
    toggleHasReferrer,
    setTerms,
    setToken
} = signupSlice.actions;

export const selectUsername = (state: RootState) => state.signup.username;
export const selectStep = (state: RootState) => state.signup.step;
export const selectType = (state: RootState) => state.signup.type;
export const selectPassword = (state: RootState) => state.signup.password;
export const selectReferrer = (state: RootState) => state.signup.referrer;
export const selectHasReferrer = (state: RootState) => state.signup.hasReferrer;
export const selectTerms = (state: RootState) => state.signup.terms;
export const selectToken = (state: RootState) => state.signup.token;

export default signupSlice.reducer;
