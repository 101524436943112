import React from "react";
import {Box, IconButton, Stack} from "@mui/material";
import BuySellImage from '../../assets/buy-sell.png';
import BuyImage from '../../assets/buy.png';
import SellImage from '../../assets/sell.png';
import RefreshImage from '../../assets/refresh.png';
import Orders from "./Orders";

export default function TradeOrders(){
    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={1} sx={{px: 1}}>
                <IconButton sx={{borderRadius: '8px', border: '1px solid yellow'}}>
                    <img src={BuySellImage} />
                </IconButton>
                <IconButton sx={{borderRadius: '8px', border: '1px solid white'}}>
                    <img src={BuyImage} />
                </IconButton>
                <IconButton sx={{borderRadius: '8px', border: '1px solid white'}}>
                    <img src={SellImage} />
                </IconButton>
                <Box flexGrow={1}></Box>
                <IconButton sx={{borderRadius: '8px', border: '1px solid white'}}>
                    <img src={RefreshImage} />
                </IconButton>
            </Stack>
            <Box>
                <Orders />
            </Box>
        </React.Fragment>
    );
}