import {useEffect, useRef, useState} from "react";
import * as Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import {Box} from "@mui/material";
import client from "../config/client";

export default  function BalancesPieChart(){
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false);
    const gradiantColors = [
        [0, 'rgba(22, 199, 132, 0.81)'],
        [1, 'rgba(34, 38, 49, 0.15)']
    ] as any;
    const lineColor = '#16C784';

    const options: Highcharts.Options = {
        chart: {
            plotBackgroundColor: '#222631',
            backgroundColor: '#222631',
            plotBorderWidth: 0,
            plotShadow: false,
            type: 'pie',
        },
        title: {
            text: '',
            align: 'left'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    connectorColor: 'orange'
                }
            }
        },
        series: [{
            type: 'pie',
            name: '',
            colorByPoint: true,
            data: data
        }]
    }

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    useEffect(() => {
        setLoading(true)
        client.get(`/v4/assets/percent`).then(function(resp){
            setData(resp.data.data)
        }).finally(() => setLoading(false))
    }, [])

    return (
        <>
            {loading && (
                <Box sx={{textAlign: 'center',}}>در حال بارگذاری...</Box>
            )}
            {!loading && (
                <HighchartsReact
                    containerProps={{ style: { height: "200px" } }}
                    highcharts={Highcharts}
                    options={options}
                    ref={chartComponentRef}
                />
            )}
        </>
    )
}