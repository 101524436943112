import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface FooterState {
    dense: boolean;
}

const initialState: FooterState = {
    dense: false,
};

export const footerSlice = createSlice({
    name: 'footer',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        makeFull: (state) => {
            state.dense = false;
        },
        makeDense: (state) => {
            state.dense = true;
        },
    },
});

export const { makeFull, makeDense } = footerSlice.actions;

export const selectDense = (state: RootState) => state.footer.dense;


export default footerSlice.reducer;
