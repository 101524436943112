import theme from './theme';
import cacheRtl from './theme';

const config = {
    defaultDir: 'rtl',
    theme: theme,
    cacheRtl: cacheRtl,
}

export default config;
