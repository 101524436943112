import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export function url(path: string){
    let base = process.env.NODE_ENV === 'production' ? 'https://cp.pouyam.com/api/' : 'http://127.0.0.1:8000/api/';
    let withoutSlashes = path.replace(/^\/|\/$/g, '');
    return `${base}/${withoutSlashes}`;
}

export function number_format(v: any){
    if(typeof v !== 'number' || !isFinite(v)) return v;

    return Intl.NumberFormat().format(v)
}

export default {}
