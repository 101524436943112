import React, {useState} from "react";
import {IconButton, InputAdornment, OutlinedInput, TextFieldProps, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";


export default function PasswordField(props: TextFieldProps){
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <TextField
            sx={{
                '& .MuiOutlinedInput-input': {
                    paddingRight: 1.5
                }
            }}
            dir={'ltr'} size={'small'} fullWidth
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            placeholder={'*********'}
            {...props}
        />
    );
}