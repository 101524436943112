import {useTranslation} from "react-i18next";
import {Box, Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

interface FiatTransactionInterface{
    id: number;
    date: string;
    status: string;
    amount: string;
    card: string;
    tracking: string;
}
function SmUpScreenCryptoTable(){
    const { t } = useTranslation();
    const assets: FiatTransactionInterface[] = [];
    return (
        <Paper sx={{p: 1}}>
            <Stack direction={'row'} justifyContent={'space-between'} >
                <Typography variant={'h6'}>آخرین واریزهای کوینی</Typography>
                <Button component={RouterLink} to={'/transactions'} variant={'text'} color={'info'} >مشاهده همه</Button>
            </Stack>
            <Grid item xs={12} className='response-font-size'>
                <Paper sx={{p: 1, bgcolor: '#383839', color: 'text.secondary'}} >
                    <Grid container>
                        <Grid item xs={2}>{t('date')}</Grid>
                        <Grid item xs={2}>{t('status')}</Grid>
                        <Grid item xs={2}>{t('amount')}</Grid>
                        <Grid item xs={3}>{t('card_number')}</Grid>
                        <Grid item xs={3}>{t('tracking_number')}</Grid>
                    </Grid>
                </Paper>
            </Grid>
            {
                assets.map(r => (
                    <Grid item xs={12} className='response-font-size' key={r.id}>
                        <Paper sx={{py: 2, px: 1, boxShadow: 'none'}} >
                            <Grid container>
                                <Grid item sm={2} sx={{alignSelf: 'center'}}>{r.date}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{r.status}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{r.amount}</Grid>
                                <Grid item xs={3} sx={{alignSelf: 'center'}}>{r.card}</Grid>
                                <Grid item xs={3} sx={{alignSelf: 'center'}}>{r.tracking}</Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))
            }
            {
                assets.length === 0 && (
                    <Grid item xs={12} className='response-font-size'>
                        <Paper sx={{py: 2, px: 1, boxShadow: 'none'}} >
                            <Grid container>
                                <Grid item sm={12} sx={{alignSelf: 'center'}}>رکوردی یافت نشد</Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )
            }
        </Paper>
    );
}
export default function CryptoTransactionsTable(){
    return <SmUpScreenCryptoTable />
}