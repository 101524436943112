import {Grid} from "@mui/material";
import KycAlert from '../../components/compound/KycAlert';
import PageTitle from "../../components/compound/PageTitle";
import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import {useEffect} from "react";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import DepositForm from "../../features/deposit-form";
import TransactionsTable from "../../features/transactions-table";
import {useParams} from "react-router-dom";
import {setTypeToCrypto, setTypeToFiat} from "../../features/deposit-form/depositFormSlice";


export default function Deposit(){
    const { t } = useTranslation();
    const {symbol} = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(symbol === undefined || symbol === 'irt'){
            dispatch(setTypeToFiat())
        }else{
            dispatch(setTypeToCrypto())
        }
    }, [symbol])
    return (
        <Page title={t('deposit')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('deposit')} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <DepositForm />
                    </Grid>
                    <Grid item xs={12}>
                        <TransactionsTable />
                    </Grid>
                </Grid>
            </BackgroundPaper>
        </Page>
    );
}