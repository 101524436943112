import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import KycAlert from "../../components/compound/KycAlert";
import PageTitle from "../../components/compound/PageTitle";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Paper,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import React, {useEffect, useState} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import IranZaminImg from "../../assets/iranzamin.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as yup from "yup";
import {useFormik} from "formik";
import client from "../../config/client";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#222631',
    color: "#fff",
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
};

interface CardInterface{
    shaba: string;
    card: string;
    name: string;
    id: number;
    status: string;
    status_fa: string;
    icon: string;
    owner: string;
}

function StatusIcon(props: {value: string}){
    const {value} = props;

    if(value === 'approved'){
        return <CheckCircleOutlineIcon color={'success'} />
    }else if(value === 'pending'){
        return <AccessTimeIcon color={'warning'} />
    }else if(value === 'rejected'){
        return <HighlightOffIcon color={'error'} />
    }else{
       return <span></span>
    }
}
function CreditCard(props: {card: CardInterface}){
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    const {card} = props;
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Card sx={{width: 300, bgcolor: 'rgba(247, 247, 247, 1)', color: '#202325'}}>
            <CardContent sx={{fontSize: 'small'}}>
                <Box sx={{display: 'flex', direction: 'row', justifyContent: 'space-between'}}>
                    <Box display={'flex'}>
                        <img src={card.icon} width={24} />
                        <Typography sx={{mt: 'auto', px: 0.5}} fontFamily={'monospace'} fontWeight={'bold'}>{card.name}</Typography>
                    </Box>
                    <Box display={'flex'}>
                        <StatusIcon value={card.status} />
                        <Typography>{card.owner}</Typography>
                    </Box>
                </Box>
                <Box sx={{mt: 3, display: 'flex', direction: 'row', justifyContent: 'space-between'}}>
                    <Box>{t('card_number')}</Box>
                    <Box>{card.card}</Box>
                </Box>
                <Box sx={{mt: 1, display: 'flex', direction: 'row', justifyContent: 'space-between'}}>
                    <Box>{t('sheba_number')}</Box>
                    <Box>IR{card.shaba}</Box>
                </Box>
            </CardContent>
        </Card>
    );
}

function AddCreditCard(){
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [errors, setErrors] = useState([]);
    const [errorsSnackbarIsOpen, setErrorsSnackbarIsOpen] = useState(false);
    const validationSchema = yup.object({
        name: yup
            .string()
            .required(t('this_field_is_required') as string),
        owner: yup
            .string()
            .required(t('this_field_is_required') as string),
        shaba: yup
            .string()
            .required(t('this_field_is_required') as string),
        card: yup
            .string()
            .required(t('this_field_is_required') as string),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            owner: '',
            shaba: '',
            card: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            client.post('/v1/credit-cards', values).then(function(resp){
                handleClose()
                window.location.reload()
            }).catch(function(err){
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors)
                }
                setErrorsSnackbarIsOpen(true)
            }).finally(function(){
                setSubmitting(false);
            })

        },
    });
    const handleCloseErrorsSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setErrorsSnackbarIsOpen(false);
    };



    return (
        <>
            <Card sx={{width: 300, bgcolor: 'rgba(247, 247, 247, 1)', color: '#202325'}}>
                <CardContent sx={{p: 0, paddingBottom: '0!important', display: 'flex', flexDirection: 'column', height: '100%', fontSize: 'small'}}>
                    <Box sx={{
                        p: 1.5,
                        flexGrow: 1,
                        textAlign: 'center',
                        my: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <IconButton onClick={handleOpen}><AddCircleIcon sx={{color: "#FCD535", fontSize: 40}}/></IconButton>
                        <Typography fontWeight={'bold'}>افزودن حساب بانکی</Typography>
                    </Box>
                    <Box sx={{bgcolor: '#D9D9D9', p: 1}}>
                        <Typography textAlign={'center'} color={'text.secondary'}>کلیه کارت های عضو شتاب</Typography>
                    </Box>
                </CardContent>
            </Card>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={formik.handleSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        افزودن حساب بانکی جدید
                    </Typography>
                    <Box mt={2}>
                        <TextField
                            id={'owner'}
                            name={'owner'}
                            fullWidth size={'small'}  label="نام دارنده حساب" variant="outlined"
                            onChange={formik.handleChange}
                            value={formik.values.owner}
                            error={formik.touched.owner && Boolean(formik.errors.owner)}
                            helperText={formik.touched.owner && formik.errors.owner}/>
                    </Box>
                    <Box mt={2}>
                        <TextField
                            id={'name'} name={'name'} fullWidth size={'small'}  label="نام بانک" variant="outlined"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextField
                            fullWidth size={'small'} id="card" label="شماره کارت" variant="outlined"
                            onChange={formik.handleChange}
                            value={formik.values.card}
                            error={formik.touched.card && Boolean(formik.errors.card)}
                            helperText={formik.touched.card && formik.errors.card}
                        />
                    </Box>
                    <Box mt={2}>
                        <TextField fullWidth size={'small'} id="shaba" name={'shaba'} label="شماره شبا" variant="outlined"
                                   onChange={formik.handleChange}
                                   value={formik.values.shaba}
                                   error={formik.touched.shaba && Boolean(formik.errors.shaba)}
                                   helperText={formik.touched.shaba && formik.errors.shaba}

                        />
                    </Box>
                    <Box mt={2}>
                        <Button type={'submit'} disabled={loading} size={'small'} variant={'contained'} color={'primary'}>ذخیره</Button>
                    </Box>
                    </form>
                </Box>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={errorsSnackbarIsOpen} autoHideDuration={6000} onClose={handleCloseErrorsSnackbar}>
                <Alert onClose={handleCloseErrorsSnackbar} severity="error" sx={{ width: '100%', bgcolor: 'error.dark' }}>
                    {errors.length > 0 && errors[0]}
                </Alert>
            </Snackbar>
        </>
    );
}
const Cards = () => {
    const { t } = useTranslation();
    const [creditCards, setCreditCards] = useState<CardInterface[]>([]);
    useEffect(() => {
        client.get('/v1/credit-cards').then(function(resp){
            setCreditCards(resp.data.data)
        })
    }, [])
    return (
        <Page title={t('bank_account')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('bank_account')} link={'/deposit'} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 1}}>
                            <Typography variant={'h6'}>اطلاعات حساب</Typography>
                            <ul>
                                <li>ابتدا اطلاعات فردی خود را تکمیل کرده و پس از تایید شدن آنها اقدام به ثبت کارت بانکی نمایید.</li>
                                <li>در صورتیکه کارت بانکی بنام شما باشد به صورت اتوماتیک تایید شده و قابل استفاده خواهد بود.</li>
                                <li>در صورتیکه کارت متعلق به شما نباشد, از طرف وب سایت تایید نخواهد شد.</li>
                            </ul>
                            <Grid container spacing={2}>
                                {
                                    creditCards.map((c) => (
                                        <Grid item>
                                            <CreditCard key={c.id} card={c} />
                                        </Grid>
                                    ))
                                }
                                <Grid item>
                                    <AddCreditCard />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </BackgroundPaper>
        </Page>
    )
}

export default Cards;