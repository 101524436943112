import {Box, Button, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import RImage from "../../assets/r.png";
import {useEffect, useState} from "react";
import LiraImage from '../../assets/lira.png';
import TejaratImage from '../../assets/tejarat.png';
import {Container} from "@mui/system";
import CurrencySelector from "../../components/atomic/currencyselector";
import ChangeIcon from '../../assets/change_sell_buy.png'
import BreadCrumbEasySellBuy from "../../components/atomic/breadcrumbeasysellbuy";
import ChartPicture from '../../assets/chart_easy_sell.png'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import TextSwitchEasySellBuy from "../../components/atomic/textswitcheasysellbuy/TextSwitchEasySellBuy";
import './Exchange.css'
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {makeDense} from "../../features/footer/FooterSlice";
import SimpleChart from "../../components/SimpleChart";
import TextSwitch from "../../components/TextSwitch";
import ExchangeForm from "../../features/exchange-form"
import {useParams} from "react-router-dom";

export default function Exchange() {
    const { t } = useTranslation();
    const {slug} = useParams();
    useEffect(() => {
        window.location.href = 'https://panel.pouyam.com/login';
    });
    return (
        <Page title={t('easy_sell_and_buy')}>
            <Box>
                <Box sx={{ bgcolor: 'background.default', textAlign: 'center', py: 2 }}>
                    <Typography variant="h5" component="h1" color="text.title" >
                        {t('easy_sell_and_buy')}
                    </Typography>
                </Box>
                <BreadCrumbEasySellBuy />
                <Box>
                    <Container>
                        <Grid container sx={{ py: 2, px: 1 }}>
                            <Grid item xs={12}>
                                <ExchangeForm symbol={slug ? slug : 'btc'}/>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Page>
    );
}