import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Container, Divider, Paper, Stack } from '@mui/material';
import { t } from "i18next";
import TwoFacIcon from '../../../assets/2fac-faq.png';
import TradeIcon from '../../../assets/trade.png';
import HarvestIcon from '../../../assets/harvest.png';
import DepositIcon from '../../../assets/deposit.png';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import EtcIcon from '../../../assets/etcpng.png';
import { Link } from "react-router-dom";
import './AccardionGuidSections.css'
import Icon from '../../../assets/user-check-1.png'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';


const TabPanel1 = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `;

const TabsList = styled(TabsListUnstyled)(
    ({ theme }) => `
    width: 100%;
    margin-top:2rem;
    background-color: background.darkBlue;
    border-radius: 10px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;  

    `,
);




function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}



export function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };


    const [value, setValue] = React.useState(0);

    const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };



    return (
        <Box sx={{ justifyContent: 'space-between', bgcolor: 'background.paper', borderRadius: '10px' }}>
            <Box     >
                <Accordion disableGutters className='accardion_parent' sx={{ borderRadius: '10px !important', boxShadow: 'none' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary
                        className='accardion-summary-guid'
                        expandIcon={<KeyboardArrowLeftOutlinedIcon sx={{ m: 3 }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '1.5rem' }}>
                            <img src={Icon} width="100%" />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            {t('register_and_auth')}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", p: 0 }} >

                        <Tabs
                            className='tabs-accardion-guid-sections'
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange1}
                            aria-label="Vertical tabs example"
                            sx={{ textAlign: 'right' }}
                        >
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_to_register')}`} {...a11yProps(0)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_to_auth')}`}{...a11yProps(1)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('user_levels')}`} {...a11yProps(2)} />
                        </Tabs>

                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters className='accardion_parent' sx={{ borderRadius: '10px !important', boxShadow: 'none' }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
                    <AccordionSummary
                        className='accardion-summary-guid'
                        expandIcon={<KeyboardArrowLeftOutlinedIcon sx={{ m: 3 }} />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '1.5rem' }}>
                            <img src={DepositIcon} width="100%" />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            {t('deposit')}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", p: 0 }} >

                        <Tabs
                            className='tabs-accardion-guid-sections'
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange1}
                            aria-label="Vertical tabs example 1"
                            sx={{ textAlign: 'right' }}
                        >
                            <Tab sx={{ textAlign: 'right' }} label={`${t('deposit_fiat')}`} {...a11yProps(3)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('deposit_coin')}`}{...a11yProps(4)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('deposit_history')}`} {...a11yProps(5)} />
                        </Tabs>

                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters className='accardion_parent' sx={{ borderRadius: '10px !important', boxShadow: 'none' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                    <AccordionSummary
                        className='accardion-summary-guid'
                        expandIcon={<KeyboardArrowLeftOutlinedIcon sx={{ m: 3 }} />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '1.5rem' }}>
                            <img src={HarvestIcon} width="100%" />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            {t('harvest')}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", p: 0 }} >

                        <Tabs
                            className='tabs-accardion-guid-sections'
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange1}
                            aria-label="Vertical tabs example 2"
                            sx={{ textAlign: 'right' }}
                        >
                            <Tab sx={{ textAlign: 'right' }} label={`${t('harvest_fiat')}`} {...a11yProps(6)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('harvest_coin')}`}{...a11yProps(7)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('harvest_history')}`} {...a11yProps(8)} />
                        </Tabs>

                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters className='accardion_parent' sx={{ borderRadius: '10px !important', boxShadow: 'none' }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
                    <AccordionSummary
                        className='accardion-summary-guid'
                        expandIcon={<KeyboardArrowLeftOutlinedIcon sx={{ m: 3 }} />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '1.5rem' }}>
                            <img src={TradeIcon} width="100%" />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            {t('trade_farsi')}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", p: 0 }} >

                        <Tabs
                            className='tabs-accardion-guid-sections'
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange1}
                            aria-label="Vertical tabs example 2"
                            sx={{ textAlign: 'right' }}
                        >
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_trade_proffessional')}`} {...a11yProps(9)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_easy_sell_buy')}`}{...a11yProps(10)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_teach_sl')}`} {...a11yProps(11)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_order_trade')}`} {...a11yProps(12)} />
                        </Tabs>

                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters className='accardion_parent' sx={{ borderRadius: '10px !important', boxShadow: 'none' }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')} >
                    <AccordionSummary
                        className='accardion-summary-guid'
                        expandIcon={<KeyboardArrowLeftOutlinedIcon sx={{ m: 3 }} />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '1.5rem' }}>
                            <img src={TwoFacIcon} width="100%" />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            {t('two_fac_auth')}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", p: 0 }} >

                        <Tabs
                            className='tabs-accardion-guid-sections'
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange1}
                            aria-label="Vertical tabs example 2"
                            sx={{ textAlign: 'right' }}
                        >
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_active_authenticator')}`} {...a11yProps(13)} />
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_active_email_sms')}`}{...a11yProps(14)} />

                        </Tabs>

                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters className='accardion_parent' sx={{ borderRadius: '10px !important', boxShadow: 'none' }}

                    expanded={expanded === 'panel6'} onChange={handleChange('panel6')} >
                    <AccordionSummary
                        className='accardion-summary-guid'
                        expandIcon={<KeyboardArrowLeftOutlinedIcon sx={{ m: 3 }} />}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                        sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box sx={{ width: '1.5rem' }}>
                            <img src={EtcIcon} width="100%" />
                        </Box>
                        <Typography sx={{ ml: 1 }}>
                            {t('etc')}
                        </Typography>

                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", p: 0 }} >

                        <Tabs
                            className='tabs-accardion-guid-sections'
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange1}
                            aria-label="Vertical tabs example 2"
                            sx={{ textAlign: 'right' }}
                        >
                            <Tab sx={{ textAlign: 'right' }} label={`${t('how_use_ref_code')}`} {...a11yProps(15)} />
                        </Tabs>

                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
}

export default function GuidSectionsAccardion() {

    const [value, setValue] = React.useState(0);



    return (
        <Box sx={{ mx: 0, px: 0, py: 2, bgcolor: 'background.darkBlack' }} >
            <Container>
                <Typography variant='h6' sx={{ color: 'text.secondary', textAlign: 'left', my: 2, pl: .4 }}>
                    {t('level_subjects')}
                </Typography>
                <Box sx={{ pb: 3, pt: 0, display: 'flex' }}>
                    <TabsUnstyled defaultValue={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TabPanel1 value={0} >
                                    <ControlledAccordions />
                                </TabPanel1>
                            </Grid>
                            <Grid item xs={12} sm={8} md={9}>
                                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', p: 1.4, borderRadius: '10px' }}>
                                    <Stack sx={{ p: 2 }}>
                                        <Typography variant='subtitle1' sx={{ color: 'text.title' }}>
                                            {t('how_register_pouyam')}
                                        </Typography>
                                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                                            {t('lorem')}
                                        </Typography>
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Link to="/authentication" style={{ padding: '0 12px' }}>
                                        <KeyboardArrowLeftOutlinedIcon sx={{ color: "text.title" }} />
                                    </Link>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', p: 1.4, borderRadius: '10px', mt: 1.75 }}>
                                    <Stack sx={{ p: 2 }}>
                                        <Typography variant='subtitle1' sx={{ color: 'text.title' }}>
                                            {t('how_register_pouyam')}
                                        </Typography>
                                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                                            {t('lorem')}
                                        </Typography>
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Link to="/authentication" style={{ padding: '0 12px' }}>
                                        <KeyboardArrowLeftOutlinedIcon sx={{ color: "text.title" }} />
                                    </Link>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', p: 1.4, borderRadius: '10px', mt: 1.75 }}>
                                    <Stack sx={{ p: 2 }}>
                                        <Typography variant='subtitle1' sx={{ color: 'text.title' }}>
                                            {t('how_register_pouyam')}
                                        </Typography>
                                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                                            {t('lorem')}
                                        </Typography>
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Link to="/authentication" style={{ padding: '0 12px' }}>
                                        <KeyboardArrowLeftOutlinedIcon sx={{ color: "text.title" }} />
                                    </Link>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', p: 1.4, borderRadius: '10px', mt: 1.75 }}>
                                    <Stack sx={{ p: 2 }}>
                                        <Typography variant='subtitle1' sx={{ color: 'text.title' }}>
                                            {t('how_register_pouyam')}
                                        </Typography>
                                        <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                                            {t('lorem')}
                                        </Typography>
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Link to="/authentication" style={{ padding: '0 12px' }}>
                                        <KeyboardArrowLeftOutlinedIcon sx={{ color: "text.title" }} />
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </TabsUnstyled>
                </Box>
            </Container>
        </Box >
    );
}

