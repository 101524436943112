import {IconButton, Stack} from "@mui/material";
import {Instagram, LinkedIn, Telegram, Twitter} from "@mui/icons-material";

const SocialMediaRoundedBtnGroup = () => {
    return (
        <Stack direction="row" spacing={1}>
            <IconButton href={'https://instagram.com/exchangepouyam?igshid=NTc4MTIwNjQ2YQ=='} target={'_blank'} aria-label="instagram"  sx={{border: 1}} size="small">
                <Instagram />
            </IconButton>
            <IconButton href={'https://t.me/pouyamexchange'} target={'_blank'} aria-label="telegram" sx={{border: 1}} size="small">
                <Telegram />
            </IconButton>
            <IconButton href={'https://twitter.com/pouyamexchange'} target={'_blank'} aria-label="twitter" sx={{border: 1}} size="small">
                <Twitter />
            </IconButton>
        </Stack>
    );
}

 export default SocialMediaRoundedBtnGroup;
