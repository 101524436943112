import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import KycAlert from "../../components/compound/KycAlert";
import PageTitle from "../../components/compound/PageTitle";
import {Box, Button, Divider, Grid, IconButton, Paper, Typography} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeDense} from "../../features/footer/FooterSlice";

const Settings = () => {
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('settings')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('user_account')} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 2}}>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Box>
                                        <IconButton>
                                            <AccountCircleIcon sx={{ fontSize: 80 }} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{mb: 2}}>
                                        <Typography>{t('user_vip_level')}</Typography>
                                    </Box>
                                    <Box>
                                        <Button variant='contained' color={'error'}>Bar</Button>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{mb: 2}}>
                                        <Typography>{t('username')}: 231451</Typography>
                                    </Box>
                                    <Typography color={'text.secondary'}>{t('user_account_kyc_warning')}</Typography>
                                </Grid>
                                <Grid item flexGrow={1} sx={{textAlign: 'right', mt: 'auto'}}>
                                    <Box>
                                        <Button>{t('start_verification')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider sx={{my: 2}} />
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Box>
                                        <PersonIcon />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{mb: 1}}>
                                        <Typography variant={'h6'}>{t('nickname')}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography color={'text.secondary'}>{t('lorem').slice(0, 50)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                                    <Box>
                                        <Button variant={'contained'}>{t('edit')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider sx={{my: 2}} />
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Box>
                                        <PhoneIphoneIcon />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{mb: 1}}>
                                        <Typography variant={'h6'}>{t('phone_number')}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography color={'text.secondary'}>{t('lorem').slice(0, 50)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                                    <Box sx={{direction: 'rtl'}}>
                                        <Typography>{"0912****10"}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider sx={{my: 2}} />
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Box>
                                        <EmailIcon />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box sx={{mb: 1}}>
                                        <Typography variant={'h6'}>{t('email')}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography color={'text.secondary'}>{t('lorem').slice(0, 50)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                                    <Box sx={{direction: 'rtl'}}>
                                        <Typography>{"rab****@gmail.com"}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </BackgroundPaper>
        </Page>
    )
}

export default Settings;