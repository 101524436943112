import Level0Img from '../../assets/level0.png';
import Level1Img from '../../assets/level1.png';
import Level2Img from '../../assets/level2.png';
import Level3Img from '../../assets/level3.png';
import Level4Img from '../../assets/level4.png';

export default function Percent(props: {level: number}){
    const {level} = props;
    let levelImg = Level0Img;
    if(level === 1){
        levelImg = Level1Img;
    }else if(level === 2){
        levelImg = Level2Img;
    }else if(level === 3){
        levelImg = Level3Img;
    }else if (level === 4){
        levelImg = Level4Img;
    }
    return (
        <img src={levelImg} />
    );
}