import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Divider, Grid, IconButton, Paper} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PieIcon from "../../../assets/pie.png";
import {useTranslation} from "react-i18next";
import RoundedButton from "../../atomic/RoundedButton";
import {Link as RouterLink} from 'react-router-dom';
import BalancesPieChart from "../../BalancesPieChart";
import {useEffect, useState} from "react";
import client from "../../../config/client";
import {number_format} from "../../../config/helpers";

export default function BalanceStat(props: {expanded?: boolean}){
    const { t } = useTranslation();
    const {expanded} = props;
    const [balance, setBalnace] = useState({
        irt: 0,
        btc: 0,
        usd: 0
    });

    useEffect(() => {
        client.get(`/v4/assets/balance`).then(function(resp){
            setBalnace(resp.data.data);
        })
    }, [])

    return (
        <Paper sx={{p: 2}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={{xs:1, sm: 2}}
            >
                <Typography variant={'h6'}>{ t('balance_details') }</Typography>
                {
                    !expanded && (
                        <>
                            <Box>
                                <RoundedButton href={'/deposit'} sx={{mr: 1}} size={'small'}  variant='contained'>{ t('deposit') }</RoundedButton>
                                <RoundedButton href={'/withdraw'} size={'small'}  variant='contained'>{ t('withdraw') }</RoundedButton>
                            </Box>
                            <IconButton component={RouterLink} to={'/wallet'} sx={{color: 'text.secondary'}}><KeyboardBackspaceIcon /></IconButton>
                        </>
                    )
                }
            </Stack>
            <Divider />
            <Grid container sx={{py: 2}}>
                <Grid item xs={4}>
                    <Stack spacing={2}>
                        <Box>
                            <Typography color={'text.secondary'}>{ t('account_balance') } <RoundedButton variant='contained' size={'small'}><VisibilityOffIcon /> { t('hide') }</RoundedButton></Typography>
                            <Typography>{number_format(balance.irt)} {t('toman')}</Typography>
                        </Box>
                        <Box>
                            <Typography color={'text.secondary'}>{ t('dollar_based') }</Typography>
                            <Typography sx={{direction: 'rtl', textAlign: 'left'}}>{number_format(balance.usd)} $</Typography>
                        </Box>
                        <Box>
                            <Typography color={'text.secondary'}>{ t('btc_based') }</Typography>
                            <Typography sx={{direction: 'rtl', textAlign: 'left'}}>{number_format(balance.btc)} BTC</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <Box>
                        <BalancesPieChart />
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}