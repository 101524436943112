import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { t } from "i18next";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function BreadCrumbGuidSections() {
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            {t('home')}
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            href="/material-ui/getting-started/installation/"
            onClick={handleClick}
        >
            {t('guid')}
        </Link>,
        <Typography key="3" color="text.primary">
             {t('register_and_auth')}
        </Typography>,
    ];

    return (
        <Box sx={{bgcolor:"background.paper" , py:1.5}}>
            <Container>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Container>
        </Box>
    );
}
