import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Alert,
    Button,
    Collapse,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import client from "../../config/client";
import LoadingButton from "@mui/lab/LoadingButton";
import MetallicAlert from "../../components/atomic/metallic-alert";
import CloseIcon from "@mui/icons-material/Close";
import {AlertTitle} from "@mui/lab";
import CircleIcon from "@mui/icons-material/Circle";

function Step1(props: {onSuccess: () => any}){
    const [t] = useTranslation();
    const [errors, setErrors] = useState([]);
    const [openErrorsAlert, setOpenErrorsAlert] = useState(false)
    const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);
    const validationSchema = yup.object({
        fname: yup
            .string()
            .required(t('this_field_is_required') as string),
        lname: yup
            .string()
            .required(t('this_field_is_required') as string),
        nid: yup
            .string()
            .required(t('this_field_is_required') as string),
        fathername: yup
            .string()
            .required(t('this_field_is_required') as string),
        birth_date: yup
            .string()
            .required(t('this_field_is_required') as string),
        id_card_image: yup
            .string()
            .required(t('this_field_is_required') as string),
    });

    const formik = useFormik({
        initialValues: {
            fname: '',
            lname: '',
            fathername: '',
            nid: '',
            birth_date: '',
            id_card_image: null
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            setErrors([]);
            let data = new FormData();
            // @ts-ignore
            Object.keys(values).forEach((o) => data.append(o, values[o]));

            // @ts-ignore
            data.append('type', 'identity');
            data.append('fullname', values.fname + ' ' + values.lname);
            client.postForm('/v2/verification/information/send', data).then(function(resp){
                setSuccessSnackbarIsOpen(true);
                props.onSuccess();

            }).catch(function(err){
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors);
                    setOpenErrorsAlert(true);
                }
            }).finally(function(){
                setSubmitting(false);
            })
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography mb={2} textAlign={'center'} fontWeight={'bold'} variant={'h5'}>{t('personal_info')}</Typography>
                </Grid>
                {
                    errors.length > 0 && (
                        <Grid item xs={12}>
                            <Collapse in={openErrorsAlert}>
                                <MetallicAlert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenErrorsAlert(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    severity={'error'}>
                                    <AlertTitle>{t('server_error')}!</AlertTitle>
                                    <List dense>
                                        {errors.map(function(err, indx){
                                            return (
                                                <ListItem sx={{px: 0}} key={indx}>
                                                    <ListItemIcon sx={{minWidth: '20px'}}><CircleIcon color={'error'} fontSize={'small'} /></ListItemIcon>
                                                    <ListItemText primary={err} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </MetallicAlert>
                            </Collapse>
                        </Grid>
                    )
                }
                <Grid item xs={12} md={6}>
                    <Typography fontSize={'small'} color={'text.secondary'}>{t('first_name')}</Typography>
                    <TextField
                        size={'small'}
                        fullWidth
                        id={'fname'}
                        name={'fname'}
                        onChange={formik.handleChange}
                        value={formik.values.fname}
                        error={formik.touched.fname && Boolean(formik.errors.fname)}
                        helperText={formik.touched.fname && formik.errors.fname}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={'small'} color={'text.secondary'}>{t('last_name')}</Typography>
                    <TextField
                        size={'small'}
                        fullWidth
                        id={'lname'}
                        name={'lname'}
                        onChange={formik.handleChange}
                        value={formik.values.lname}
                        error={formik.touched.lname && Boolean(formik.errors.lname)}
                        helperText={formik.touched.lname && formik.errors.lname}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={'small'} color={'text.secondary'}>{t('nid')}</Typography>
                    <TextField
                        size={'small'}
                        fullWidth
                        id={'nid'}
                        name={'nid'}
                        onChange={formik.handleChange}
                        value={formik.values.nid}
                        error={formik.touched.nid && Boolean(formik.errors.nid)}
                        helperText={formik.touched.nid && formik.errors.nid}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={'small'} color={'text.secondary'}>{t('father_name')}</Typography>
                    <TextField
                        size={'small'}
                        fullWidth
                        id={'fathername'}
                        name={'fathername'}
                        onChange={formik.handleChange}
                        value={formik.values.fathername}
                        error={formik.touched.fathername && Boolean(formik.errors.fathername)}
                        helperText={formik.touched.fathername && formik.errors.fathername}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={'small'} color={'text.secondary'}>{t('nid_image')}</Typography>
                    <input
                        type={'file'}
                        id={'id_card_image'}
                        name={'id_card_image'}
                        onChange={(event) => {
                            if(event?.currentTarget?.files){
                                formik.setFieldValue("id_card_image", event?.currentTarget?.files[0]);
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography fontSize={'small'} color={'text.secondary'}>{t('birth_date')}</Typography>
                    <TextField
                        size={'small'}
                        fullWidth
                        id={'birth_date'}
                        name={'birth_date'}
                        onChange={formik.handleChange}
                        value={formik.values.birth_date}
                        error={formik.touched.birth_date && Boolean(formik.errors.birth_date)}
                        helperText={formik.touched.birth_date && formik.errors.birth_date}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!formik.isSubmitting && <Button type="submit" variant="contained" fullWidth size='large'>{ t('send') }</Button>}
                    {formik.isSubmitting && (
                        <LoadingButton loading variant="outlined" fullWidth size='large' loadingIndicator={t('validating')}>
                            { t('loading') }
                        </LoadingButton>
                    )}
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={successSnackbarIsOpen} autoHideDuration={6000} >
                <Alert  severity="success" sx={{ width: '100%', bgcolor: 'success.dark' }}>
                    {t('success')}
                </Alert>
            </Snackbar>
        </form>
    );

}

function Step2(){
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>مدارک شما با موفقیت ثبت شد،‌لطفا تا بررسی توسط مدیریت صبور باشید</Typography>
        </Stack>
    );
}

function Step3(){
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Alert
                severity="warning"
                icon={false}
                sx={{bgcolor: 'rgba(252, 213, 53, 0.1)', color: '#FAFAFA', textAlign: 'center'}}
                variant="outlined">
                <Typography>مدارک شما با موفقیت تایید شد</Typography>

            </Alert>
        </Stack>
    );
}

function Step4(){
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>در حال بارگذاری...</Typography>
        </Stack>
    );
}

export default function PersonalInfoVerification(){
    const [step, setStep] = useState(4);

    useEffect(() => {
        client.get('/v4/verifications/information').then((resp)=>{
           if(resp?.data?.data?.status){
               if(resp.data.data.status == 'sent'){
                   setStep(1);
               }else if(resp.data.data.status == 'verified'){
                   setStep(2);
               }else{
                   setStep(0)
               }
           }
        });
    }, [])
    function gotoStep2(){
        setStep(1);
    }
    if(step === 0){
        return <Step1  onSuccess={gotoStep2}/>
    }else if(step === 1){
        return <Step2 />
    }else if(step === 2){
        return <Step3 />
    }else{
        return <Step4 />
    }
}