import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface DrawerState {
    open: boolean;
}

const initialState: DrawerState = {
    open: false,
};

export const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        close: (state) => {
            state.open = false;
        },
        open: (state) => {
            state.open = true;
        },
        toggle: (state) => {
            state.open = !state.open;
        }
    },
});

export const { open, close, toggle } = drawerSlice.actions;

export const selectDrawerStatus = (state: RootState) => state.drawer.open;

export default drawerSlice.reducer;
