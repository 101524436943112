import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectType, setStep} from "./ForgetSlice";
import DoubleCheckMarkImage from '../../assets/double-check-mark.png'
import {Link as RouterLink, useNavigate} from 'react-router-dom';

export default function FinishCard(){
    const { t } = useTranslation();
    const type = useAppSelector(selectType);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    function handleReturnToLoginPageButton(){
        dispatch(setStep('username'));
        navigate('/login');
    }
    return (
        <React.Fragment>
            <Card sx={{borderRadius: '10px'}}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontWeight='bold' variant='h6' component='h4' textAlign='center'>{ t('success_password_change') }</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <Box component={'img'} src={DoubleCheckMarkImage} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontSize={'small'} color={'text.secondary'} textAlign={'center'}>{t('password_changed_successfully')}</Typography>
                            <Typography sx={{mt: 2}}  textAlign={'center'}>{t('click_link_below_for_login')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleReturnToLoginPageButton} variant={'contained'} size={'large'} fullWidth>{t('login')}</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}