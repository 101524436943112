import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {number_format} from "../../config/helpers";

interface ItemInterface{
    value: string,
    country: string,
    label: string,
    balance: string,
    icon: string
}

export default function FiatSelector(props: {items: Array<ItemInterface>}){
    const { t } = useTranslation();
    const {items} = props;
    const [value, setValue] = useState<ItemInterface>(() => {
        if(items.length) return items[0];
        return {
            value: '',
            country: '',
            label: '',
            balance: '',
            icon: ''
        };
    });

    useEffect(() => {
        if(items.length) {
            setValue(items[0]);
        }else{
            setValue({
                value: '',
                country: '',
                label: '',
                balance: '',
                icon: ''
            })
        }
        return ;
    }, [items])

    function SelectedItem(){
        return (
            <Grid container spacing={1}>
                <Grid item>
                    <img src={value.icon} width="24" />
                </Grid>
                <Grid item flexGrow={1}>
                    <Typography>{ value?.label }</Typography>
                </Grid>
            </Grid>
        );
    }
    return (
        <Box sx={{mb: 2}}>
            <Typography color={'text.secondary'} sx={{mb: 1}}><PriceChangeIcon /> {t('select_fiat_currency')}</Typography>
            <Select
                fullWidth
                value={value}
                size="small"
                renderValue={SelectedItem}
                IconComponent={KeyboardArrowDownIcon}
            >

                {props.items.map((option) => (
                    <MenuItem
                        onClick={(event: any) => {
                            setValue(option)
                        }}
                        key={option.value} value={option as any}>
                        <Grid container spacing={1}>
                            <Grid item sx={{alignSelf: 'center'}}>
                                <img src={option.icon} width={32} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <Typography>{option.label}</Typography>
                                <Typography color={'text.secondary'}>{option.country}</Typography>
                            </Grid>
                            <Grid item minWidth={'140px'}>
                                <Typography color={'text.secondary'}>{t('balance')}:</Typography>
                                <Typography>{number_format(option.balance)} {option.label}</Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}