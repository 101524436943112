import {useTranslation} from "react-i18next";
import {Box, Button, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectCount} from "../counter/counterSlice";
import {setAmount} from "./withdrawFormSlice";

export default function WithdrawAmount(){
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [value, setValue] = useState<string | number>('' );
    function handleChangeValue(value: string){
        console.log(value)
        setValue(value);
        dispatch(setAmount(value))
    }

    return (
        <Box sx={{mb: 2}}>
            <Stack direction={'row'} sx={{mb: 1}} justifyContent={'space-between'}>
                <Typography sx={{mt: 'auto'}} color={'text.secondary'}><AttachMoneyIcon /> {t('withdraw_amount')}</Typography>
            </Stack>
            <TextField
                fullWidth
                size={'small'}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeValue(event.target.value);
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="start">{t('toman')}</InputAdornment>,
                }}
                variant="outlined" />
        </Box>
    );
}