import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Container, Divider, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import TwoFacIcon from '../../../assets/2fac-faq.png';
import TradeIcon from '../../../assets/trade.png';
import HarvestIcon from '../../../assets/harvest.png';
import DepositIcon from '../../../assets/deposit.png';
import IntroFriendsIcon from '../../../assets/intro-friends.png';
import UserRegisterIcon from '../../../assets/user-check-1.png';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';





const Tab = styled(TabUnstyled)`
    background-color: #222631;
    padding: 10px 0;
    border: 0px;
    display: flex;
    justify-content: center;
    border-radius: 10px ;
    width:100%;
    height:9rem;
    align-items:center;

    &:hover {
    }
  
    &:focus {
      color: #fff;
    }
  
    &.${tabUnstyledClasses.selected} {
        border:1px solid #FCD535;
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
      border: none;
    }
  `;

const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `;

const TabsList = styled(TabsListUnstyled)(
    ({ theme }) => `
    width: 100%;
    margin-top:2rem;
    background-color: background.darkBlue;
    border-radius: 10px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;  

    `,
);






export function ControlledAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };



    return (
        <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={9}  >
                <Accordion disableGutters sx={{ borderRadius: '10px !important', p: 1, m: 2 }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ borderRadius: '10px' }}
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters sx={[{ borderRadius: '10px', p: 1, m: 2 }, { '&::before': { height: 0 }, }]} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                        expandIcon={<ExpandCircleDownOutlinedIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            {t('exchange_crypto')}
                        </Typography>
                    </AccordionSummary>
                    <Divider sx={{ borderColor: 'text.primary', borderBottomWidth: '1px' }} />
                    <AccordionDetails>
                        <Typography variant='body2'>
                            {t('exchange_crypto_paragraph')}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default function AccardionFaq() {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ mx: 0, px: 0, bgcolor: 'inherit' }} >
            <Container >
                <Box sx={{ pb: 3, pt: 0 }}>
                    <TabsUnstyled defaultValue={0}>

                        <TabsList>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Tab value={0}>

                                        <Box>
                                            <img src={DepositIcon} alt="icon image" width="100%" />
                                        </Box>
                                        <Typography variant="subtitle1" component="span" color="text.title">
                                            {t('deposit')}
                                        </Typography>

                                    </Tab>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Tab value={1}>
                                        <Box>
                                            <img src={TwoFacIcon} alt="icon image" width="100%" />
                                        </Box>
                                        <Typography variant="subtitle1" component="span" color="text.title">
                                            {t('twofactor_auth')}
                                        </Typography>
                                    </Tab>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Tab value={2}>

                                        <Box>
                                            <img src={TradeIcon} alt="icon image" width="100%" />
                                        </Box>
                                        <Typography variant="subtitle1" component="span" color="text.title">
                                            {t('trade')}
                                        </Typography>

                                    </Tab>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Tab value={3}>

                                        <Box>
                                            <img src={HarvestIcon} alt="icon image" width="100%" />
                                        </Box>
                                        <Typography variant="subtitle1" component="span" color="text.title">
                                            {t('harvest')}
                                        </Typography>

                                    </Tab>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Tab value={4}>

                                        <Box>
                                            <img src={UserRegisterIcon} alt="icon image" width="100%" />
                                        </Box>
                                        <Typography variant="subtitle1" component="span" color="text.title">
                                            {t('Authentication')}
                                        </Typography>

                                    </Tab>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Tab value={5}>

                                        <Box>
                                            <img src={IntroFriendsIcon} alt="icon image" width="100%" />
                                        </Box>
                                        <Typography variant="subtitle1" component="span" color="text.title">
                                            {t('introduce_to_friends')}
                                        </Typography>

                                    </Tab>
                                </Grid>
                            </Grid>
                        </TabsList>
                        <Box sx={{ mt: 6.5, mb: 4 }}>
                            <Typography variant="h4" component="h5" color="text.title" sx={{ textAlign: 'center' }}>
                                {t('faq')}
                            </Typography>
                            <Typography variant="subtitle1" component="h5" color="text.title" sx={{ textAlign: 'center', mt: 2 }}>
                                {t('lorem').substr(0, 58)}
                            </Typography>
                        </Box>
                        <TabPanel value={0} >
                            <ControlledAccordions />
                        </TabPanel>
                        <TabPanel value={1} >
                            <ControlledAccordions />
                        </TabPanel>
                        <TabPanel value={2} >
                            <ControlledAccordions />
                        </TabPanel>
                        <TabPanel value={3} >
                            <ControlledAccordions />
                        </TabPanel>
                        <TabPanel value={4} >
                            <ControlledAccordions />
                        </TabPanel>
                        <TabPanel value={5} >
                            <ControlledAccordions />
                        </TabPanel>
                    </TabsUnstyled>
                </Box>
            </Container>
        </Box >
    );
}

