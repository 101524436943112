import {Alert, Box, Button, Stack, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import client from "../../config/client";

function EmailVerificationStep1(props: {onOtp: () => any}){
    const [t] = useTranslation();
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    function handleSubmit(){
        setLoading(true);
        let data = {
            type: 'email_code_request',
            email: email,
        }
        client.post('/v1/verifications', data).then((resp)=>{
            props.onOtp();
        }).catch((err) => {
            if(err?.response?.data?.errors){
                setError(err?.response?.data?.errors[0])
            }
        }).finally(()=>setLoading(false))
    }
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>{t('email_confirmation')}</Typography>
            <Box>
                <Typography color={'text.secondary'}>{t('enter_your_email_address')}</Typography>
                <TextField
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(event.target.value);
                    }}
                    sx={{direction: 'rtl'}}
                    size={'small'}
                    placeholder={'example@site.com'}
                    fullWidth />
                <Typography color={'error'}>{error}</Typography>
            </Box>
            <Box>
                <Button disabled={loading} onClick={handleSubmit} sx={{minWidth: 80}}  size={'large'} variant={'contained'}>{t('confirm')}</Button>
            </Box>
        </Stack>
    );
}

function EmailVerificationStep2(props: {email: string, onEdit: () => any}){
    const [t] = useTranslation();
    const {email} = props;

    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>لینک تایید ایمیل برای شما ارسال شد. لطفا صندوق ورودی خود را چک کنید.</Typography>
            <Alert
                severity="warning"
                icon={false}
                sx={{bgcolor: 'rgba(252, 213, 53, 0.1)', color: '#FAFAFA', textAlign: 'center'}}
                variant="outlined">
                <Typography>{t('confirmation_code_sent_to_email', {email: email})}</Typography>
                <Box>
                    <Button onClick={() => props.onEdit()} variant={'text'} color={'info'}>
                        <BorderColorIcon /> {t('edit_email')}
                    </Button>
                </Box>
            </Alert>
        </Stack>
    );
}

function EmailVerificationStep3(props: {email: string}){
    const [t] = useTranslation();
    const {email} = props;
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography>
                <CheckCircleOutlineIcon color={'success'} /> {t('your_email_is_confirmed')}
            </Typography>
            <Alert
                severity="warning"
                icon={false}
                sx={{bgcolor: 'rgba(252, 213, 53, 0.1)', color: '#FAFAFA', textAlign: 'center', display: 'block'}}
                variant="outlined">
                <Typography sx={{textAlign: 'center', display: 'block'}}>{email}</Typography>
            </Alert>
        </Stack>
    );
}

function Loading(){
    const [t] = useTranslation();

    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography>{t('loading_please_wait')}</Typography>
        </Stack>
    )
}
export default function EmailVerification(){
    const [step, setStep] = useState(3);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        setLoading(true)
        client.get('v4/verifications/email').then((resp) => {
            setEmail(resp.data.data.email);
            setStatus(resp.data.data.status);
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if(status === 'verified'){
            setStep(2);
        }else{
            setStep(0);
        }
    }, [status])


    function setStep1(){
        setStep(0);
    }

    function setStep2(){
        setStep(1);
    }

    if(loading){
        return <Loading />
    }

    if(step === 0){
        return <EmailVerificationStep1 onOtp={setStep2} />
    }else if(step === 1){
        return <EmailVerificationStep2 onEdit={setStep1} email={email} />;
    }else{
        return <EmailVerificationStep3 email={email} />
    }
}