import HowToRegIcon from '@mui/icons-material/HowToReg';
import {Alert, Button, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {Link as RouterLink} from 'react-router-dom';

export default function KycAlert(){
    const { t } = useTranslation();
    const theme = useTheme();
    const smallUpScreens = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Alert
            sx={{
                justifyContent: 'center',
                borderRadius: 0,
                color: '#FEB019!important',
                bgcolor: "rgba(254, 176, 25, 0.1)!important",
                '& .MuiAlert-action': {
                    marginLeft: '5px!important'
                }
            }}
            icon={<HowToRegIcon />}
            severity="warning"
            action={
                smallUpScreens ?
                <Button component={RouterLink} to={'/kyc'} color="warning" variant="contained" size="small">
                    { t('kyc') }
                </Button> : <IconButton component={RouterLink} to={'/kyc'}  color="warning"><KeyboardBackspaceIcon /></IconButton>
            }>{ t('kyc_alert_message') }</Alert>
    );
}