import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import TextSwitch from "../../components/TextSwitch";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadingButton from '@mui/lab/LoadingButton';
import {useFormik} from "formik";
import * as yup from 'yup';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectType, selectUsername, setType, setUsername,} from "./LoginSlice";
import client from "../../config/client";
import CloseIcon from '@mui/icons-material/Close';
import {AlertTitle} from "@mui/lab";
import MetallicAlert from "../../components/atomic/metallic-alert";
import CircleIcon from '@mui/icons-material/Circle';
import KeyIcon from "@mui/icons-material/Key";
import PasswordField from "../../components/atomic/PasswordField";

export default function UserNameCard(){
    const dispatch = useAppDispatch();
    const defaultUsername = useAppSelector(selectUsername);
    const { t } = useTranslation();
    const type = useAppSelector(selectType);
    const [errors, setErrors] = useState([]);
    const [openErrorsAlert, setOpenErrorsAlert] = useState(false)
    const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);
    const navigate = useNavigate();
    const emailValidationSchema = yup.object({
        username: yup
            .string()
            .email(t('enter_a_valid_email_address') as string)
            .required(t('email_is_required') as string),
        password: yup
            .string()
            .required(t('password_is_required') as string)
    });
    const mobileValidationSchema = yup.object({
        username: yup
            .string()
            .matches(/^9[0-9]{9,}$/, t('phone_format_is_invalid') as string)
            .required(t('phone_is_required') as string),
        password: yup
            .string()
            .required(t('password_is_required') as string)
    });
    const validationSchema = type === 'email' ? emailValidationSchema : mobileValidationSchema;

    function handleTypeChange(newVal: 0 | 1){
        formik.setFieldValue('username', '')
        formik.setTouched({}, false)
        dispatch(setUsername(''))
        if(newVal === 0) dispatch(setType('email'))
        else dispatch(setType('mobile'))
    }
    const formik = useFormik({
        initialValues: {
            username: defaultUsername,
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            setErrors([]);
            let data = {password: values.password, username: values.username};
            client.post('/v4/auth/login', data).then(function(resp){
                setSuccessSnackbarIsOpen(true)
                localStorage.setItem('access_token', resp?.data?.data?.access_token);
                setTimeout(function(){
                    window.location.reload()
                }, 2000)
            }).catch(function(err){
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors);
                    setOpenErrorsAlert(true);
                }
            }).finally(function(){
                setSubmitting(false);
            })
        },
    });

    return (
        <React.Fragment>
            <Card sx={{borderRadius: '10px'}}>
                <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography fontWeight='bold' variant='h6' component='h4' textAlign='center'>{ t('login_to_your_account') }</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                                <TextSwitch defaultValue={type === 'email' ? 0 : 1} onLabel={t('mobile') as string} offLabel={t('email') as string} handleChange={handleTypeChange} />
                            </Grid>
                            {
                                errors.length > 0 && (
                                    <Grid item xs={12}>
                                        <Collapse in={openErrorsAlert}>
                                            <MetallicAlert
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenErrorsAlert(false);
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                                severity={'error'}>
                                                <AlertTitle>{t('server_error')}!</AlertTitle>
                                                <List dense>
                                                    {errors.map(function(err, indx){
                                                        return (
                                                            <ListItem sx={{px: 0}} key={indx}>
                                                                <ListItemIcon sx={{minWidth: '20px'}}><CircleIcon color={'error'} fontSize={'small'} /></ListItemIcon>
                                                                <ListItemText primary={err} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                            </MetallicAlert>
                                        </Collapse>
                                    </Grid>
                                )
                            }
                            {
                                type === 'email' ? (
                                    <Grid item xs={12}>
                                        <Typography sx={{mb: 1}}><MailOutlineIcon /> { t('enter_your_email_address') }</Typography>
                                        <TextField
                                            id='username'
                                            name={'username'}
                                            sx={{direction: 'rtl', borderRadius: '10px'}}
                                            placeholder={'youremail@site.com'}
                                            fullWidth
                                            variant="outlined"
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                            error={formik.touched.username && Boolean(formik.errors.username)}
                                            helperText={formik.touched.username && formik.errors.username}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography sx={{mb: 1}}><PhoneIphoneIcon /> { t('enter_your_phone_number') }</Typography>
                                        <Stack direction={'row'} spacing={1}>
                                            <TextField
                                                sx={{direction: 'rtl', borderRadius: '10px'}}
                                                placeholder={'912 345 6789'}
                                                fullWidth
                                                id='username'
                                                name={'username'}
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                                error={formik.touched.username && Boolean(formik.errors.username)}

                                            />
                                            <Select value={1} sx={{direction: 'rtl', maxWidth: '70px'}} variant="outlined">
                                                <MenuItem value={1} sx={{direction: 'rtl'}}>+98</MenuItem>
                                            </Select>
                                        </Stack>
                                        {
                                            formik.touched.username && Boolean(formik.errors.username) && <Box  fontSize={'x-small'} color={'error.main'} >{formik.touched.username && formik.errors.username}</Box>
                                        }
                                    </Grid>
                                )
                            }
                            <Grid item xs={12}>
                                <Typography sx={{mb: 1}}><KeyIcon /> { t('password') }</Typography>
                                <PasswordField
                                    id='password'
                                    name={'password'}
                                    size={'medium'}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                                <Typography sx={{mt: 1.5, pl: 1}}>
                                    <Link  component={RouterLink} to='/forget' variant='body2' underline='none' color='text.secondary'>{ t('forget_my_password') }</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{mt: 2}}>
                                {!formik.isSubmitting && <Button type="submit" variant="contained" fullWidth size='large'>{ t('confirm') }</Button>}
                                {formik.isSubmitting && (
                                    <LoadingButton loading variant="outlined" fullWidth size='large' loadingIndicator={t('validating')}>
                                        { t('loading') }
                                    </LoadingButton>
                                )}
                                <Typography textAlign='center' sx={{mt: 2}}>
                                    <Box component='span' sx={{px: 1}}>{ t('dont_have_account') }</Box>
                                    <Link component={RouterLink} to='/signup' underline='none'>{ t('signup_please') }</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={successSnackbarIsOpen} autoHideDuration={6000} >
                <Alert  severity="success" sx={{ width: '100%', bgcolor: 'success.dark' }}>
                    {t('login_done_redirecting')}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}