import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { amber, grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';

const getDesignTokens = (mode: PaletteMode) => ({
    typography: {
        fontSize: 11,
        fontFamily: [
            'IRANSansX',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode,
        primary: {
            ...amber,
            ...(mode === 'dark' && {
                main: '#FCD535',
            }),
        },
        ...(mode === 'dark' && {
            background: {
                default: '#212226',
                paper: '#222631',
                darkBlack: '#1B1B1D',
                light: '#383839'

            },
            warning: {
                main: '#FEB019'
            }
        }),
        text: {
            ...(mode === 'light'
                ? {
                    primary: grey[900],
                    secondary: grey[800],
                }
                : {
                    primary: '#fff',
                    secondary: '#B7BDC6',
                    title: '#FAFAFA',
                }),
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '10px!important',
                    paddingLeft: '0!important',
                    paddingRight: '0!important'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderBottomWidth: '2px'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                    borderRadius: '10px'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: '0px'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Iransans';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('Iransans'), local('Iransans-Regular'), url(/fonts/IRANSansWeb.woff2) format('woff2');
                  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
              `,
        },
    }
});



const darkModeTheme = createTheme(getDesignTokens('dark'));

const directedTheme = createTheme(darkModeTheme, {
    direction: 'rtl',
});

// Create rtl cache
export const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const Theme = createTheme(directedTheme, cacheRtl);


export default Theme;
