import {Box, Stack} from "@mui/system"
import {Button, Grid, Typography} from "@mui/material"
import {t} from "i18next";
import NoAuthImage from '../../assets/no-auth-pic.png'
import {FileUploader} from "react-drag-drop-files";
import React, {useEffect, useState} from "react";
import './AuthPage3.css'
import client from "../../config/client";
import {useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function Step0(){
    const [t] = useTranslation();
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>{t('loading_please_wait')}</Typography>
        </Stack>
    );
}

function Step1(props: {onSuccess: ()=>any}){
    const fileTypes = ["JPEG", "PNG", "GIF"];
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleChange = (file: any) => {
        setFile(file);
    };

    const handleSubmit = () => {
        if(!file) return;
        setLoading(true);

        const data = new FormData();
        data.append('file', file);
        client.postForm('/v4/verifications/file1', data).then((resp)=>{
            props.onSuccess();
        }).finally(() => setLoading(false))
    }
    return (
        <>
            <Typography variant="subtitle1" component="h4" sx={{ color: "text.title", textAlign: 'center', mb: 2 }}>
                {t('accept_id_card')}
            </Typography>
            <Stack direction={{xs:'column' , sm:'row'}} spacing={4} mt={3}>
                <Stack direction={'column'} spacing={2} mt={-4.25}>
                    <Typography variant="subtitle2" component="p" sx={{ color: "text.title" }}>
                        {t('upload_pic_paragraph')}
                    </Typography>
                    <FileUploader
                        classes="file_uploader"
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        label={'عکس خود را اینجا آپلود کنید'}
                    />
                    <Typography variant="caption" component="p" sx={{ color: "text.secondary", mt: 2 }}>
                        {t('volume_of_pic_paragraph')}
                    </Typography>
                </Stack>
                <Box>
                    <img src={NoAuthImage} alt="id card picture" width="100%" />
                </Box>
            </Stack>


            <Box sx={{ display: 'flex', justifyContent: 'center', width: '93%', mt: 3 }}>
                <Button disabled={loading} onClick={handleSubmit} variant="contained" sx={{px: 2, }}>{t('save')}</Button>
            </Box>
        </>
    )
}

function Step2(){
    const [t] = useTranslation();
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography>
                <AccessTimeIcon color={'warning'} /> {t('your_identity_is_waiting_for_confirmation')}
            </Typography>
        </Stack>
    );
}

function Step3(){
    const [t] = useTranslation();
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography>
                <CheckCircleOutlineIcon color={'success'} /> {t('your_identity_is_confirmed')}
            </Typography>
        </Stack>
    );
}
const IdentityVerification = () => {
    const [step, setStep] = useState(0);

    useEffect(() => {
        client.get('/v4/verifications/file1-status').then((resp)=>{
            if(resp?.data?.data?.status == 'verified'){
                setStep(3);
            }else if(resp?.data?.data?.status == 'sent'){
                setStep(2);
            }else {
                setStep(1)
            }
        })
    }, [])

    function setStep2(){
        setStep(2);
    }

    if(step === 0){
        return <Step0 />
    }else if(step === 1){
        return <Step1 onSuccess={setStep2} />
    }else if(step === 2){
        return <Step2 />
    }else if(step === 3){
        return <Step3 />
    }else{
        return <Step1 onSuccess={setStep2} />
    }
}

export default IdentityVerification