import {Grid, Paper, Typography} from "@mui/material";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import {useTranslation} from "react-i18next";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";
import CurrenciesTable from "../../components/compound/CurrenciesTable";
import TrendCoin from "../../components/TrendCoin";

export default function Markets(){
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('market')}>
            <BackgroundPaper sx={{pb: 10}}>
                <Paper elevation={0} sx={{pt: 8, pb: 15, bgcolor: '#212226', borderRadius: 0, mx: 0}}>
                    <Typography variant={'h4'} sx={{textAlign: 'center'}}>{t('crypto_live_prices')}</Typography>
                </Paper>
                <Paper sx={{p: 3,mt: -12, mx: {xs: 1, sm: 2, md: 10}}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TrendCoin slug={'bitcoin'} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TrendCoin slug={'ethereum'}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TrendCoin slug={'tether'}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TrendCoin slug={'tron'} />
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{mx: {xs: 1, sm: 2, md: 10}, mt: 5, p: 2}}>
                    <CurrenciesTable dense={false}  />
                </Paper>
            </BackgroundPaper>
        </Page>
    );
}