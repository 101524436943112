import React from "react";
import { Box, Container, Typography, Stack, Grid, Divider, Button } from "@mui/material";
import { t } from "i18next";
import { Link } from 'react-router-dom';
import CoolWalletPro from '../../assets/product_pic.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import './product.css'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Product = () => {
    return (
        <>
            <Box sx={{ bgcolor: 'background.default', py: 4, textAlign: "center" }}>
                <Typography variant="h4" component="h1" sx={{ color: "text.title" }}>
                    {t('product_name')}
                </Typography>
            </Box>
            <Container sx={{py:3}}>
                <Grid container spacing={{ xs: 5, sm: 2.5 }} direction={{ xs: 'column-reverse', md: 'row' }}>
                    <Grid item xs={12} sm={6} display={'flex'} justifyContent={'center'}>
                        <Stack justifyContent={'center'}>
                            <Typography variant="h4" component="h4" sx={{ color: "text.primary" }}>
                                {t('product_name2')}
                            </Typography>
                            <Typography variant="h6" component="p" sx={{ color: "text.title", mt: 2, textAlign: 'justify' }}>
                                {t('lorem')}
                            </Typography>
                            <Stack direction={'row'} spacing={1.5} alignItems={'center'} mt={2}>
                                <Typography variant="h4" component="span" sx={{ color: "primary.main" }}>
                                    {t('rial')}
                                </Typography>
                                <Typography variant="h4" component="span" sx={{ color: "primary.main" }}>
                                    {t('price_wallet2')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: { xs: '100%', sm: '25rem' } }}>
                            <img src={CoolWalletPro} alt="wallet picture" width='100%' />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{ bgcolor: 'background.paper', py: 5 }}>
                <Container>
                    <Stack>
                        <Typography variant="h4" component="h5" sx={{ color: "text.primary" }}>
                            {t('introduce_cool_wallet')}
                        </Typography>
                        <Typography variant="subtitle1" component="p" sx={{ color: "text.title", mt: 3, textAlign: 'justify' }}>
                            {t('introduce_cool_wallet_paragraph')}
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            <Container sx={{ py: 4 }}>
                <Typography variant="h4" component="h5" sx={{ color: "text.primary" }}>
                    {t('buy_hardware_wallet')}
                </Typography>
                <Grid container spacing={1.5} mt={3}>
                    <Grid item xs={12} sm={6} md={3} >
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('product_name')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet2')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('product_name')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet2')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('product_name')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet2')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <Stack direction={'column'} spacing={2} sx={{ py: 2, px: 1.5, bgcolor: 'background.paper', borderRadius: '15px' }}>
                                <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={CoolWalletPro} alt="product picture" className='product_image_no_detail' />
                                </Box>
                                <Typography variant="h5" component="p" sx={{ color: "text.primary", "&:hover": { color: "primary.main" } }}>
                                    {t('product_name')}
                                </Typography>
                                <Stack direction={'row'} spacing={1.5} alignItems={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('rial')}
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "primary.main" }}>
                                        {t('price_wallet2')}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack direction={'row'} spacing={1.25} alignItems={'center'} justifyContent={'center'}>
                                    <Typography variant="subtitle1" component="span" sx={{ color: "text.title", "&:hover": { color: 'text.secondary' } }}>
                                        {t('see_product_info')}
                                    </Typography>
                                    <ArrowRightAltIcon sx={{ transform: 'rotate(180deg)', color: 'text.title' }} />
                                </Stack>
                            </Stack>
                        </Link>
                    </Grid>
                </Grid>
                <Link to="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px' , textDecoration:"none" }}>
                    <Button variant="outlined" endIcon={<KeyboardArrowLeftIcon sx={{ color: 'primary.main' }} />}>
                        {t('see_all_products')}
                    </Button>
                </Link>
            </Container>
        </>
    )
}

export default Product