import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Box, Chip, CircularProgress, IconButton, Paper, Stack, Typography} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TrxImage from "../assets/trx.png";
import axios from "axios";
import Toman from "./compound/Toman";
import Percentage from "./compound/Percentage";
import {Link as RouterLink} from 'react-router-dom';

interface TrendCoinInterface{
    slug: string
}

interface CurrencyInterface{
    cmcId: string;
    cmcRank: string;
    slug: string;
    name: string;
    nameFa: string;
    symbol: string;
    price: string;
    price_toman: string;
    percentChange1h: string;
    percentChange24h: string;
    percentChange7d: string;
    percentChange30d: string;
    percentChange60d: string;
    percentChange90d: string;
    percentChange180d: string;
    maxSupply: string;
    circulatingSupply: string;
    ath: string;
    atl: string;
    high24h: string;
    low24h: string;
    volume24h: string;
    marketCap: string;
    dominance: string;
    raise?: -1 | 0 | 1;
}
export default function TrendCoin(props: TrendCoinInterface){
    const [t] = useTranslation();
    const [currency, setCurrency] = useState<CurrencyInterface>({
        cmcId: '',
        cmcRank: '',
        slug: '',
        name: '',
        nameFa: '',
        symbol: '',
        price: '',
        price_toman: '',
        percentChange1h: '',
        percentChange24h: '',
        percentChange7d: '',
        percentChange30d: '',
        percentChange60d: '',
        percentChange90d: '',
        percentChange180d: '',
        maxSupply: '',
        circulatingSupply: '',
        ath: '',
        atl: '',
        high24h: '',
        low24h: '',
        volume24h: '',
        marketCap: '',
        dominance: '',
        raise: 0
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [wsUrl, setWsUrl] = useState<string>("");
    const slug = props.slug;

    useEffect(() => {
        setWsUrl(`wss://ws.ramzarz.news/prices?assets=${slug}`);
    }, [slug])


    useEffect(() => {
        if(!!slug){
            loadCurrency(slug)
        }
    }, [slug]);
    useEffect(() => {

        if(!!wsUrl){
            const ws = new WebSocket(wsUrl);
            ws.onmessage = (event) => {
                try{
                    const updates = JSON.parse(event.data);

                    let currentCurrency = Object.assign({}, currency);
                    Object.keys(updates).forEach((currencyName) => {
                        if(currentCurrency.name.toLowerCase() === currencyName.toLowerCase()){
                            currentCurrency.price_toman = (parseFloat(updates[currencyName]) * (parseFloat(currentCurrency.price_toman) / parseFloat(currentCurrency.price))).toString()

                            currentCurrency.price = updates[currencyName];
                        }
                    })

                    setCurrency(currentCurrency);

                }catch (err){
                    //console.log(err);
                }


            }
            return () => {
                ws.close();
            }
        }
    }, [wsUrl, currency]);


    function loadCurrency(slug: string){
        setLoading(true);
        axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/single/${slug.toLowerCase()}`).then(response => response.data)
            .then((data) => {
                setCurrency(data)
            }).catch((err) => {

        }).finally(() => setLoading(false))
    }

    return (
        loading ? (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            ) : (
            <Paper sx={{bgcolor: '#212226', p: 1}}>
                <Stack spacing={1}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography fontWeight={'bold'} variant={'h6'} alignSelf={'center'}>{currency.nameFa}</Typography>
                        <IconButton component={RouterLink} to={`/trade/${currency.symbol.toLowerCase()}`} size={'small'}><KeyboardBackspaceIcon /></IconButton>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box sx={{display: 'inline-flex'}}>
                            <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${currency.cmcId}.png`} width={21} height={21} /> <Typography component={'span'} sx={{mx: 0.4}}>{currency.symbol}/IRT</Typography>
                        </Box>
                        <Chip size={'small'} label={<Percentage value={currency.percentChange24h} />} variant="outlined" color={parseFloat(currency.percentChange24h) > 0 ? 'success' : 'error'} />
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Toman
                            irt={currency.price_toman}
                            usd={currency.price}
                            symbol='تومان'
                        />
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography color={'text.secondary'} alignSelf={'center'}>{t('trade_volume')}:</Typography>
                        <Typography>{new Intl.NumberFormat().format(parseFloat(currency.volume24h))}</Typography>
                    </Stack>
                </Stack>
            </Paper>
            )
    );
}