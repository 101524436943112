import React from 'react';
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Latest from "./Latest";

export default function TradeLatest(){
    const [t] = useTranslation();
    return (
        <React.Fragment>
            <Box sx={{px: 1, pb: 2}}>
                <Typography>{t('trade_history')}</Typography>
            </Box>
            <Box>
                <Latest />
            </Box>
        </React.Fragment>
    );
}