import {
    Box,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Tab,
    Tabs
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import React from "react";
import {useTranslation} from "react-i18next";
import Pairs from "./Pairs";

export default function TradePairs(){
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [t] = useTranslation();
    return (
        <React.Fragment>
            <Box sx={{px: 1}}>
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        type="text"
                        size={'small'}
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: '3.5px 8px'
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    sx={{p: '2px', mr: '-5px'}}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder={t('search_currency') as string}
                    />
                </FormControl>
            </Box>
            <Box sx={{my: 1, px: 1}}>
                <Tabs
                    sx={{
                        bgcolor: 'background.default',
                        borderRadius: '10px',
                        minHeight: '28px',
                        '& .MuiButtonBase-root.MuiTab-root': {
                            minHeight: '28px',
                            padding: '4px 10px',
                            minWidth: '50px'
                        }
                    }}
                    value={value} onChange={handleChange}>
                    <Tab icon={<StarBorderIcon />} />
                    <Tab label={t('toman')} />
                    <Tab label="USDT" />
                    <Tab label="BUSD" />
                </Tabs>
            </Box>
            <Box sx={{px: 1}}>
                <RadioGroup row>
                    <FormControlLabel  value="female" control={<Radio size={'small'} />} label={t('change') as string} />
                    <FormControlLabel value="male" control={<Radio size={'small'} />} label={t('volume') as string} />
                </RadioGroup>
            </Box>
            <Box>
                <Pairs />
            </Box>
        </React.Fragment>
    );
}