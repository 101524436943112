import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import client from "../../config/client";
import {Alert, Box, Button, Stack, TextField, Typography} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function Step0(){
    const [t] = useTranslation();
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>{t('loading_please_wait')}</Typography>
        </Stack>
    );
}

function Step1(props: {onOtp: () => any, onChangePhone: (p: string) => any}){
    const [t] = useTranslation();
    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    function handleSubmit(){
        setLoading(true);
        let data = {
            type: 'home_telephone_code_request',
            'home-telephone': phone,
        }
        client.post('/v1/verifications', data).then((resp)=>{
            props.onOtp();
            props.onChangePhone(phone)
        }).catch((err) => {
            if(err?.response?.data?.errors){
                setError(err?.response?.data?.errors[0])
            }
        }).finally(()=>setLoading(false))
    }
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>{t('home_phone_verification')}</Typography>
            <Box>
                <Typography color={'text.secondary'}>{t('enter_a_valid_phone_number')}</Typography>
                <TextField
                    value={phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPhone(event.target.value);
                    }}
                    sx={{direction: 'rtl'}}
                    size={'small'}
                    fullWidth />
                <Typography color={'error'}>{error}</Typography>
            </Box>
            <Box>
                <Button disabled={loading} onClick={handleSubmit} sx={{minWidth: 80}}  size={'large'} variant={'contained'}>{t('confirm')}</Button>
            </Box>
        </Stack>
    );
}


function Step2(props: {phone: string, onEdit: () => any, onSuccess: () => any}){
    const [t] = useTranslation();
    const [code, setCode] = useState('');
    const {phone} = props;
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    function handleSubmit(){
        setLoading(true);
        let data = {
            type: 'home_telephone_verification',
            'code': code,
        }
        client.post('/v1/verifications', data).then((resp)=>{
            props.onSuccess();
        }).catch((err) => {
            if(err?.response?.data?.errors){
                setError(err?.response?.data?.errors[0])
            }
        }).finally(()=>setLoading(false))
    }

    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography fontWeight={'bold'} variant={'h6'}>{t('home_phone_verification')}</Typography>
            <Alert
                severity="warning"
                icon={false}
                sx={{bgcolor: 'rgba(252, 213, 53, 0.1)', color: '#FAFAFA', textAlign: 'center'}}
                variant="outlined">
                <Typography>{t('we_will_call_to_your_phone_number', {phone: phone})}</Typography>
                <Box>
                    <Button onClick={() => props.onEdit()} variant={'text'} color={'info'}>
                        <BorderColorIcon /> {t('edit_phone')}
                    </Button>
                </Box>
            </Alert>
            <Box>
                <TextField
                    value={code}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCode(event.target.value);
                    }}
                    sx={{direction: 'rtl'}}
                    size={'small'}
                    fullWidth />
                <Typography color={'error'}>{error}</Typography>
            </Box>
            <Box>
                <Button disabled={loading} onClick={handleSubmit} sx={{minWidth: 80}}  size={'large'} variant={'contained'}>{t('confirm')}</Button>
            </Box>
        </Stack>
    );
}


function Step3(props: {phone: string}){
    const [t] = useTranslation();
    const {phone} = props;
    return (
        <Stack spacing={3} minWidth={250} textAlign={'center'}>
            <Typography>
                <CheckCircleOutlineIcon color={'success'} /> {t('your_phone_is_confirmed')}
            </Typography>
            <Alert
                severity="warning"
                icon={false}
                sx={{bgcolor: 'rgba(252, 213, 53, 0.1)', color: '#FAFAFA', textAlign: 'center', display: 'block'}}
                variant="outlined">
                <Typography sx={{textAlign: 'center', display: 'block'}}>{phone}</Typography>
            </Alert>
        </Stack>
    );
}

export default function PhoneVerification(){
    const [step, setStep] = useState(0);
    const [phone, setPhone] = useState('')

    useEffect(() => {
        client.get('/v4/verifications/home-phone').then((resp)=>{
            if(resp?.data?.data?.status === 'verified'){
                setStep(3);
            }else{
                setStep(1);
            }

            if(resp?.data?.data?.phone){
                setPhone(resp.data.data.phone);
            }
        })
    }, []);

    function setStep1(){
        setStep(1);
    }

    function setStep2(){
        setStep(2);
    }

    function setStep3(){
        setStep(3);
    }

    function changePhone(p: string){
        setPhone(p)
    }
    if(step === 0){
        return <Step0 />
    }else if(step === 1){
        return <Step1 onOtp={setStep2} onChangePhone={changePhone} />
    }else if(step === 2){
        return <Step2 phone={phone} onEdit={setStep1} onSuccess={setStep3} />
    }else{
        return <Step3 phone={phone} />
    }
}