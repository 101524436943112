import {Box, Stack} from "@mui/system"
import {Button, Typography} from "@mui/material"
import {t} from "i18next";
import DottedBorderIcon from '../../assets/dotted-auth-boder.png'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import {useState} from "react";
import EmailVerification from "./EmailVerification";
import PersonalInfoVerification from "./PersonalInfoVerification";
import BankAccountVerification from "./BankAccountVerification";

const Level1 = () => {
    const [item, setItem] = useState(0);

    function handleChangeItem(i: number) {
        setItem(i)
    }

    return (
        <Box sx={{bgcolor: 'background.paper', py: 4, px: 4}}>
            <Stack alignItems={'center'} direction={'row'} justifyContent={'center'} spacing={3}
                   sx={{borderBottom: '1px solid #fff', py: 2}}>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Button className={item === 0 ? 'active' : ''} sx={{
                        display: 'inline-block',
                        color: (theme) => item === 0 ? theme.palette.primary.main : 'inherit'
                    }} onClick={() => handleChangeItem(0)}>
                        <Box>
                            <EmailIcon fontSize={'large'}/>
                        </Box>
                        <Typography variant="subtitle2" component="h4">
                            {t('confirm_email')}
                        </Typography>
                    </Button>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <Box>
                        <img src={DottedBorderIcon} alt='border icon'/>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Button className={item === 1 ? 'active' : ''} sx={{
                        display: 'inline-block',
                        color: (theme) => item === 1 ? theme.palette.primary.main : 'inherit'
                    }} onClick={() => handleChangeItem(1)}>
                        <Box>
                            <ContactEmergencyIcon fontSize={'large'}/>
                        </Box>
                        <Typography variant="subtitle2" component="h4">
                            {t('personal_info')}
                        </Typography>
                    </Button>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <Box>
                        <img src={DottedBorderIcon} alt='border icon'/>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Button className={item === 2 ? 'active' : ''} sx={{
                        display: 'inline-block',
                        color: (theme) => item === 2 ? theme.palette.primary.main : 'inherit'
                    }} onClick={() => handleChangeItem(2)}>
                        <Box>
                            <CreditCardIcon fontSize={'large'}/>
                        </Box>
                        <Typography variant="subtitle2">
                            {t('bank_info')}
                        </Typography>
                    </Button>
                </Box>
            </Stack>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                mt: 3
            }}>
                {item === 0 && (
                    <EmailVerification/>
                )}
                {item === 1 && (
                    <PersonalInfoVerification/>
                )}
                {item === 2 && (
                    <BankAccountVerification/>
                )}
            </Box>
        </Box>
    )
}

export default Level1