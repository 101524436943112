import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {RouterProvider} from "react-router-dom";
import router from './app/router'
import './config/i18n'
import {ThemeProvider} from '@mui/material/styles';
import {CacheProvider} from '@emotion/react';
import _config from './config';
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import {prefixer} from "stylis";

const container = document.getElementById('root')!;
const root = createRoot(container);


// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={_config.theme}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </CacheProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
