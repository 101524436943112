import {useTranslation} from "react-i18next";
import {Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React from "react";

export default function TermsLink(){
    const { t } = useTranslation();
    return (
        <>
            <Link color={'info.main'} underline={'none'} component={RouterLink} to={'/terms'}>{t('terms')}</Link> {t('brand_name')} {t('read_and_accept')}.
        </>
    );
}