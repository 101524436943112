import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import RImage from "../../assets/r.png";
import {Alert, Box, Button, CircularProgress, IconButton, Snackbar, Stack, TextField, Typography} from "@mui/material";
import ChangeIcon from "../../assets/change_sell_buy.png";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {CurrencyInterface, FormInterface, WalletInterface} from "./types";
import client from "../../config/client";
import {number_format} from "../../config/helpers";

export default function BuyForm(props: FormInterface){
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [currency, setCurrency] = useState<CurrencyInterface | null>(null);
    const [wallet, setWallet] = useState<WalletInterface | null>(null)
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState<string>('');
    const [amount, setAmount] = useState<string | number>('');
    const [submitting, setSubmitting] = useState(false);
    const [errorsSnackbarIsOpen, setErrorsSnackbarIsOpen] = useState(false);
    const [errors, setErrors] = useState<Array<any>>([])

    useEffect(() => {
        setLoading(true)
        client.get(`/v4/exchanges/${props.symbol}`).then((resp)=>{
            setWallet(resp.data.data.wallet);
            setCurrency(resp.data.data.currency);
            setValue('1000000')
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if(!currency || currency.buy == '0'){
            setAmount('0');
            return;
        }
        setAmount(parseFloat(value) / parseFloat(currency.buy) )
    }, [value])

    function handleSubmit(){
        if(!currency) return;
        if(amount <= 0) return;

        let data = {
            currency_id: currency.id,
            type: 'buy',
            amount: amount,
            buy_method: 'wallet'
        };

        setSubmitting(true);
        setErrors([])
        setErrorsSnackbarIsOpen(false)
        client.post(`/v1/orders`, data).then((resp) => {
            console.log(resp);
            if(resp?.data?.error){
                let errors = [];
                errors.push(resp.data.message)
                setErrors(errors)
                setErrorsSnackbarIsOpen(true)
                return;
            }

        }).catch((err) => {
            console.log(err.response)
            if(err?.response?.data?.errors){
                setErrors(err.response.data.errors)
            }else if(err?.response?.data?.message){
                setErrors([err.response.data.message])
            }else{
                setErrors([err.toString()])
            }
            setErrorsSnackbarIsOpen(true)
        }).finally(() => setSubmitting(false));
    }

    const handleCloseErrorsSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setErrorsSnackbarIsOpen(false);
    };

    return (
        <>
            <Box hidden={props.value !== props.index} sx={{ mt: 4, position: 'relative' }}>
                <Box visibility={loading ? "hidden" : "visible"}>
                    <Box>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={.5} alignItems={{ xs: 'start', sm: 'center' }} justifyContent={'space-between'} my={1}>
                            <Typography variant="subtitle2" component="p" color="text.title">
                                {t('i_pay')}
                            </Typography>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} alignItems={{ xs: 'start', sm: 'center' }}>
                                <Stack direction={'row'} spacing={.25} alignItems={'center'}>
                                    <Typography variant="subtitle2" component="p" color="text.title">
                                        {t('my_balance')}
                                    </Typography>
                                    <Typography variant="caption" component="p" color="text.title">
                                        {wallet && number_format(wallet.balance)} {t('toman')}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box>
                        <TextField
                            fullWidth
                            placeholder={`${t('enter_price')}`}
                            value={value}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setValue(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Stack direction={'row'} spacing={1} sx={{mx: 1, alignItems: 'center'}}>
                                        <Box component={'img'} width={24} height={24} src={RImage} />
                                        <Typography sx={{display: 'inline'}}>{t('toman')}</Typography>
                                    </Stack>
                                ),

                            }}
                        />
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, pb: 0 }}>
                            <IconButton onClick={() => props.onSwitch(1)} sx={{ "&:hover": { backgroundColor: "#222631" } }}>
                                <img src={ChangeIcon} alt="change icon" width="100%" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" component="p" color="text.title" sx={{ mb: 1.5 }}>
                            {t('i_receive')}
                        </Typography>
                        <TextField
                            fullWidth
                            disabled
                            value={amount}
                            placeholder={`${t('enter_count')}`}
                            InputProps={{
                                endAdornment: (
                                    <Stack direction={'row'} spacing={1} sx={{mx: 1, alignItems: 'center'}}>
                                        {
                                            currency && (
                                                <>
                                                    <Box component={'img'} width={24} height={24} src={currency.icon_link} />
                                                    <Typography sx={{display: 'inline'}}>{currency.name}</Typography>
                                                </>
                                            )
                                        }
                                    </Stack>
                                ),
                            }}
                        />
                    </Box>
                    <Box>
                        <Stack direction={'row'} spacing={.5} alignItems={'center'} justifyContent={'space-between'} my={1}>
                            <Typography variant="subtitle2" component="p" color="text.title">
                                {
                                    currency && (
                                        <span>{'قیمت روز ' + currency.name}</span>
                                    )
                                }
                            </Typography>
                            <Typography variant="subtitle2" component="p" color="text.title">
                                {currency && number_format(currency.buy)}IRT
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction={'column'} spacing={.5} my={1}>
                            <Button sx={{ "&:hover": { backgroundColor: "#222631" }, width: 'fit-content' }} className='btn_hide-show-off-code'>
                                <Stack direction={'row'} onClick={() => setShow(!show)} spacing={.1} alignItems={'center'}>
                                    {
                                        show ? <ExpandMoreOutlinedIcon sx={{ color: '#0082FD' }} /> : <KeyboardArrowLeftOutlinedIcon sx={{ color: '#0082FD' }} />
                                    }
                                    <Typography variant="subtitle2" component="p" color="#0082FD">
                                        {t('have_off_code')}
                                    </Typography>
                                </Stack>
                            </Button>
                            {
                                show ? <TextField fullWidth placeholder={`${t('enter_off_code')}`} /> : null
                            }

                        </Stack>
                    </Box>
                    <Box>
                        <Button disabled={submitting} onClick={handleSubmit} size={'large'} fullWidth variant={'contained'} sx={{ bgcolor: '#58BC7C', color: '#202325', "&:hover": { bgcolor: '#58BC7C' } }}>{currency && t('buy') + " " + currency.name}</Button>
                    </Box>
                </Box>
                <Box visibility={!loading ? "hidden" : "visible"} sx={{position: 'absolute', top: '45%', right: '42%'}}>
                    <CircularProgress />
                </Box>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={errorsSnackbarIsOpen} autoHideDuration={6000} onClose={handleCloseErrorsSnackbar}>
                <Alert onClose={handleCloseErrorsSnackbar} severity="error" sx={{ width: '100%', bgcolor: 'error.dark' }}>
                    {errors.length > 0 && errors[0]}
                </Alert>
            </Snackbar>
        </>
    );
}