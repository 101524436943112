import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CoinImage from "../../assets/coin.png";
import {useAppDispatch} from "../../app/hooks";
import {setSymbol} from "./withdrawFormSlice";

interface CryptoInterface{
    abbreviation_up: string,
    abbreviation: string,
    id: string,
    name: string,
    icon_link: string
}

export default function CryptoSelector(props: {items: Array<CryptoInterface>}){
    const { t } = useTranslation();
    const {items} = props;
    const [value, setValue] = useState<CryptoInterface>(() => {
        return {
            id: '',
            abbreviation_up: '',
            abbreviation: '',
            name: '',
            icon_link: ''
        };
    });
    const dispatch = useAppDispatch();


    function SelectedItem(){
        return (
            <Grid container spacing={1}>
                <Grid item>
                    <img src={value?.icon_link} width="16" />
                </Grid>
                <Grid item flexGrow={1}>
                    <Typography>{ value?.name } {value?.abbreviation_up}</Typography>
                </Grid>
            </Grid>
        );
    }
    return (
        <Box sx={{mb: 2}}>
            <Typography color={'text.secondary'} sx={{mb: 1}}><img src={CoinImage} width={16} /> {t('select_coin')}</Typography>
            <Select
                fullWidth
                value={value}
                size="small"
                renderValue={SelectedItem}
                IconComponent={KeyboardArrowDownIcon}
            >

                {props.items.map((option) => (
                    <MenuItem
                        onClick={(event: any) => {
                            setValue(option)
                            dispatch(setSymbol(option.abbreviation))
                        }}
                        key={option.id} value={option as any}>
                        <Grid container spacing={1}>
                            <Grid item sx={{alignSelf: 'center'}}>
                                <img src={option.icon_link} width={32} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <Typography>{option.name}</Typography>
                                <Typography color={'text.secondary'}>{option.abbreviation_up}</Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}