import * as React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const MetallicAlert = styled(Alert)<AlertProps>(({ theme }) => ({
    '&.MuiAlert-standardWarning': {
        backgroundColor: 'rgba(254, 176, 25, 0.1)!important',
        color: '#FEB019'
    }
}));

export default MetallicAlert;