import {Box, Button, TextField} from "@mui/material";
import DepositAmount from "../../components/atomic/DepositAmount";
import {FormInterface} from "./types";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import client from "../../config/client";
import CryptoSelector from "./CryptoSelector";
import NetworkSelector from "./NetworkSelector";
import {useAppSelector} from "../../app/hooks";
import {selectNetwork} from "./depositFormSlice";

export default function CrypoForm(props: FormInterface){
    const {t} = useTranslation();
    const [items, setItems] = useState([])
    const network = useAppSelector(selectNetwork);

    useEffect(() => {
        client.get('/v1/get/currencies').then((resp)=>{
            if(resp?.data?.data?.currencies){
                setItems(resp?.data?.data?.currencies);
            }
        })
    }, [])

    return (
        <Box hidden={props.value !== props.index}>
            <Box>
                <CryptoSelector items={items} />
            </Box>
            <Box>
                <NetworkSelector />
            </Box>
            <Box>
                {network && (
                    <TextField id="outlined-basic" size={'small'} fullWidth label={t('your_wallet_address')} disabled value={network.address} variant="outlined"/>
                )}
            </Box>
        </Box>
    );
}