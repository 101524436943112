import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Container} from "@mui/system";
import LevelsContainer from "./LevelsContainer";
import Level0 from "./Level0";
import client from "../../config/client";


export default function KycWizard() {
    const [currentLevel, setCurrentLevel] = useState(0);

    useEffect(() => {
        client.get('/v4/verifications/level').then((resp)=>{
            if(resp?.data?.data){
                setCurrentLevel(resp?.data?.data.current_level)
            }
        })
    }, [])
    function setLevel1(){
        setCurrentLevel(1);
    }
    return (
        <Box sx={{ bgcolor: 'background.darkBlack', py: { xs: 1, sm: 4 }, px: { xs: 0, sm: 6 } }}>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: 2, sm: 0 } }}>
                    {
                        currentLevel === 0 ? <Level0 gotoLevel1Form={setLevel1} /> : <LevelsContainer />
                    }
                </Box>
            </Container>
        </Box>
    );
}
