import {Intro} from "../../components/compound/Intro/Intro";
import {LivePricesTable} from "../../components/compound/LivePricesTable/LivePricesTable";
import {MakeOrderSteps} from "../../components/compound/MakeOrderSteps/MakeOrderSteps";
import {GridLatestPosts} from "../../components/compound/GridLatestPosts/GridLatestPosts";
import {CategorizedFaqs} from "../../components/compound/CategorizedFaqs/CategorizedFaqs";
import {DownloadApplication} from "../../components/compound/DownloadApplication/DownloadApplication";
import {ShopCarousel} from "../../components/compound/ShopCarousel/ShopCarousel";
import {ContactChannels} from "../../components/compound/ContactChannels/ContactChannels";
import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import {Grid} from "@mui/material";
import Page from "../Page";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";
import {useAppDispatch} from "../../app/hooks";
import CurrenciesTable from "../../components/compound/CurrenciesTable";

export default function Home(){
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <Page title={t('brand_description')}>
            <Intro />
            <BackgroundPaper>
                <Grid container>
                    <Grid item xs={12} sx={{mx: 2, py: 5}}>
                        <CurrenciesTable dense={true} more='/markets' label={ t('currencies_live_price') as string }  />
                    </Grid>
                </Grid>
            </BackgroundPaper>
            <MakeOrderSteps />
            <GridLatestPosts />
            <CategorizedFaqs />
            <DownloadApplication />
            <ShopCarousel />
            <ContactChannels />
        </Page>
    );
}
