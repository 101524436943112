import {Box, IconButton, Stack, Typography} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlainPaper from "../../atomic/PlainPaper";
import MenuIcon from '@mui/icons-material/Menu';
import {useAppDispatch} from "../../../app/hooks";
import {toggle as toggleDrawer} from '../../../features/drawer/DrawerSlice'
import {Link as RouterLink} from 'react-router-dom';
interface PageTitlePropsInterface{
    title: string,
    link?: string,
    header?: string
}
export default function PageTitle(props: PageTitlePropsInterface){
    const dispatch = useAppDispatch();

    return (
        <PlainPaper>
            <Stack direction={'row'} sx={{py: 1}}>
                <Box sx={{display: 'flex'}}>
                    {
                        props.link && (
                            <IconButton component={RouterLink} to={props.link}><ArrowForwardIcon /></IconButton>
                        )
                    }
                    <Typography sx={{alignSelf: 'center', px: 2}}>{ props.title }</Typography>
                </Box>
                {
                    !!props.header && <Box sx={{mx: 'auto', alignSelf: 'center'}}>
                        <Typography variant={'h5'}>{props.header}</Typography>
                    </Box>
                }
                <Box sx={{alignSelf: 'center', px: 2, ml: !!props.header ? 0 : 'auto'}}>
                    <IconButton sx={{display: {xs: 'inline', md: 'none'}}} onClick={() => dispatch(toggleDrawer())}>
                        <MenuIcon />
                    </IconButton>
                </Box>
            </Stack>
        </PlainPaper>
    );
}