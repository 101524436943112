import {Alert, Box, Button, Card, CardContent, Grid, Link, Snackbar, TextField, Typography} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {Link as RouterLink} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadingButton from '@mui/lab/LoadingButton';
import {useFormik} from "formik";
import * as yup from 'yup';
import MetallicAlert from "../../components/atomic/metallic-alert";
import EditIcon from '@mui/icons-material/Edit';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectToken, selectType, selectUsername, setStep, setToken} from "./LoginSlice";
import {IMaskInput} from 'react-imask';
import client from "../../config/client";
import {login} from "../auth/authSlice";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const OtpCodeMask = React.forwardRef<HTMLElement, CustomProps>(
    function OtpCodeMask(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="0-0-0-0-0"
                inputRef={ref as any}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export default function OtpCard(){
    const { t } = useTranslation();
    const type = useAppSelector(selectType);
    const username = useAppSelector(selectUsername);
    const token = useAppSelector(selectToken);
    const [errorsSnackbarIsOpen, setErrorsSnackbarIsOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const dispatch = useAppDispatch();
    const validationSchema = yup.object({
        code: yup
            .string()
            .required(t('enter_otp_code') as string),
    });

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            client.post('/v4/auth/verify-token', {code: values.code.replaceAll('-', ''), token: token}).then(function(resp){
                dispatch(login(resp.data.data.token))
            }).catch(function(err){
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors)
                }
                setErrorsSnackbarIsOpen(true)
            }).finally(function(){
                setSubmitting(false);
            })

        },
    });

    const handleCloseErrorsSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setErrorsSnackbarIsOpen(false);
    };



    return (
        <React.Fragment>
            <Card sx={{borderRadius: '10px'}}>
                <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography fontWeight='bold' variant='h6' component='h4' textAlign='center'>{ t('enter_otp_code') }</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{textAlign: 'center'}}>
                                <MetallicAlert icon={false} sx={{border: '1px solid #FEB019', justifyContent: 'center'}} severity="warning">
                                    { type === 'email' && (
                                        <Typography fontSize={'larger'} color={'text.primary'}>{t('confirmation_code_sent_to_email', {email: username})}</Typography>
                                    )}
                                    { type === 'mobile' && (
                                        <Typography fontSize={'larger'} color={'text.primary'}>{t('confirmation_code_sent_to_mobile', {mobile: username})}</Typography>
                                    )}

                                    <Box>
                                        <Button onClick={() => dispatch(setStep('username'))} startIcon={<EditIcon />} variant={'text'} color={'info'}>
                                            {
                                                type === 'email' ? t('edit_email') : t('edit_phone')
                                            }
                                        </Button>
                                    </Box>
                                </MetallicAlert>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{mb: 1}}>{ type === 'email' ? <MailOutlineIcon /> : <PhoneIphoneIcon />} { t('confirmation_code') }</Typography>
                                <TextField
                                    id='code'
                                    name={'code'}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontSize: 'larger',
                                            textAlign: 'center'
                                        },
                                        direction: 'rtl', borderRadius: '10px'}}
                                    fullWidth
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.code}
                                    error={formik.touched.code && Boolean(formik.errors.code)}
                                    helperText={formik.touched.code && formik.errors.code}
                                    InputProps={{
                                        inputComponent: OtpCodeMask as any,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{mt: 2}}>
                                {!formik.isSubmitting && <Button type="submit" variant="contained" fullWidth size='large'>{ t('confirm') }</Button>}
                                {formik.isSubmitting && (
                                    <LoadingButton loading variant="outlined" fullWidth size='large' loadingIndicator={t('validating')}>
                                        { t('loading') }
                                    </LoadingButton>
                                )}
                                <Typography textAlign='center' sx={{mt: 2}}>
                                    <Box component='span' sx={{px: 1}}>{ t('dont_have_account') }</Box>
                                    <Link component={RouterLink} to='/signup' underline='none'>{ t('signup_please') }</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={errorsSnackbarIsOpen} autoHideDuration={6000} onClose={handleCloseErrorsSnackbar}>
                <Alert onClose={handleCloseErrorsSnackbar} severity="error" sx={{ width: '100%', bgcolor: 'error.dark' }}>
                    {errors.length > 0 && errors[0]}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}