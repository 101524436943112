import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';

export interface WithdrawFormState {
  symbol: string;
  type: 'fiat' | 'crypto';
  asset?: object;
  network?: any;
  amount: string;
  account: string;
}

const initialState: WithdrawFormState = {
  symbol: 'irt',
  type: 'fiat',
  amount: '',
  account: ''
};

export const withdrawFormSlice = createSlice({
  name: 'withdraw_form',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTypeToFiat: (state) => {
      state.type = 'fiat'
    },
    setTypeToCrypto: (state) => {
      state.type = 'crypto';
    },
    setSymbol: (state, action: PayloadAction<string>) => {
      state.symbol = action.payload;
    },
    setNetwork: (state, action: PayloadAction<object>) => {
      state.network = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.account = action.payload;
    },
  },
});

export const { setTypeToFiat, setTypeToCrypto, setSymbol, setNetwork, setAmount, setAccount } = withdrawFormSlice.actions;

export const selectType = (state: RootState) => state.withdraw_form.type;
export const selectSymbol = (state: RootState) => state.withdraw_form.symbol;
export const selectNetwork = (state: RootState) => state.withdraw_form.network;
export const selectAmount = (state: RootState) => state.withdraw_form.amount;
export const selectAccount = (state: RootState) => state.withdraw_form.account;


export default withdrawFormSlice.reducer;
