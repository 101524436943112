import React, {useEffect} from 'react';
import {Box, Typography} from "@mui/material";
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {makeDense} from "../../features/footer/FooterSlice";
import KycWizard from "../../features/kyc-wizard";


const Kyc = () => {
    const [t] = useTranslation()
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('kyc')}>
            <Box sx={{ bgcolor: 'background.default', py: 4, textAlign: "center" }}>
                <Typography variant="h5" component="h1" sx={{ color: "text.title" }}>
                    {t('authentication')}
                </Typography>
            </Box>
            <KycWizard />
        </Page>
    )
}

export default Kyc;