import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

interface PageProps{
    title: 'string',
    children: React.ReactElement | React.ReactElement[]
}
export default function Page(props: PageProps){
    const [t] = useTranslation();

    const titlePrefix = t('brand_name');

    const separator = '|';

    useEffect(function(){
        document.title = titlePrefix + ' ' + separator + ' ' + props.title;
    }, []);

    return <Box>{props.children}</Box>
}