import {
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import TextSwitch from "../../components/TextSwitch";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {Link as RouterLink} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadingButton from '@mui/lab/LoadingButton';
import {useFormik} from "formik";
import * as yup from 'yup';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectType,
    selectUsername,
    setStep,
    setToken,
    setType,
    setUsername
} from "./ForgetSlice";
import client from "../../config/client";
import CloseIcon from '@mui/icons-material/Close';
import MetallicAlert from "../../components/atomic/metallic-alert";
import CircleIcon from '@mui/icons-material/Circle';

export default function UserNameCard(){
    const dispatch = useAppDispatch();
    const defaultUsername = useAppSelector(selectUsername);
    const { t } = useTranslation();
    const type = useAppSelector(selectType);
    const [errors, setErrors] = useState([]);
    const [openErrorsAlert, setOpenErrorsAlert] = useState(false)
    const emailValidationSchema = yup.object({
        username: yup
            .string()
            .email(t('enter_a_valid_email_address') as string)
            .required(t('email_is_required') as string),
    });
    const mobileValidationSchema = yup.object({
        username: yup
            .string()
            .matches(/^9[0-9]{9,}$/, t('phone_format_is_invalid') as string)
            .required(t('phone_is_required') as string),
    });
    const validationSchema = type === 'email' ? emailValidationSchema : mobileValidationSchema;

    function handleTypeChange(newVal: 0 | 1){
        formik.setFieldValue('username', '')
        formik.setTouched({}, false)
        dispatch(setUsername(''))
        if(newVal === 0) dispatch(setType('email'))
        else dispatch(setType('mobile'))
    }
    const formik = useFormik({
        initialValues: {
            username: defaultUsername,
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            setErrors([]);
            let data = {type: type, username: values.username};

            client.post('/v4/auth/forget-check-username', data).then(function(resp){
                dispatch(setToken(resp.data.data.token))
                dispatch(setUsername(values.username))
                dispatch(setStep('otp'))
            }).catch(function(err){
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors);
                    setOpenErrorsAlert(true);
                }
            }).finally(function(){
                setSubmitting(false);
            })
        },
    });

    return (
        <Card sx={{borderRadius: '10px'}}>
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontWeight='bold' variant='h6' component='h4' textAlign='center'>{ t('enter_email_or_mobile') }</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{textAlign: 'center'}}>
                            <TextSwitch defaultValue={type === 'email' ? 0 : 1} onLabel={t('mobile') as string} offLabel={t('email') as string} handleChange={handleTypeChange} />
                        </Grid>
                        {
                            errors.length > 0 && (
                                <Grid item xs={12}>
                                    <Collapse in={openErrorsAlert}>
                                        <MetallicAlert
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenErrorsAlert(false);
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                            severity={'error'}>
                                            <AlertTitle>{t('server_error')}!</AlertTitle>
                                            <List dense>
                                                {errors.map(function(err, indx){
                                                    return (
                                                        <ListItem sx={{px: 0}} key={indx}>
                                                            <ListItemIcon sx={{minWidth: '20px'}}><CircleIcon color={'error'} fontSize={'small'} /></ListItemIcon>
                                                            <ListItemText primary={err} />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </MetallicAlert>
                                    </Collapse>
                                </Grid>
                            )
                        }
                        {
                            type === 'email' ? (
                                <Grid item xs={12}>
                                    <Typography sx={{mb: 1}}><MailOutlineIcon /> { t('enter_your_email_address') }</Typography>
                                    <TextField
                                        id='username'
                                        name={'username'}
                                        sx={{direction: 'rtl', borderRadius: '10px'}}
                                        placeholder={'youremail@site.com'}
                                        fullWidth
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                        error={formik.touched.username && Boolean(formik.errors.username)}
                                        helperText={formik.touched.username && formik.errors.username}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography sx={{mb: 1}}><PhoneIphoneIcon /> { t('enter_your_phone_number') }</Typography>
                                    <Stack direction={'row'} spacing={1}>
                                        <TextField
                                            sx={{direction: 'rtl', borderRadius: '10px'}}
                                            placeholder={'912 345 6789'}
                                            fullWidth
                                            id='username'
                                            name={'username'}
                                            variant="outlined"
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                            error={formik.touched.username && Boolean(formik.errors.username)}

                                        />
                                        <Select value={1} sx={{direction: 'rtl', maxWidth: '70px'}} variant="outlined">
                                            <MenuItem value={1} sx={{direction: 'rtl'}}>+98</MenuItem>
                                        </Select>
                                    </Stack>
                                    {
                                        formik.touched.username && Boolean(formik.errors.username) && <Box  fontSize={'x-small'} color={'error.main'} >{formik.touched.username && formik.errors.username}</Box>
                                    }
                                </Grid>
                            )
                        }
                        <Grid item xs={12} sx={{mt: 2}}>
                            {!formik.isSubmitting && <Button type="submit" variant="contained" fullWidth size='large'>{ t('confirm') }</Button>}
                            {formik.isSubmitting && (
                                <LoadingButton loading variant="outlined" fullWidth size='large' loadingIndicator={t('validating')}>
                                    { t('loading') }
                                </LoadingButton>
                            )}
                            <Typography textAlign='center' sx={{mt: 2}}>
                                <Box component='span' sx={{px: 1}}>{ t('remember_your_password') }</Box>
                                <Link component={RouterLink} to='/login' underline='none'>{ t('login_please') }</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
}