import * as React from "react";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import {Divider, Typography} from "@mui/material";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useAppSelector} from "../../app/hooks";
import {selectIsLoggedIn} from "../auth/authSlice";

type Anchor = 'top' | 'left' | 'bottom' | 'right';


interface PageInterface{
    text: string,
    link: string
}
const pages:Array<PageInterface> = [
    {
        text: 'market',
        link: '/markets'
    },
    {
        text: 'trade',
        link: '/trade'
    },
    {
        text: 'easy_order',
        link: '/easy-sell-and-buy'
    },
    {
        text: 'shop',
        link: '/shop'
    }
];

export default function MobileDrawer() {
    const [state, setState] = React.useState({
        left: false,
        right: false,
    });
    const { t } = useTranslation();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const navigate = useNavigate();
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Stack direction='column'>
                <Paper elevation={0} sx={{mb: 5, backgroundColor: 'inherit', display: 'flex', flexDirection: 'row-reverse'}}>
                    <IconButton
                        onClick={toggleDrawer(anchor, false)}
                        onKeyDown={toggleDrawer(anchor, false)}>
                        <CloseIcon />
                    </IconButton>
                </Paper>
                {!isLoggedIn && (
                    <Stack spacing={2} sx={{mx: 2, display: {md: 'none'}}}>
                        <Button component={RouterLink} to='/login' variant='outlined' fullWidth={true}>{ t('login') }</Button>
                        <Button component={RouterLink} to='/signup' variant='contained' fullWidth={true}>{ t('sign_up') }</Button>
                    </Stack>
                )}
            </Stack>
            <List sx={{display: {md: 'none'}}}>
                <ListItem
                    key={'dashboard'}
                    disablePadding>
                    <ListItemButton onClick={() => navigate('/dashboard')}>
                        <ListItemText primary={t('dashboard') as string} />
                    </ListItemButton>
                </ListItem>
                <ListItem
                    key={'wallet'}
                    disablePadding>
                    <ListItemButton onClick={() => navigate('/wallet')}>
                        <ListItemText primary={t('wallet') as string} />
                    </ListItemButton>
                </ListItem>
                <ListItem
                    key={'deposit'}
                    disablePadding>
                    <ListItemButton onClick={() => navigate('/deposit')}>
                        <ListItemText primary={t('deposit') as string} />
                    </ListItemButton>
                </ListItem>
                <ListItem
                    key={'withdraw'}
                    disablePadding>
                    <ListItemButton onClick={() => navigate('/withdraw')}>
                        <ListItemText primary={t('withdraw') as string} />
                    </ListItemButton>
                </ListItem>
                <ListItem
                    key={'orders'}
                    disablePadding>
                    <ListItemButton onClick={() => navigate('/orders')}>
                        <ListItemText primary={t('orders') as string} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider sx={{my: 2, mx: 2, display: {sm: 'none'}}} />
            <List sx={{display: {sm: 'none'}}}>
                {pages.map((page, index) => (
                    <ListItem key={page.text} disablePadding>
                        <ListItemButton onClick={() => navigate(page.link)}>
                            <ListItemText primary={t(page.text)} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider sx={{my: 2, mx: 2, display: {md: 'none'}}} />
            <List>
                <ListItem
                    secondaryAction={<Typography>{t('persian')}</Typography>}
                    key='language'
                    disablePadding>
                    <ListItemButton>
                        <ListItemText primary={t('language')} />
                    </ListItemButton>
                </ListItem>
                <ListItem
                    secondaryAction={<Typography>{t('toman')}</Typography>}
                    key='currency'
                    disablePadding>
                    <ListItemButton>
                        <ListItemText primary={t('currency_unit')} />
                    </ListItemButton>
                </ListItem>
                <ListItem
                    secondaryAction={<Typography>{t('dark')}</Typography>}
                    key='theme'
                    disablePadding>
                    <ListItemButton>
                        <ListItemText primary={t('theme')} />
                    </ListItemButton>
                </ListItem>
                <ListItem key='downloads' disablePadding>
                    <ListItemButton>
                        <ListItemText primary={t('download_app')} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            {(['right'] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleDrawer(anchor, true)}
                        color="inherit"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Drawer
                        sx={{
                            '& .MuiDrawer-paper': {
                                borderRadius: '0!important'
                            }
                        }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
