import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import KycAlert from "../../components/compound/KycAlert";
import PageTitle from "../../components/compound/PageTitle";
import {Box, Divider, FormControl, Grid, MenuItem, Paper, Select, Switch, Typography} from "@mui/material";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeDense} from "../../features/footer/FooterSlice";

function TwoFASetting(){
    const { t } = useTranslation();
    let language = 'persian';
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Paper sx={{p: 2}}>
            <Grid container spacing={3}>
                <Grid item>
                    <Box sx={{mb: 1}}>
                        <Typography>{t('play_sound_for_notifications')}</Typography>
                    </Box>
                    <Box>
                        <Typography color={'text.secondary'}>{t('play_sound_for_notifications_description')}</Typography>
                    </Box>
                </Grid>
                <Grid item flexGrow={1} sx={{my: 'auto', textAlign: 'right'}}>
                    <Box sx={{ml: 4}}><Switch /></Box>
                </Grid>
            </Grid>
            <Divider sx={{my: 2}} />
            <Grid container spacing={3}>
                <Grid item>
                    <Box sx={{mb: 1}}>
                        <Typography>{t('choose_notifications_language')}</Typography>
                    </Box>
                </Grid>
                <Grid item flexGrow={1} sx={{textAlign: 'right', my: 'auto'}}>
                    <Box>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select value={language}>
                                <MenuItem value={'persian'}>{t('persian')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

const Notifications = () => {
    const { t } = useTranslation();
    return (
        <Page title={t('notifications')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('notifications')} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <TwoFASetting />
                    </Grid>
                </Grid>
            </BackgroundPaper>
        </Page>
    )
}

export default Notifications;