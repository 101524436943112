import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded';
import Crop54RoundedIcon from '@mui/icons-material/Crop54Rounded';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: 'rgba(252, 213, 53, 0.85)',
    },
    '& .MuiRating-iconHover': {
        color: 'rgba(252, 213, 53, 0.95)',
    },
});

export default function CurrentP2pLevel() {
    return (
        <Box
            sx={{
                pt: '5px',
                px: '2px',
                border: '1px solid rgba(255, 255, 255, 0.3)',
                borderLeft: 'none',
                borderRight: 'none'
            }}
        >
            <StyledRating
                name="customized-10"
                readOnly
                icon={<RectangleRoundedIcon fontSize="inherit" />}
                emptyIcon={<Crop54RoundedIcon fontSize="inherit" />}
                defaultValue={2} max={15}
            />
        </Box>
    );
}