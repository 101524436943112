import {Container, IconButton, TextField} from "@mui/material";
import {t} from "i18next";
import {Box} from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import GuidSectionsAccardion from "../../components/atomic/guid-sections-accardion"
import BreadCrumbGuidSections from "../../components/breadcrumbguidsections";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeFull} from "../../features/footer/FooterSlice";

const GuidSections = () => {
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeFull())
    }, [])
    return (
        <>
            <Box sx={{ bgcolor: 'background.default', textAlign: 'center', py: 2, position: 'relative' }}>
                <Container sx={{ mt: 2, display: 'flex', justifyContent: 'start', alignItems: "center" }}>
                    <TextField
                        className="search-input-faq"
                        sx={{ width: { md: '30%', xs: 'auto' } }}
                        placeholder={`${t('search_in_pouyam_guid')}`}
                        InputProps={{
                            style: {
                                padding: 0
                            },
                            endAdornment: (
                                <IconButton sx={{ transform: 'rotate(85deg)' }}>
                                    <SearchIcon />
                                </IconButton>
                            ),

                        }}
                    />
                </Container>
            </Box>
            <BreadCrumbGuidSections />

            <GuidSectionsAccardion />
        </>
    )
}

export default GuidSections