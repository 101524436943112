import {Grid} from "@mui/material";
import KycAlert from '../../components/compound/KycAlert';
import PageTitle from "../../components/compound/PageTitle";
import {useTranslation} from "react-i18next";
import BackgroundPaper from "../../components/atomic/BackgroundPaper";
import Page from "../Page";
import {useAppDispatch} from "../../app/hooks";
import {useEffect} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import TransactionsTable from "../../features/transactions-table";

export default function Transactions(){
    const { t } = useTranslation();
    const dispath = useAppDispatch();
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('wallet')}>
            <BackgroundPaper>
                <KycAlert />
                <PageTitle title={t('deposit_withdraw_histories')} />
                <Grid container sx={{py: 2, px: 1}} spacing={1}>
                    <Grid item xs={12}>
                        <TransactionsTable />
                    </Grid>

                </Grid>
            </BackgroundPaper>
        </Page>
    );
}