import React from 'react';


import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {useTranslation} from 'react-i18next';
import UserRegisterIcon from '../../../assets/user-register.png';
import UserApproveIcon from '../../../assets/user-approve.png';
import UserDepositIcon from '../../../assets/user-deposit.png';
import UserBuyIcon from '../../../assets/user-buy.png';
import {Container, Typography} from '@mui/material';

interface OrderStepsInterface{
    id: number,
    image: string,
    step: string,
    title: string,
    description: string
}



export function MakeOrderSteps(){
    const { t } = useTranslation();
    const orderSteps:Array<OrderStepsInterface> = [
        {
            id: 1,
            image: UserRegisterIcon,
            step: t('first_step'),
            title: t('sign_up'),
            description: 'در گام اول برای شروع به کار در وب سایت صرافی ارز دیجیتال پویام، باید یک حساب کاربری ایجاد کنید. برای ایجاد حساب کاربری روی دکمه "ثبت نام" در صفحه اصلی کلیک کنید و اطلاعات درخواستی را وارد کنید. توصیه می کنیم رمز عبور منحصر به فردی را انتخاب کنید که حداقل 8 کاراکتر داشته باشد و ترکیبی از حروف بزرگ و کوچک، اعداد و نمادها را شامل شود.'
        },
        {
            id: 2,
            image: UserApproveIcon,
            step: t('second_step'),
            title: t('kyc'),
            description: 'گام دوم پس از ایجاد حساب کاربری، باید هویت خود را تأیید کنید. این یک گام مهم برای تضمین امنیت پلتفرم ما و جلوگیری از تقلب است. شما باید برخی از اطلاعات شخصی مانند نام کامل، آدرس و شماره تماس خود را ارائه دهید. همچنین عکسی از کارت شناسایی صادر شده توسط کشوری که زندگی میکنید ، مانند کارت ملی گذرنامه یا گواهینامه رانندگی، پاسپورت آپلود کنید.'
        },
        {
            id: 3,
            image: UserDepositIcon,
            step: t('third_step'),
            title: t('top_up'),
            description: ' گام سوم پس از تایید حساب شما می توانید مبلغ موردنظر را به حساب خود واریز کنید. برای واریز، کافی است به صفحه «واریز» بروید و روش پرداخت خود را انتخاب کنید. برای تکمیل تراکنش دستورالعمل ها را دنبال کنید و وجوه شما به حساب شما واریز می شود.'
        },
        {
            id: 4,
            image: UserBuyIcon,
            step: t('fourth_step'),
            title: t('choose_currency'),
            description: 'در گام اخر زمانی که حساب شما شارژ شد، می توانید خرید و فروش ارزهای دیجیتال را شروع کنید. پلتفرم معاملاتی کاربر پسند ما انجام سفارشات خرید و فروش را آسان می کند و داده های زمان واقعی ما تضمین می کند که شما همیشه بهترین قیمت ممکن را دریافت کنید. به سادگی ارز دیجیتالی را که می خواهید بخرید یا بفروشید انتخاب کنید، نوع سفارش موردنظر خود را انتخاب کنید، و مبلغی را که می خواهید بخرید یا بفروشید را وارد کنید. سفارش شما فوراً اجرا می شود و ارز دیجیتال خریداری شده شما به حساب شما واریز می شود.'
        }
    ]
    return (
        <Paper elevation={0} sx={{borderRadius: 0, mx: 0, px: 0, bgcolor: '#212226', py: 4}}>
            <Container maxWidth='xl'>
            <Stack direction='column'>
                <Grid>
                    <Grid xs item>
                        <Typography variant='h4' sx={{textAlign: 'center', mb: 0, bgcolor: 'inherit'}}>{t('steps_to_start_making_order')}</Typography>
                    </Grid>
                    <Grid xs item>
                        <Typography variant='h6' sx={{textAlign: 'center', color: 'text.secondary', mt: 0, mb: 4, bgcolor: 'inherit'}}>{t('you_can_start_in_four_simple_steps')}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {orderSteps.map(orderStep => (
                        <Grid key={orderStep.id}  xs={12} sm={6} md={3} item sx={{px: 4}}>
                            <Stack direction='column'>
                                <Paper elevation={0} sx={{textAlign: 'center', bgcolor: 'inherit'}}>
                                    <img src={orderStep.image} />
                                </Paper>
                                <Typography variant='subtitle1' sx={{textAlign: 'center', color: 'text.secondary', mb: 0, fontSize: 'small', bgcolor: 'inherit', py: 2}}>{ orderStep.step }</Typography>
                                <Typography variant='h6' sx={{textAlign: 'center', mt: 0, bgcolor: 'inherit', pb: 2}}>{ orderStep.title }</Typography>
                                <Typography variant='body1' sx={{textAlign: 'center', color: 'text.secondary', bgcolor: 'inherit'}}>{ orderStep.description }</Typography>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
            </Container>
        </Paper>
    );
}
