import axios from 'axios';
import {url} from './helpers';

const token = localStorage.getItem('access_token');

function getHeaders(){
    let headers = {};
    if(token){
        headers['Authorization'] = `Bearer ${token}`
    }

    return headers;
}

const instance = axios.create({
    baseURL: url('/'),
    headers: getHeaders()
});

instance.interceptors.response.use(res => {
    return res;
}, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('access_token');
    }

    throw error;
})

export default instance;
