import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Snackbar} from "@mui/material";
import FiatSelector from "./FiatSelector";
import DepositAmount from "../../components/atomic/DepositAmount";
import {CreditCardInterface, FiatInterface, FormInterface} from "./types";
import {useTranslation} from "react-i18next";
import client from "../../config/client";
import {useAppSelector} from "../../app/hooks";
import {selectAccount, selectAmount} from "./withdrawFormSlice";
import CreditCardSelector from "./CreditCardSelector";
import WithdrawAmount from "./WithdrawAmount";

export default function FiatForm(props: FormInterface){
    const amount = useAppSelector(selectAmount);
    const account = useAppSelector(selectAccount);
    const {t} = useTranslation();
    const [cards, setCards] = useState<CreditCardInterface[]>([])

    const [items, setItems] = useState<FiatInterface[]>([]);

    const [loading, setLoading] = useState(false)

    const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);



    useEffect(() => {
        client.get('/v4/assets/fiats').then((resp) => {
            const data = resp.data.data;

            setItems(data.map((r: any) => {
                return {
                    value: r.symbol,
                    country: 'IRAN',
                    label: r.name,
                    balance: r.total,
                    icon: r.icon
                }
            }))
        })
    }, [])

    useEffect(() => {
        client.get('/v1/credit-cards/approved').then((resp) => {
            setCards(
                resp.data.data.map((r: any) => {
                    return {
                        value: r.id,
                        label: "IR"+r.shaba,
                        icon: r.icon
                    };
                })
            )
        })
    }, [])


    function handlePayBtn(){
        setLoading(true)
        let data = {
            amount: amount,
            account: account,
            type: 'withdraw',
        };
        client.post('/v1/wallet', data).then((resp) => {
            setSuccessSnackbarIsOpen(true)
        }).finally(()=>setLoading(false))
    }

    return (
        <Box hidden={props.value !== props.index}>
            <Box>
                <FiatSelector items={items} />
            </Box>
            <Box>
                <CreditCardSelector items={cards} label={t('sheba_number') as string} />
            </Box>
            <Box>
                <WithdrawAmount />
            </Box>
            <Box>
                <Button disabled={loading} onClick={handlePayBtn} size={'large'} fullWidth variant={'contained'}>{t('withdraw')}</Button>
            </Box>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={successSnackbarIsOpen} autoHideDuration={6000} >
                <Alert  severity="success" sx={{ width: '100%', bgcolor: 'success.dark' }}>
                    {t('withdraw_request_created')}
                </Alert>
            </Snackbar>
        </Box>
    );
}
