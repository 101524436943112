import React from 'react';
import './App.css';
import Footer from "./features/footer/Footer";
import Header from "./features/header";
import {Outlet} from "react-router-dom"
import {useAppSelector} from "./app/hooks";
import {selectDense} from "./features/footer/FooterSlice";
import {Stack} from "@mui/material";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    const footerIsDense = useAppSelector(selectDense)

  return (
      <Stack minHeight={'100vh'} sx={{ flexGrow: 1}} className="App" bgcolor={'background.darkBlack'}>
          <ScrollToTop />
          <Header />
          <Outlet />
          <Footer dense={footerIsDense} />
      </Stack>
  );
}

export default App;
