import React from 'react';


import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from "@mui/material/Button";
import CardMedia from '@mui/material/CardMedia';
import {Box, Container} from '@mui/material';
import {useTranslation} from 'react-i18next';
import LedgerIcon from '../../../assets/ledger.png';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import {Link as RouterLink} from 'react-router-dom';
interface ShopCarouselProps {
  /**
   * What background color to use
   */
  backgroundColor?: string;
  dir?: 'rtl' | 'ltr'
}

export function ShopCarousel(){
    const { t } = useTranslation();

    return (
        <Paper elevation={0} sx={{borderRadius: 0, mx: 0, px: 0, bgcolor: '#1B1B1D', py: 3}}>
            <Container maxWidth='lg'>
                <Stack direction="column">
                    <Grid>
                        <Typography variant='h4' sx={{textAlign: 'center', mb: 3}}>{t('shop')}</Typography>
                    </Grid>
                    <Grid container spacing={1}>
                        {
                            [1, 2, 3, 4].map((n) => <Grid key={n} item xs={12} sm={6} md={3} px={1}>
                                <Card sx={{borderRadius: '10px'}}>
                                    <CardMedia
                                        sx={{borderRadius: '10px', mx: 'auto', width: '95%', mt: 1}}
                                        component="img"
                                        image={LedgerIcon}
                                        alt="place holder image"
                                    />
                                    <CardContent sx={{pb: 0}}>
                                        <Typography gutterBottom variant='body1' fontWeight={'bold'}>
                                        کیف پول سخت افزاری کول ولت پرو    
                                        </Typography>
                                        <Typography color="text.secondary" variant='body2' sx={{mb: 2}}>
                                           {t('lorem').slice(0, 75)}
                                        </Typography>
                                        <Grid container justifyContent="space-between">
                                            <Typography gutterBottom variant='body1'>
                                                {t('price')}:
                                            </Typography>
                                            <Typography gutterBottom variant='body1'>
                                                12,000 تومان
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <Button fullWidth size='large' variant='contained'>{t('buy')}</Button>
                                    </CardActions>
                                </Card>
                            </Grid>)
                        }
                    </Grid>
                    <Box  justifyContent="center" alignItems="center" sx={{display: 'flex', my: 2}}>
                        <Button component={RouterLink} to={'/shop'} sx={{color: '#1E88E5', fontWeight: 'bold', border: 'none'}} variant='text' endIcon={<ArrowBackIos />}>{ t('view_more') }</Button>
                    </Box>
                </Stack>
            </Container>
        </Paper>
    );
}
