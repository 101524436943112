import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Refresh, Search, Visibility} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import EthIcon from '../../../assets/eth.png';
import RIcon from '../../../assets/r.png';
import './AssetsList.css';
import {useEffect, useState} from "react";
import client from "../../../config/client";
import {number_format} from "../../../config/helpers";
import {Link as RouterLink} from 'react-router-dom';


interface AssetInterface{
    icon: string;
    name: string;
    symbol: string;
    total: string;
    withdrawable: string;
    trading: string;
    total_usd: string;
}
function XsScreenFiatTable(props: {assets: AssetInterface[]}){
    const { t } = useTranslation();
    const {assets} = props;
    return (
        <>
            {
                assets.map(asset => (
                    <Grid item xs={12} key={asset.symbol} className='response-font-size'>
                        <Paper sx={{py: 2, px: 1, boxShadow: 'none'}} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{display: 'flex'}}>
                                    <Box>
                                        <img src={RIcon} />
                                    </Box>
                                    <Box sx={{alignSelf: 'center', mx: 1}}>
                                        {asset.name}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('total_balance')}</Box>
                                    <Box>
                                        {number_format(asset.total)}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('withdrawble')}</Box>
                                    <Box>
                                        {number_format(asset.withdrawable)}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('trading')}</Box>
                                    <Box>
                                        {number_format(asset.trading)}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('usd_eq')}</Box>
                                    <Box>
                                        {asset.total_usd}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box></Box>
                                    <Box>
                                        <Button component={RouterLink} to={'/deposit/irt'} sx={{mr: 0.5}} size='small' variant='contained'>{t('deposit')}</Button>
                                        <Button component={RouterLink} to={'/withdraw/irt'} sx={{ml: 0.5}} size='small' variant='contained'>{t('withdraw')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))
            }
        </>
    );
}

function SmUpScreenFiatTable(props: {assets: AssetInterface[]}){
    const { t } = useTranslation();
    const {assets} = props;
    return (
        <>
            <Grid item xs={12} className='response-font-size'>
                <Paper sx={{p: 1, bgcolor: '#383839', color: 'text.secondary'}} >
                    <Grid container>
                        <Grid item xs={1}>{t('symbol')}</Grid>
                        <Grid item xs={1}>{t('name')}</Grid>
                        <Grid item xs={2}>{t('total_balance')}</Grid>
                        <Grid item xs={2}>{t('withdrawble')}</Grid>
                        <Grid item xs={2}>{t('trading')}</Grid>
                        <Grid item xs={2}>{t('usd_eq')}</Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Paper>
            </Grid>
            {
                assets.map(asset => (
                    <Grid item xs={12} className='response-font-size' key={asset.symbol}>
                        <Paper sx={{py: 2, px: 1, boxShadow: 'none'}} >
                            <Grid container>
                                <Grid item xs={false} sm={1} sx={{alignSelf: 'center'}}>
                                    <Box sx={{display: 'flex'}}>
                                        <img src={RIcon} />
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sx={{alignSelf: 'center'}}>{asset.name}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{number_format(asset.total)}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{number_format(asset.withdrawable)}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{number_format(asset.trading)}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{asset.total_usd}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>
                                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                        <Button component={RouterLink} to={'/deposit/irt'} sx={{mr: 0.5}} size='small' variant='contained'>{t('deposit')}</Button>
                                        <Button component={RouterLink} to={'/withdraw/irt'} sx={{ml: 0.5}} size='small' variant='contained'>{t('withdraw')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))
            }
        </>
    );
}
function Fiats(){
    const { t } = useTranslation();
    const theme = useTheme();
    const xsMatches = useMediaQuery(theme.breakpoints.down('sm'));
    const [assets, setAssets] = useState<AssetInterface[]>([]);

    useEffect(() => {
        client.get('/v4/assets/fiats').then(function(resp){
          setAssets(resp.data.data);
        })
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <Typography variant='h6'>{t('fiat')}</Typography>
            </Grid>
            {
                xsMatches ? <XsScreenFiatTable assets={assets} /> : <SmUpScreenFiatTable assets={assets} />
            }
        </>
    );
}

function XsScreenCryptosTable(props: {assets: AssetInterface[]}){
    const { t } = useTranslation();
    const {assets} = props;
    return (
        <>
            {
                assets.map(r => (
                    <Grid item xs={12} className='response-font-size' key={r.symbol}>
                        <Paper sx={{py: 2, px: 1, boxShadow: 'none'}} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{display: 'flex'}}>
                                    <Box>
                                        <img src={r.icon} /> <Box sx={{alignSelf: 'center'}}>{r.symbol.toUpperCase()}</Box>
                                    </Box>
                                    <Box sx={{alignSelf: 'center', mx: 1}}>
                                        {r.name}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('total_balance')}</Box>
                                    <Box>
                                        {r.total}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('withdrawble')}</Box>
                                    <Box>
                                        {r.withdrawable}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('trading')}</Box>
                                    <Box>
                                        {r.trading}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>{t('btc_eq')}</Box>
                                    <Box>
                                        {r.total_usd}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'end'}}>
                                    <Box>
                                        <Button component={RouterLink} to={`/deposit/${r.symbol.toLowerCase()}`} sx={{mr: 0.5}} size='small' variant='contained'>{t('deposit')}</Button>
                                        <Button component={RouterLink} to={`/withdraw/${r.symbol.toLowerCase()}`} sx={{ml: 0.5}} size='small' variant='contained'>{t('withdraw')}</Button>
                                        <Button component={RouterLink} to={`/trade`} sx={{ml: 0.5}} size='small' variant='outlined'>{t('trade')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))
            }
        </>
    );
}

function SmUpScreenCryptosTable(props: {assets: AssetInterface[]}){
    const { t } = useTranslation();
    const {assets} = props;
    return (
        <>
            <Grid item xs={12} className='response-font-size'>
                <Paper sx={{p: 1, bgcolor: '#383839', color: 'text.secondary'}} >
                    <Grid container>
                        <Grid item xs={1}>{t('symbol')}</Grid>
                        <Grid item xs={1}>{t('name')}</Grid>
                        <Grid item xs={2}>{t('total_balance')}</Grid>
                        <Grid item xs={2}>{t('withdrawble')}</Grid>
                        <Grid item xs={2}>{t('trading')}</Grid>
                        <Grid item xs={2}>{t('btc_eq')}</Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Paper>
            </Grid>
            {
                assets.map(r => (
                    <Grid item xs={12} className='response-font-size' key={r.symbol}>
                        <Paper sx={{py: 2, px: 1, boxShadow: 'none'}} >
                            <Grid container>
                                <Grid item xs={false} sm={1} sx={{alignSelf: 'center'}}>
                                    <Box sx={{display: 'flex'}}>
                                        <img src={r.icon} /> <Box sx={{alignSelf: 'center', mx: 1}}>{r.symbol.toUpperCase()}</Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sx={{alignSelf: 'center'}}>{r.name}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{r.total}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{r.withdrawable}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{r.trading}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>{r.total_usd}</Grid>
                                <Grid item xs={2} sx={{alignSelf: 'center'}}>
                                    <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                        <Button component={RouterLink} to={`/deposit/${r.symbol.toLowerCase()}`} sx={{mr: 0.5}} size='small' variant='contained'>{t('deposit')}</Button>
                                        <Button component={RouterLink} to={`/withdraw/${r.symbol.toLowerCase()}`} sx={{ml: 0.5}} size='small' variant='contained'>{t('withdraw')}</Button>
                                        <Button component={RouterLink} to={`/trade`} sx={{ml: 0.5}} size='small' variant='outlined'>{t('trade')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))
            }
        </>
    );
}

function Cryptos(){
    const { t } = useTranslation();
    const theme = useTheme();
    const xsMatches = useMediaQuery(theme.breakpoints.down('md'));
    const [assets, setAssets] = useState<AssetInterface[]>([]);

    useEffect(() => {
        client.get('/v1/crypto-wallets').then((resp) => {
            let assets = resp.data.data.map((r: any) => {
                return {
                    name: r.currency,
                    symbol: r.abbr,
                    total: r.balance,
                    withdrawable: r.balance,
                    trading: 0,
                    icon: r.icon,
                    total_usd: r.usd
                };
            })

            setAssets(assets);
        })
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <Typography variant='h6'>{t('currency_balances')}</Typography>
            </Grid>
            {
                xsMatches ? <XsScreenCryptosTable assets={assets} /> : <SmUpScreenCryptosTable assets={assets}/>
            }
        </>
    );
}
export default function AssetsList(){
    const { t } = useTranslation();

    return (
        <Paper sx={{p: 2}}>
            <Grid container spacing={1}>
                <Grid item xs={12} container spacing={1}>
                    <Grid item>
                        <Button color='secondary' startIcon={<Visibility />}>{t('hide_small_balances')}</Button>
                    </Grid>
                    <Grid item flexGrow={1}>
                        <Button>{t('exchange_small_balances')}</Button>
                    </Grid>
                    <Grid item>
                        <Button startIcon={<Refresh />} variant="outlined">{t('update')}</Button>
                    </Grid>
                    <Grid item>
                        <TextField
                            size='small'
                            InputProps={{
                                endAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
                <Fiats />
                <Cryptos />
            </Grid>
        </Paper>
    );
}