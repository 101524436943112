import {
    Alert,
    Badge,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Paper,
    Slider,
    Stack,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import ReferralImage from '../../assets/referral.png';
import DollarGiftImage from '../../assets/dollar-gift.png';
import AddUsersImage from '../../assets/add-users.png';
import PersonIcon from '@mui/icons-material/Person';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InfoIcon from '@mui/icons-material/Info';
import Page from "../Page";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {makeDense} from "../../features/footer/FooterSlice";
import {selectIsLoggedIn} from "../../features/auth/authSlice";
import {Link as RouterLink} from 'react-router-dom';
import client from "../../config/client";

function NotLoggedIn(){
    const [t] = useTranslation();
    return (
        <Grid container sx={{px: 1}}>
            <Grid item xs={12} lg={8} sx={{pt: 2, pb: 10, mx: 'auto'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 4}}>
                            <Typography variant={'h5'} textAlign={'center'}>برای استفاده از امکانات سیستم بایستی وارد شوید</Typography>
                            <Stack direction={'row'} spacing={2} justifyContent={'center'} sx={{my: 5}}>
                                <Box>
                                    <Button component={RouterLink} to={'/login'} variant={'outlined'} size={'large'}>{t('login')}</Button>
                                </Box>
                                <Box>
                                    <Button component={RouterLink} to={'/signup'} variant={'contained'} size={'large'}>{t('sign_up')}</Button>
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
function HasErrors(props: {errors: []}){
    const [t] = useTranslation();
    return (
        <Grid container sx={{px: 1}}>
            <Grid item xs={12} lg={8} sx={{pt: 2, pb: 10, mx: 'auto'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{p: 4}}>
                            {
                                props.errors.map((err)=>(
                                    <Alert severity="error" sx={{mb: 2}}>{ err }</Alert>
                                ))
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default function Referral(){
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const [affiliate, setAffiliate] = useState([])
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState({
        affiliationPercent: 0,
        affiliationReturn: 0,
        affiliationToken: '0',
        commissions: [],
        inviteds: [],
        inviter: '',
        referrals: [],
        referrerCount: 0,
    })

    useEffect(function(){
        dispath(makeDense())
    }, [])

    useEffect(() => {
        setLoading(true)
        setErrors([])
        client.get('/v2/affiliate').then((resp)=>{
            setData(resp.data.data);
        }).catch((err) => {
            if(err?.response?.data?.errors){
                setErrors(err.response.data.errors)
            }else if(err?.response?.data?.message){
                setErrors([err.response.data.message])
            }else{
                setErrors([err.toString()])
            }
        }).finally(() => setLoading(false))
    }, [])

    const handleCreateLink = () => {
        window.location.reload();
    }

    return (
        <Page title={t('referral')}>
            <Paper elevation={0} sx={{mx: 0, px: 2, py: 5, textAlign: 'center', borderRadius: 0, bgcolor: 'background.default'}}>
                <Typography fontWeight={'bold'} sx={{mb: 1}} variant={'h5'}>{t('referral_title')}</Typography>
                <Typography color={'text.secondary'}>{t('referral_subtitle')}</Typography>
                <Stack sx={{mt: 3}} direction={'row'} justifyContent={'center'} spacing={2}>
                    <Stack spacing={1} maxWidth={200}>
                        <Box>
                            <img src={ReferralImage} />
                        </Box>
                        <Typography variant={'h6'}>
                            <Badge sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'rgba(252, 213, 53, 0.1)',
                                    color: '#FCD535'
                                }
                            }}
                            badgeContent={1} color="primary" />
                            <Box component={'span'} sx={{pl: 2}}>{t('referral_step1_title')}</Box>
                        </Typography>
                        <Typography variant={'subtitle2'} color={'text.secondary'}>{t('referral_step1_subtitle')}</Typography>
                    </Stack>
                    <Stack spacing={1} maxWidth={200}>
                        <Box>
                            <img src={AddUsersImage} />
                        </Box>
                        <Typography variant={'h6'}>
                            <Badge sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'rgba(252, 213, 53, 0.1)',
                                    color: '#FCD535'
                                }
                            }} badgeContent={2} color="primary" />
                            <Box component={'span'} sx={{pl: 2}}>{t('referral_step2_title')}</Box>
                        </Typography>
                        <Typography variant={'subtitle2'} color={'text.secondary'}>{t('referral_step2_subtitle')}</Typography>
                    </Stack>
                    <Stack spacing={1} maxWidth={200}>
                        <Box>
                            <img src={DollarGiftImage} />
                        </Box>
                        <Typography variant={'h6'}>
                            <Badge sx={{
                                '& .MuiBadge-badge': {
                                    backgroundColor: 'rgba(252, 213, 53, 0.1)',
                                    color: '#FCD535'
                                }
                            }} badgeContent={3} color="primary" />
                            <Box component={'span'} sx={{pl: 2}}>{t('referral_step3_title')}</Box>
                        </Typography>
                        <Typography variant={'subtitle2'} color={'text.secondary'}>{t('referral_step3_subtitle')}</Typography>
                    </Stack>
                </Stack>
            </Paper>
            {
                errors.length == 0 && isLoggedIn && (
                    <Grid container sx={{px: 1}}>
                        <Grid item xs={12} lg={8} sx={{pt: 2, pb: 10, mx: 'auto'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper sx={{p: 4}}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant={'h6'}>{t('default_referral_code')}</Typography>
                                                <Typography color={'text.secondary'}>{t('default_referral_code_desc')}</Typography>
                                                <Paper elevation={0} sx={{
                                                    backgroundColor: 'rgba(252, 213, 53, 0.1)',
                                                    my: 3,
                                                    p: 2,
                                                    border: '1.5px solid #FCD535'
                                                }}>
                                                    <Stack
                                                        justifyContent={'space-between'}
                                                        divider={<Divider orientation="vertical" flexItem />}
                                                        direction="row" spacing={2}>
                                                        <Box>
                                                            <Typography textAlign={'center'}>{t('your_share')}</Typography>
                                                            <Typography textAlign={'center'}>{data.affiliationPercent} %</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography textAlign={'center'}>{t('others_share')}</Typography>
                                                            <Typography textAlign={'center'}>0</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Paper>
                                                <Box sx={{mb: 2}}>
                                                    <Typography><PersonIcon /> {t('your_referral_code')}</Typography>
                                                    <FormControl sx={{ m: 1}} variant="outlined" fullWidth>
                                                        <OutlinedInput
                                                            type={'text'}
                                                            size={'small'}
                                                            value={data.affiliationToken}
                                                            disabled
                                                            endAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton edge="end">
                                                                        <ContentCopyIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box >
                                                    <Typography><LinkIcon /> {t('your_referral_link')}</Typography>
                                                    <FormControl sx={{ m: 1}} variant="outlined" fullWidth>
                                                        <OutlinedInput
                                                            type={'text'}
                                                            size={'small'}
                                                            value={`https://pouyam.com/signup/r=${data.affiliationToken}`}
                                                            disabled
                                                            endAdornment={
                                                                <InputAdornment position="start">
                                                                    <IconButton edge="end">
                                                                        <ContentCopyIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Stack direction={'row'} spacing={1}>
                                                    <Button fullWidth variant={'contained'} color={'warning'}>{t('share_referral_link')}</Button>
                                                    <IconButton sx={{borderRadius: '8px'}} aria-label="qrcode" color={'warning'}>
                                                        <QrCodeIcon />
                                                    </IconButton>
                                                </Stack>
                                                <Stack spacing={1} sx={{mt: 2}}>
                                                    <Typography><InfoIcon /> {t('referral_tip')}</Typography>
                                                    <Typography><InfoIcon /> {t('terms_and_rules')}</Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Paper sx={{
                                                    backgroundColor: 'rgba(252, 213, 53, 0.1)',
                                                    my: 3,
                                                    p: 2,
                                                }}>
                                                    <Typography fontWeight={'bold'}>{t('create_custom_referral_link')}</Typography>
                                                    <Typography fontSize={'small'} color={'text.secondary'}>{t('create_custom_referral_link_desc')}:</Typography>
                                                    <Typography sx={{mt: 2}}>{t('specify_your_friends_share')}:</Typography>
                                                    <Slider
                                                        aria-label="Temperature"
                                                        defaultValue={5}
                                                        valueLabelDisplay="auto"
                                                        step={0.5}
                                                        marks
                                                        min={0}
                                                        max={20}
                                                    />
                                                    <Paper elevation={0} sx={{
                                                        backgroundColor: 'rgba(252, 213, 53, 0.1)',
                                                        mt: 3,
                                                        p: 1,
                                                        border: '1.5px solid #FCD535'
                                                    }}>
                                                        <Stack
                                                            justifyContent={'space-between'}
                                                            divider={<Divider orientation="vertical" flexItem />}
                                                            direction="row" spacing={2}>
                                                            <Box>
                                                                <Typography textAlign={'center'}>{t('your_share')}</Typography>
                                                                <Typography textAlign={'center'}>20 %</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography textAlign={'center'}>{t('others_share')}</Typography>
                                                                <Typography textAlign={'center'}>0</Typography>
                                                            </Box>
                                                        </Stack>
                                                    </Paper>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox />} label={t('set_as_default_referral_link')} />
                                                    </FormGroup>
                                                    <Button onClick={handleCreateLink} sx={{mt: 2}} fullWidth color={'warning'} variant={'contained'}>{t('create_referral_link')}</Button>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{p: 2}}>
                                        <Typography variant={'h6'} sx={{mb: 1}}>{t('your_account_stat')}</Typography>
                                        <Grid container sx={{bgcolor: '#383839', color: '#848E9C', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', p: 1}}>
                                            <Grid item xs={3}>{t('commission_rate')}</Grid>
                                            <Grid item xs={3}>{t('total_commission_gained')}</Grid>
                                            <Grid item xs={3}>{t('your_friends')}</Grid>
                                            <Grid item xs={3}>{t('your_friends_trades')}</Grid>
                                        </Grid>
                                        {/*<Grid container sx={{p: 1}} spacing={2}>
                                            <Grid item xs={3}>20%</Grid>
                                            <Grid item xs={3}>35,540,000 {t('toman')}</Grid>
                                            <Grid item xs={3}>3</Grid>
                                            <Grid item xs={3}>6</Grid>
                                        </Grid>*/}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{p: 2}}>
                                        <Typography variant={'h6'} sx={{mb: 1}}>{t('created_referral_links')}</Typography>
                                        <Grid container sx={{bgcolor: '#383839', color: '#848E9C', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', p: 1}}>
                                            <Grid item xs={2}>{t('referral_code')}</Grid>
                                            <Grid item xs={2}>{t('your_share_friends_share')}</Grid>
                                            <Grid item xs={2}>{t('friends_count')}</Grid>
                                            <Grid item xs={2}>{t('gained_commission_sum')}</Grid>
                                            <Grid item xs={4}>{t('operations')}</Grid>
                                        </Grid>
                                        {/*<Grid container sx={{p: 1}} spacing={2}>
                                            <Grid item xs={2}>5658233</Grid>
                                            <Grid item xs={2}>15/10</Grid>
                                            <Grid item xs={2}>3</Grid>
                                            <Grid item xs={2}>458,450,000 {t('toman')}</Grid>
                                            <Grid item xs={4}>
                                                <Button size={'small'} color={'warning'} variant={'contained'}>{t('copy_link')}</Button>
                                            </Grid>
                                        </Grid>*/}

                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{p: 2}}>
                                        <Typography variant={'h6'} sx={{mb: 1}}>{t('commissions_history')}</Typography>
                                        <Grid container sx={{bgcolor: '#383839', color: '#848E9C', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', p: 1}}>
                                            <Grid item xs={3}>{t('date')}</Grid>
                                            <Grid item xs={6}>{t('your_friends_email')}</Grid>
                                            <Grid item xs={3}>{t('commission')}</Grid>
                                        </Grid>
                                        {/*<Grid container sx={{p: 1}} spacing={2}>
                                            <Grid item xs={3}>1401:10:11 14:51</Grid>
                                            <Grid item xs={6}>foo@bar.com</Grid>
                                            <Grid item xs={3}>356,000 {t('toman')}</Grid>
                                        </Grid>*/}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                isLoggedIn && errors.length > 0 && (
                    <HasErrors errors={errors}/>
                )
            }
            {
                !isLoggedIn && (
                    <NotLoggedIn />
                )
            }
        </Page>
    );
}