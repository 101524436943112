import {
    Alert,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import LoadingButton from '@mui/lab/LoadingButton';
import {useFormik} from "formik";
import * as yup from 'yup';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectToken, selectType, selectUsername} from "./SignupSlice";
import LockIcon from '@mui/icons-material/Lock';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import client from "../../config/client";
import {useNavigate} from 'react-router-dom';
import {login} from "../auth/authSlice";

export default function PasswordCard(){
    const { t } = useTranslation();
    const type = useAppSelector(selectType);
    const username = useAppSelector(selectUsername);
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [errorsSnackbarIsOpen, setErrorsSnackbarIsOpen] = useState(false);
    const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
    const navigate = useNavigate();
    const validationSchema = yup.object({
        password: yup
            .string()
            .required(t('please_enter_password') as string)
            .min(6, t('password_at_least_6_chars') as string),
        password_confirmation: yup
            .string()
            .required(t('please_enter_password_again') as string)
            .oneOf([yup.ref('password')], t('passwords_mismatched') as string),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            client.post('/v4/auth/password', {token: token, password: values.password}).then(function(resp){
                setSuccessSnackbarIsOpen(true);
                dispatch(login(resp?.data?.data?.access_token))
                setTimeout(function(){
                    navigate('/dashboard')
                }, 2000)
            }).catch(function(err){
                const errors = [];
                errors.push(err.message);
                if(err?.response?.data?.errors){
                    setErrors(err.response.data.errors)
                }else if(err?.message){
                    setErrors(errors)
                }
                setErrorsSnackbarIsOpen(true)
            }).finally(function(){
                setSubmitting(false);
            })
        },
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleCloseErrorsSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setErrorsSnackbarIsOpen(false);
    };
    return (
        <React.Fragment>
            <Card sx={{borderRadius: '10px'}}>
                <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography fontWeight='bold' variant='h6' component='h4' textAlign='center'>{ t('set_your_password') }</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{mb: 1}}><LockIcon /> { t('password') }</Typography>
                                <TextField
                                    id='password'
                                    name={'password'}
                                    sx={{direction: 'rtl', borderRadius: '10px', '& .MuiInputBase-input': {paddingRight: '14px'}}}
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{mb: 1}}><LockIcon /> { t('password_confirmation') }</Typography>
                                <TextField
                                    id='password_confirmation'
                                    name={'password_confirmation'}
                                    sx={{direction: 'rtl', borderRadius: '10px', '& .MuiInputBase-input': {paddingRight: '14px'}}}
                                    fullWidth
                                    type={showPasswordConfirmation ? 'text' : 'password'}
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.password_confirmation}
                                    error={formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)}
                                    helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConfirmation}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{mt: 2}}>
                                {!formik.isSubmitting && <Button type="submit" variant="contained" fullWidth size='large'>{ t('confirm') }</Button>}
                                {formik.isSubmitting && (
                                    <LoadingButton loading variant="outlined" fullWidth size='large' loadingIndicator={t('validating')}>
                                        { t('loading') }
                                    </LoadingButton>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={errorsSnackbarIsOpen} autoHideDuration={6000} onClose={handleCloseErrorsSnackbar}>
                <Alert onClose={handleCloseErrorsSnackbar} severity="error" sx={{ width: '100%', bgcolor: 'error.dark' }}>
                    {errors.length > 0 && errors[0]}
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={successSnackbarIsOpen} autoHideDuration={6000} >
                <Alert  severity="success" sx={{ width: '100%', bgcolor: 'success.dark' }}>
                    {t('signup_done_redirecting')}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}