import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useTranslation} from "react-i18next";

interface Data {
    price: string;
    volume: string;
    date: string;
}

function createData(
    price: string,
    volume: string,
    date: string,
): Data {
    return {
        price,
        volume,
        date,
    };
}

const rows = [
    createData('2,148,200', '0.0041', '10:14:15'),
    createData('2,148,200', '2.001512', '19:10:15'),
    createData('2,148,200', '2.001512', '19:10:15'),
    createData('2,148,200', '2.001512', '19:10:15'),
    createData('2,148,200', '2.001512', '19:10:15'),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}



interface EnhancedTableProps {
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const [t] = useTranslation();
    const headCells: readonly HeadCell[] = [
        {
            id: 'price',
            numeric: false,
            disablePadding: true,
            label: t('price'),
        },
        {
            id: 'volume',
            numeric: true,
            disablePadding: false,
            label: t('volume'),
        },
        {
            id: 'date',
            numeric: true,
            disablePadding: false,
            label: t('date'),
        }
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function Orders() {
    return (
        <Box sx={{ width: '100%' }}>
            <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{
                            '& .MuiTableCell-sizeSmall': {
                                fontSize: '0.775rem',
                                padding: '5px 16px',
                            },
                            '& tbody .MuiTableCell-sizeSmall': {
                                borderBottom: 0
                            },
                            '& thead .MuiTableCell-sizeSmall': {
                                borderBottom: '2px solid rgba(206, 212, 218, 0.2)'
                            }
                        }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.price}
                                            </TableCell>
                                            <TableCell align="right">{row.volume}</TableCell>
                                            <TableCell align="right">{row.date}</TableCell>
                                        </TableRow>
                                    );
                            })}
                            <TableRow>
                                <TableCell colSpan={3} sx={{bgcolor: 'black', textAlign: 'center', color: 'green'}}>154,010,000</TableCell>
                            </TableRow>
                            {rows.map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.price}
                                        </TableCell>
                                        <TableCell align="right">{row.volume}</TableCell>
                                        <TableCell align="right">{row.date}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}