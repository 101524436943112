import {Box, Button, Grid, IconButton, Paper, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ChartIcon from '../../../assets/chart.png';
import './OpenOrders.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EthIcon from '../../../assets/eth.png';

function Rows(){
    const { t } = useTranslation();
    return (
        <>
            {
                [0, 1, 2, 3, 4, 5].map(element => (
                    <Paper className="live-prices data-row" sx={{borderRadius: '10px', px: 2, py: 1, my: 1}} elevation={0}>
                        <Grid container columns={18}>
                            <Grid sx={{alignSelf: 'center'}} item xs={1}>
                                <img src={EthIcon} />
                            </Grid>
                            <Grid sx={{alignSelf: 'center'}} item xs={4} sm={5}>
                                <Box component='span'>BTC</Box>
                                <Box component='span' sx={{display: {xs: 'none', sm: 'inline'}}}>/USDT</Box>
                            </Grid>
                            <Grid sx={{alignSelf: 'center'}} item lg={2} xs={2}>5.2+</Grid>
                            <Grid sx={{alignSelf: 'center'}} item lg={2} xs={5} sm={3.5}>1,142,431,000</Grid>
                            <Grid sx={{alignSelf: 'center'}} item lg={2} xs={5} sm={3.5}>1,132,431,000</Grid>
                            <Grid item sx={{display: {xs: 'none', lg: 'block', alignSelf: 'center'}}} lg={3}>
                                <img src={ChartIcon} />
                            </Grid>
                            <Grid sx={{alignSelf: 'center', textAlign: 'right'}} item xs={1} sm={3}>
                                <Button sx={{display: {xs: 'none', sm: 'inline'}, whiteSpace: 'nowrap'}} variant='outlined' color='secondary'>{t('buy_sell')}</Button>
                                <IconButton color='primary' size='small' sx={{display: {xs: 'inline', sm: 'none'}}}><ArrowBackIosIcon fontSize='small' /></IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                ))
            }
        </>
    );
}
export default function OpenOrders(){
    const { t } = useTranslation();

    return (
        <Grid container sx={{mx: 'auto'}} rowSpacing={2}>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{mt: 'auto'}}>
                    <Typography>{ t('open_orders') }</Typography>
                </Box>
                <Box>
                    <IconButton><KeyboardBackspaceIcon /></IconButton>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Paper className="live-prices data-row" sx={{borderRadius: '10px', px: 2, py: 1}} elevation={0}>
                    <Grid container columns={18}>
                        <Grid item xs={2} sm={3}>{t('currency_pairs')}</Grid>
                        <Grid item lg={2} xs={2}>{t('date')}</Grid>
                        <Grid item lg={1} xs={1} sm={1}>{t('direction')}</Grid>
                        <Grid item lg={2} xs={2} sm={2}>{t('price')}</Grid>
                        <Grid item lg={2} xs={2} sm={2}>{t('amount')}</Grid>
                        <Grid item lg={1} xs={1} sm={1}>{t('filled')}</Grid>
                        <Grid item xs={2}>{t('total_amount')}</Grid>
                        <Grid item xs={1} sm={2}>{t('status')}</Grid>
                    </Grid>
                </Paper>
                <Paper className="live-prices data-row" sx={{borderRadius: '10px', px: 2, py: 1, my: 1}} elevation={0}>
                    <Grid container columns={18}>
                        <Grid sx={{alignSelf: 'center'}} item xs={12}>
                           <Typography color={'text.secondary'} textAlign={'center'}> اطلاعاتی برای نمایش وجود ندارد</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
