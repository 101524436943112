import {Box, Grid, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectSymbol, setNetwork} from "./withdrawFormSlice";
import NetworkImage from '../../assets/network.png';
import client from "../../config/client";

interface NetworkInterface{
    id: string,
    name: string,
}

export default function NetworkSelector(){
    const { t } = useTranslation();
    const [value, setValue] = useState<NetworkInterface>(() => {
        return {
            id: '',
            name: '',
        };
    });
    const [items, setItems] = useState<NetworkInterface[]>([]);
    const symbol = useAppSelector(selectSymbol);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!symbol && symbol == 'irt') return;

        client.get(`/v4/networks/${symbol}`).then((resp)=>{
           if(resp?.data?.data?.networks){
               setItems(resp?.data?.data?.networks)
           }
        });
    }, [symbol])


    function SelectedItem(){
        return (
            <Grid container spacing={1}>
                <Grid item flexGrow={1}>
                    <Typography>{ value?.name }</Typography>
                </Grid>
            </Grid>
        );
    }
    return (
        <Box sx={{mb: 2}}>
            <Typography color={'text.secondary'} sx={{mb: 1}}><img src={NetworkImage} width={16} /> {t('network_type')}</Typography>
            <Select
                fullWidth
                value={value}
                size="small"
                renderValue={SelectedItem}
                IconComponent={KeyboardArrowDownIcon}
            >

                {items.map((option) => (
                    <MenuItem
                        onClick={(event: any) => {
                            setValue(option)
                            dispatch(setNetwork(option))
                        }}
                        key={option.id} value={option as any}>
                        <Grid container spacing={1}>
                            <Grid item flexGrow={1}>
                                <Typography>{option.name}</Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}