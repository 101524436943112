import {Container, Grid, Paper, Typography} from "@mui/material";
import Page from "../Page";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import SignupForm from "../../features/signup-form";
import {useAppDispatch} from "../../app/hooks";
import {makeDense} from "../../features/footer/FooterSlice";
import MetallicAlert from "../../components/atomic/metallic-alert";
import ErrorIcon from '@mui/icons-material/Error';
import ForgetForm from "../../features/forget-form";

export default function Forget() {
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(() => {
        window.location.href = 'https://panel.pouyam.com/ForgetPassword';
    });
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('recover_password')}>
            <Container maxWidth={false} sx={{mx: 0, px: '0!important'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper elevation={0} sx={{borderRadius: 0, py: 4, textAlign: 'center', bgcolor: '#222631'}}>
                            <Typography fontWeight='bold' variant='h5' component='h1'>{ t('recover_password') }</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <MetallicAlert
                            severity={'warning'}
                            sx={{
                                borderRadius: 0,
                                justifyContent: 'center'
                            }}
                            icon={<ErrorIcon />}
                        >
                            {t('recover_password_warning')}
                        </MetallicAlert>
                    </Grid>
                    <Grid item xs={12} sx={{bgcolor: '#1B1B1D', py: 5}}>
                        <ForgetForm />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}