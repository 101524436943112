import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Divider, Grid, IconButton, Paper} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import client from "../../../config/client";

export default function AnnouncementWidget(){
    const {t} = useTranslation();
    const [announcements, setAnnouncements] = useState([]);
    useEffect(() => {
        client.get(`/v1/announcement`).then(function(resp){
            setAnnouncements(resp.data.data.announcements);
        })
    }, [])
    return (
        <Paper sx={{p: 2, display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Typography>{ t('notifications') }</Typography>
                <IconButton><KeyboardBackspaceIcon /></IconButton>
            </Stack>
            <Divider />
            {
                announcements.length === 0 ? (
                    <Grid container sx={{my: 2}}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography sx={{textAlign: 'center'}}>اطلاعاتی برای نمایش وجود ندارد</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {
                            announcements.map(function(announcement: any){
                                return (
                                    <Grid key={announcement.id} container sx={{my: 2}}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography>{announcement.title}</Typography>
                                            </Box>
                                            <Box sx={{direction: 'rtl'}}>
                                                <Typography>{announcement.created_at}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </>
                )
            }
        </Paper>
    );
}