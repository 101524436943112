import * as React from 'react';
import {useCallback, useEffect, useState, Profiler} from 'react';
import {Box, Button, Container, Divider, Grid, IconButton, Link, Paper, Stack, Typography} from '@mui/material';
import axios from 'axios';
import Loading from "../Loading";
import {Link as RouterLink} from 'react-router-dom';
import DollarPrice from "../DollarPrice";
import Percentage from "../Percentage";
import Toman from "../Toman";
import Blinker from "../Blinker";
import InfiniteScroll from 'react-infinite-scroll-component';
import Ellipsis from "../Ellipsis";
import {useTranslation} from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

interface   Currency{
    name: string,
    symbol: string,
    slug: string,
    price: string,
    price_toman: string,
    cmcRank: number,
    cmcId: number,
    percentChange24h: string,
    percentChange30d: string,
    raise?: -1 | 0 | 1
}

interface CurrenciesTableProps{
    withHeader?: boolean,
    withWs?: boolean,
    withPagination?: boolean,
    withQuery?: boolean,
    query?: string
}

function onRenderCallback(
    id: any, // the "id" prop of the Profiler tree that has just committed
    phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration: any, // time spent rendering the committed update
    baseDuration: any, // estimated time to render the entire subtree without memoization
    startTime: any, // when React began rendering this update
    commitTime: any, // when React committed this update
    interactions: any // the Set of interactions belonging to this update
) {
    console.log(id);
}

function CurrencyRow(props: { currency: Currency}){
    const [t] = useTranslation();
  return (
      <Box sx={{'&:hover': {backgroundColor: '#222631'}}}>
          <Grid container py={1}>
              <Grid item xs={1} sx={{ textAlign: 'center', my: 'auto'}}>
                  <Typography variant='body1'>{ props.currency.cmcRank }</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'center'}} justifyContent="center">
                  <Stack direction='row'>
                      <Box sx={{ my: 'auto'}}>
                          <RouterLink to={`/currencies/${props.currency.cmcRank}/${props.currency.slug.toLowerCase()}`}>
                              <img alt={props.currency.name} width={24} src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${props.currency.cmcId}.png`} />
                          </RouterLink>
                      </Box>
                      <Stack sx={{ textAlign: 'left', ml: 1}}>
                          <Box>
                              <Link underline="none" component={RouterLink} to={`/currencies/${props.currency.cmcRank}/${props.currency.slug.toLowerCase()}`}>
                                  <Ellipsis text={props.currency.name} />
                              </Link>
                          </Box>
                          <Box>
                              <Typography color={'grey'}>{ props.currency.symbol.toUpperCase() }</Typography>
                          </Box>
                      </Stack>
                  </Stack>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                  <Box>
                      <Blinker mode={props.currency.raise}>
                          <DollarPrice price={props.currency.price} />
                      </Blinker>
                  </Box>
                  <Box sx={{ direction: 'rtl', color: 'grey'}}>
                      <Toman
                          irt={props.currency.price_toman}
                          usd={props.currency.price}
                          symbol='تومان'
                      />
                  </Box>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'center', my: 'auto'}}><Percentage value={props.currency.percentChange24h} /></Grid>
              <Grid sx={{alignSelf: 'center', textAlign: 'right'}} item xs={2} >
                  <Button component={RouterLink} to={`/coins/${props.currency.slug.toLowerCase()}`} sx={{display: {xs: 'none', sm: 'inline'}, whiteSpace: 'nowrap'}} variant='outlined' color='inherit'>{t('buy_sell')}</Button>
                  <IconButton component={RouterLink} to={`/coins/${props.currency.slug.toLowerCase()}`} color='primary' size='small' sx={{display: {xs: 'inline', sm: 'none'}}}><ArrowBackIosIcon fontSize='small' /></IconButton>
              </Grid>
          </Grid>
          <Divider />
      </Box>
  );
}


function Currencies(props: {currencies: Currency[]}){
    return (
        <Box>
            { props.currencies.map( (currency) => (
                <CurrencyRow key={currency.cmcId} currency={currency} />
            ))}
        </Box>
    );
}

function CurrencyHead(){
    const [t] = useTranslation();
    return (
        <Paper className="live-prices data-row" sx={{borderRadius: '10px', px: 2, py: 1}} elevation={0}>
            <Grid container>
                <Grid item xs={1} sx={{ textAlign: 'center', my: 'auto'}}>#</Grid>
                <Grid item xs={3}>{t('name')}</Grid>
                <Grid item xs={4} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>{t('price')}</Grid>
                <Grid item xs={2} sx={{ textAlign: 'center', my: 'auto'}}>24h</Grid>
                <Grid item xs={2} sx={{alignSelf: 'center', textAlign: 'right'}}></Grid>
            </Grid>
        </Paper>
    );
}

interface LivePricesTableProps{
    label?: string,
    more?: string,
    dense: boolean
}
export default function CurrenciesTable(props: LivePricesTableProps) {
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [finished, setFinished] = useState<boolean>(false)
    const [wsUrl, setWsUrl] = useState<string>("")
    const [ticking, setTicking] = useState<boolean>(false);
    const [currencies, setCurrencies] = useState<Currency[]>([])
    const [updates, setUpdates] = useState<any>({});
    const [assets, setAssets] = useState("ALL");
    const [t] = useTranslation();


    const getCurrencies = useCallback(() => {
        setLoading(true)
        const limit = props.dense ? 10 : 400;
        let nextPage = Math.floor(currencies.length / limit) + 1;
        axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/listing?page=${nextPage}&limit=${limit}`)
            .then(response => response.data)
            .then((data) => {
                setCurrencies(mergeCurrencies(currencies, data.data.cryptoCurrencyList))

            }).catch(function(err){
            if(err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number"){
                setFinished(true);
            }

        }).finally(() => {
            setLoading(false)
        });
    }, [currencies]);

    useEffect(() => {
        setWsUrl(`wss://ws.ramzarz.news/prices?assets=${assets}`);
    }, [assets])

    useEffect(() => {
        setAssets(currencies.map((currency) => currency.slug).join(','));
    }, [currencies])

    useEffect(() => {
        if(!!wsUrl){
            const ws = new WebSocket(wsUrl);
            ws.onmessage = (event) => {
                if(!loading){
                    setUpdates(JSON.parse(event.data))
                }
            }
            return () => {
                ws.close();
            }
        }
    }, [wsUrl, loading]);

    useEffect(() => {
        let updatedCurrencies = currencies.map((currency) => {
            Object.keys(updates).forEach((currencyName) => {
                if(currency.name.toLowerCase() === currencyName.toLowerCase()){
                    currency.price = updates[currencyName];
                }
            })

            return currency;
        })

        if(!loading){
            setCurrencies(updatedCurrencies);
        }

    }, [updates, loading]);


    useEffect(() => {
        getCurrencies()
    }, []);

    function mergeCurrencies<Type extends Currency>(currentCurrencies: Type[], newCurrencies: Type[]): Type[]{
        return currentCurrencies.concat(newCurrencies.filter((crypto)=>{
            let alreadyExists =  false;
            for(let i=0;i<currentCurrencies.length;i++){
                if(currentCurrencies[i].cmcId === crypto.cmcId){
                    alreadyExists = true
                    break;
                }
            }

            return !alreadyExists;
        }))
    }

  return (
      <Container className="currencies-table custom-font-size" sx={{ direction: 'ltr'}} disableGutters maxWidth={false}>
          {!!props.label && <Grid item xs={12} mb={3}>
              <Typography textAlign='center' variant='h5'>{props.label}</Typography>
          </Grid>}
          <CurrencyHead />
          {!props.dense && (
              <InfiniteScroll
                  dataLength={currencies.length}
                  next={getCurrencies}
                  hasMore={true}
                  loader={<Loading />}
                  endMessage={
                      <p style={{ textAlign: 'center' }}>
                          <b>اطلاعات بیشتر برای نمایش وجود ندارد</b>
                      </p>
                  }
                  style={{overflow: 'unset'}}
              >
                  <Currencies currencies={currencies} />
              </InfiniteScroll>
          )}
          {props.dense && (
              <Currencies currencies={currencies} />
          )}
          {!!props.more && <Grid item xs={12} mt={3}>
              <Typography textAlign='center' variant='body1' fontWeight={'bold'}>
                  <Button component={RouterLink} to={props.more} sx={{color: '#1E88E5', fontWeight: 'bold', border: 'none'}} variant='text' endIcon={<ArrowBackIosIcon />}>{ t('view_more') }</Button>
              </Typography>
          </Grid>}
      </Container>
  );
}
