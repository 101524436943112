import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../app/store";

export interface AuthState {
    username: string;
    token: string | null;
}

const initialState: AuthState = {
    username: '-',
    token: null
};


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            localStorage.setItem('access_token', action.payload)
        },
        logout: (state) => {
            state.token = null;
            localStorage.removeItem('access_token');
        },
    }
});


export const { login, logout } = authSlice.actions;


export const selectIsLoggedIn = (state: RootState) => !!state.auth.token;
export const selectAccessToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;