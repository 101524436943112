import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Button} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";

const GoldButton = styled(Button)<ButtonProps>(({theme}) => ({
    '&:hover': {
        color: 'rgba(254, 176, 25, 1)',
        backgroundColor: 'rgba(50, 42, 29, 1)'
    },
    '&:active': {
        color: 'rgba(254, 176, 25, 1)',
        backgroundColor: 'rgba(50, 42, 29, 1)'
    },
    '&:focus': {
        color: 'rgba(254, 176, 25, 1)',
        backgroundColor: 'rgba(50, 42, 29, 1)'
    },
    color: 'rgba(254, 176, 25, 1)',
    backgroundColor: 'rgba(50, 42, 29, 1)'
}))
export default GoldButton;