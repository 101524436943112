import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { t } from "i18next";
import Table1Wage from '../table1-wage';
import './Threesectiontab.css'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p:{xs:0 , md:3} }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ThreeSectionTabWage() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box >
                <Tabs sx={{display:'flex' , flexDirection:{xs:'column' , md:'row'}}} value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs_three_tab_wage' TabIndicatorProps={{
                    style: {
                        display: "none"
                    }
                }} >
                    <Tab label={`${t('rial')}`} {...a11yProps(0)} sx={{ border: '1px solid #FCD535', borderRadius: '10px', color: 'primary.main'  , maxWidth:'100%'}} />
                    <Tab label={`${t('lir')}`} {...a11yProps(1)} sx={{ border: '1px solid #FCD535', borderRadius: '10px', color: 'primary.main'  , maxWidth:'100%'}} />
                    <Tab label={`${t('crypto')}`} {...a11yProps(2)} sx={{ border: '1px solid #FCD535', borderRadius: '10px', color: 'primary.main'  , maxWidth:'100%'}} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
                <Table1Wage />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Table1Wage />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Table1Wage />
            </TabPanel>
        </Box>
    );
}
