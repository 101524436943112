import React, {useCallback} from "react";
import {
    Collapse,
    Drawer as BaseDrawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar
} from "@mui/material";
import Box from "@mui/material/Box";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {close as closeDrawer, selectDrawerStatus} from "./DrawerSlice";
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import {useTranslation} from "react-i18next";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SettingsIcon from '@mui/icons-material/Settings';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import {Link as RouterLink, useLocation} from 'react-router-dom';

const drawerWidth = 240;
export default function Drawer(){
    const isDrawerOpen = useAppSelector(selectDrawerStatus)
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    const location = useLocation();
    const [opened, setOpened] = React.useState<'wallet' | 'orders' | 'settings' | 'support' | null >(function(){
        if(location.pathname === '/wallet') return 'wallet';
        if(location.pathname === '/deposit') return 'wallet';
        if(location.pathname === '/withdraw') return 'wallet';
        if(location.pathname === '/transactions') return 'wallet';
        if(location.pathname === '/settings') return 'settings';
        if(location.pathname === '/security') return 'settings';
        if(location.pathname === '/notifications') return 'settings';
        return null;
    });
    const isNavSelected = useCallback((nav: string) => {
        return nav === location.pathname.replace('/', '')
    }, [location]);
    function changeOpened(newVal: 'wallet' | 'orders' | 'settings' | 'support'){
        if(opened === newVal){
            setOpened(null)
        }else{
            setOpened(newVal)
        }
    }
    function isWalletSelected(){
        return isNavSelected('wallet') || isNavSelected('deposit') || isNavSelected('withdraw') || isNavSelected('transactions');
    }
    function isSettingsSelected(){
        return isNavSelected('settings') || isNavSelected('security') || isNavSelected('notifications');
    }
    const container = undefined;
    const drawer = (
        <div>
            <List sx={{
                '& .MuiListItem-root.Mui-selected': {
                    backgroundColor: '#212226',
                    color: '#FCD535',
                    borderLeft: '2px solid #FCD535'
                },
                '&>.MuiListItemButton-root.Mui-selected': {
                    backgroundColor: '#212226',
                    borderLeft: '2px solid #FCD535'
                },
                '&>.MuiListItemButton-root.Mui-selected+.MuiCollapse-root': {
                    backgroundColor: '#212226',
                },
                '&>.MuiListItemButton-root.Mui-selected+.MuiCollapse-root .MuiListItemButton-root.Mui-selected': {
                    backgroundColor: 'inherit',
                    color: '#FCD535'
                }
            }}>
                <ListItem disablePadding selected={isNavSelected('dashboard')}>
                    <ListItemButton component={RouterLink} to={'/dashboard'}>
                        <ListItemIcon>
                            <GridViewRoundedIcon sx={{color: '#FCD535'}} />
                        </ListItemIcon>
                        <ListItemText primary={t('dashboard') as string} />
                    </ListItemButton>
                </ListItem>
                <ListItemButton onClick={() => {changeOpened('wallet')}} selected={isWalletSelected()}>
                    <ListItemIcon>
                        <AccountBalanceWalletRoundedIcon sx={{color: '#FCD535'}} />
                    </ListItemIcon>
                    <ListItemText primary={t('wallet') as string} />
                    {opened === 'wallet' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={opened === 'wallet'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton component={RouterLink} to={'/wallet'} sx={{ pl: 4 }} selected={isNavSelected('wallet')}>
                            <ListItemText primary={t('assets') as string} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={'/deposit'} sx={{ pl: 4 }} selected={isNavSelected('deposit')}>
                            <ListItemText primary={t('deposit') as string} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={'/withdraw'} sx={{ pl: 4 }} selected={isNavSelected('withdraw')}>
                            <ListItemText primary={t('withdraw') as string} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={'/transactions'} sx={{ pl: 4 }} selected={isNavSelected('transactions')}>
                            <ListItemText primary={t('deposit_withdraw_history') as string} />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton onClick={() => {changeOpened('orders')}}>
                    <ListItemIcon>
                        <FormatListBulletedIcon sx={{color: '#FCD535'}} />
                    </ListItemIcon>
                    <ListItemText primary={t('orders') as string} />
                    {opened === 'orders' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={opened === 'orders'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={t('open_orders')} />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={t('orders_history')} />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={t('trade_history')} />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton onClick={() => {changeOpened('settings')}} selected={isSettingsSelected()}>
                    <ListItemIcon>
                        <SettingsIcon sx={{color: '#FCD535'}} />
                    </ListItemIcon>
                    <ListItemText primary={t('settings') as string} />
                    {opened === 'settings' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={opened === 'settings'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton component={RouterLink} to={'/settings'} sx={{ pl: 4 }} selected={isNavSelected('settings')}>
                            <ListItemText primary={t('user_account')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={'/security'} sx={{ pl: 4 }} selected={isNavSelected('security')}>
                            <ListItemText primary={t('security')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={'/notifications'} sx={{ pl: 4 }} selected={isNavSelected('notifications')}>
                            <ListItemText primary={t('notifications')} />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton onClick={() => {changeOpened('support')}}>
                    <ListItemIcon>
                        <HeadsetMicIcon sx={{color: '#FCD535'}} />
                    </ListItemIcon>
                    <ListItemText primary={t('support') as string} />
                    {opened === 'support' ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={opened === 'support'} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={t('send_ticket')} />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary={t('tickets_list')} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </div>
    );

    function handleCloseDrawer(){
        dispatch(closeDrawer())
    }

    return (
        <React.Fragment>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="drawer"
            >
                <BaseDrawer
                    container={container}
                    variant="temporary"
                    open={isDrawerOpen}
                    onClose={handleCloseDrawer}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRadius: 0 },
                    }}
                >
                    {drawer}
                </BaseDrawer>
                <BaseDrawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, position: 'relative', borderRadius: 0, borderRight: 0 },
                    }}
                    open
                >
                    {drawer}
                </BaseDrawer>
            </Box>
        </React.Fragment>
    );
}