import {Box, Grid, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import TradePairs from "../../features/trade-pairs";
import TradeLatest from "../../features/trade-latest";
import TradeOrders from "../../features/trade-orders";
import TradingViewImage from '../../assets/tradingview.png'
import BitcoinImage from '../../assets/bitcoin.png';
import TradeForm from "../../features/trade-form";
import Page from "../Page";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../app/hooks";
import {makeDense} from "../../features/footer/FooterSlice";

export default function Trade(){
    const [t] = useTranslation();
    const dispath = useAppDispatch();
    useEffect(() => {
        window.location.href = 'https://panel.pouyam.com/login';
    });
    useEffect(function(){
        dispath(makeDense())
    }, [])
    return (
        <Page title={t('trade')}>
            <Box sx={{p: 1}}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Paper sx={{mb: 1, py: 1}}>
                            <TradePairs />
                        </Paper>
                        <Paper sx={{py: 1}}>
                            <TradeLatest />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper sx={{p: 1, mb: 1}}>
                            <Stack sx={{px: 1, py: 2}} direction={'row'} spacing={2} justifyContent={'space-between'}>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <Box component={'img'} width={21} height={21} src={BitcoinImage} />
                                    <Box sx={{ml: 1}}>
                                        <Typography>بیت کوین / ریال</Typography>
                                        <Typography color={'text.secondary'}>BTC</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography color={'success.main'}>۹۸۰.۰۵۵.۴۸۳ ریال</Typography>
                                    <Typography>۳.۰۳۳.۷۵۹ USDT ≈</Typography>
                                </Box>
                                <Box>
                                    <Typography color={'text.secondary'}>تغییر 24h</Typography>
                                    <Typography color={'success.main'}>+۰.۴۴ ٪</Typography>
                                </Box>
                                <Box>
                                    <Typography color={'text.secondary'}>بیشترین قیمت</Typography>
                                    <Typography>۳.۰۹۰.۱۶۷</Typography>
                                </Box>
                                <Box>
                                    <Typography color={'text.secondary'}>کمترین قیمت</Typography>
                                    <Typography>۳.۰۸۰.۲۶۰</Typography>
                                </Box>
                                <Box>
                                    <Typography color={'text.secondary'}>حجم معاملات</Typography>
                                    <Typography>۳۴.۷۸۹.۱۲۰.۰۰۰ ریال</Typography>
                                </Box>
                            </Stack>
                        </Paper>
                        <Box sx={{p: 0, m: 1}}>
                            <Box component={'img'} sx={{width: '100%'}} src={TradingViewImage} />
                        </Box>
                        <Paper>
                            <TradeForm />
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper sx={{py: 1, mb: 1}}>
                            <TradeOrders />
                        </Paper>
                    </Grid>

                </Grid>
            </Box>
        </Page>
    );
}