import React from 'react';
import './directed-link-button.css';
import {Button as MuiButton, useTheme} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * The button variant
   */
  variant?: 'contained' | 'outlined' | 'text';
  /**
   * Button contents
   */
  label: string;
  /**
   * Button direction
   */
  dir?: 'ltr' | 'rtl';
  /**
   * Optional click handler
   */
  onClick?: () => void;
}


function DirectionAwareArrowIcon(){
  const theme = useTheme();
  return theme.direction === 'rtl' ? <ArrowBackIcon /> : <ArrowForwardIcon />;
}

/**
 * Primary UI component for user interaction
 */
export const DirectedLinkButton = ({
  primary = false,
  backgroundColor,
  label,
  ...props
}: ButtonProps) => {
  return (
      <MuiButton
          endIcon={<DirectionAwareArrowIcon />}
          {...props}
      >
        {label}
      </MuiButton>
  );
};
