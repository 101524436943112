import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Button, Card, CardContent, Divider, Grid, Stack} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KycBgImg from '../../assets/kyc-bg.png';
import {useTranslation} from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Percent from "./Percent";

export default function Level0(props: {gotoLevel1Form: () => any}){
    const [t] = useTranslation();

    return (
        <Grid container spacing={1}>
           <Grid item xs={12} sm={4} md={3} sx={{height: '100%'}}>
               <Card sx={{
                   backgroundImage: `url(${KycBgImg})`,
                   height: '100%'
               }}>
                   <CardContent>
                       <Box textAlign={'center'} mb={2}>
                           <Typography variant={'h5'} fontWeight={'bold'}>{t('your_current_level_is_zero', {level: 0})}</Typography>
                       </Box>
                       <Box textAlign={'center'}>
                           <Percent level={0} />
                       </Box>
                       <Box>
                           <Typography mb={2} color={'text.secondary'}>{t('your_level_perks')}:</Typography>
                           <Typography mb={1}><HighlightOffIcon color={'error'} sx={{pr: 0.5}} />{t('fiat_deposit')}</Typography>
                           <Typography mb={1}><CheckCircleOutlineIcon color={'success'} sx={{pr: 0.5}} />{t('fiat_withdraw')}</Typography>
                           <Typography mb={1}><CheckCircleOutlineIcon color={'success'} sx={{pr: 0.5}} />{t('crypto_deposit')}</Typography>
                           <Typography mb={1}><CheckCircleOutlineIcon color={'success'} sx={{pr: 0.5}} />{t('crypto_withdraw')}</Typography>
                       </Box>
                   </CardContent>
               </Card>
           </Grid>
           <Grid item xs={12} sm={8} md={9} sx={{height: '100%'}}>
               <Card sx={{
                   height: '100%'
               }}>
                   <CardContent sx={{mb: 10}}>
                      <Stack direction={'row'} justifyContent={'space-between'}>
                          <Box>
                              <Typography><InfoIcon /> {t('user_levels_help')}</Typography>
                          </Box>
                          <Box>
                              <Button variant={'text'} color={'inherit'}><PlayCircleIcon /> {t('user_levels_tutorial')}</Button>
                          </Box>
                      </Stack>
                       <Divider sx={{my: 1}} />
                       <Typography variant={'body1'}>
                           ضمن خوش‌آمدگویی به شما کاربر محترم پویام، به اطلاع می‌رساند جهت فراهم آوردن محیطی مطمئن برای تمامی کاربران سایت،‌ برای واریز و برداشت وجه ریالی نیاز به تکمیل فرایند احراز هویت وجود دارد. لطفا پس از مطالعه توضیحات زیر،‌ برای شروع فعالیت مالی در سایت پویام، فرایند احراز هویت خود را آغاز نمایید.
                       </Typography>
                       <Typography variant={'body1'}>
                           احراز هویت پایه‌ای با تایید شماره تلفن همراه و ارسال مدارک هویتی (کارت ملی)، ورود آدرس محل سکونت و تلفن ثابت جهت تایید آن‌هاصورت می‌گیرد. با انجام این سطح از احراز هویت می‌توانید اقدام به واریز وجه (رمزارز)، معامله و برداشت وجه (رمزارز) نمایید.
                       </Typography>
                       <Typography variant={'body1'}>
                           همچنین لازم به توضیح است که با توجه به قوانین بانکی کشور و امکان بلوکه شدن وجوه واریزشده از کارت‌های بانکی مسروقه،‌ کاربران جدید که به تازگی اقدام به ثبت و واریز وجه ازطریق یک کارت بانکی می‌کنند، به مدت دو روز کاری برداشت اول کاربران به طول خواهد انجامید. این تاخیر صرفا جهت تایید اصالت کارت بانکی بوده و درطول این مدت کاربران می‌توانند به هر میزان با وجه واریزی خود در سیستم معامله نمایند و آن را تبدیل به سایر ارزهای دیجیتال کنند و محدودیت مذکور صرفا مربوط به خارج کردن وجه واریزی از سیستم به مدت دو روز کاری است. طبق ماده ۵۶ قانون آیین‌نامه اجرایی قانون مبارزه با پولشویی، احراز هویتبه کمک مدارک مشتریان جهت جلوگیری است
                       </Typography>
                       <Box sx={{my: 4}} textAlign={'center'}>
                           <Button onClick={() => props.gotoLevel1Form()} variant={'contained'} size={'large'}>{t('upgrade_to_next_level')}</Button>
                       </Box>
                   </CardContent>
               </Card>
           </Grid>
        </Grid>
    );
}