import {Alert, Box, Divider, Paper, Stack} from "@mui/material";
import {ErrorOutline} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import client from "../../config/client";
import {number_format} from "../../config/helpers";
import './CryptoTips.css';
import {useAppSelector} from "../../app/hooks";
import {selectSymbol} from "./withdrawFormSlice";

interface AssetInterface{
    total: string;
    trading: string;
}
export default function CryptoTips(props: {symbol?: string}){
    const [t] = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [asset, setAsset] = useState<null | AssetInterface>(null);
    const symbol = useAppSelector(selectSymbol);

    useEffect(() => {
        if(!symbol) return;
        setLoading(true);
        client.get(`/v4/assets/crypto-balance/${symbol}`).then((resp)=>{
            setAsset(resp.data.data);
        })
    }, [symbol])

    return (
        <Paper className={loading ? 'crypto-tips loading' : ''} sx={{bgcolor: '#212226', p: 2}}>
            <Stack spacing={1}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>{t('total_balance')}:</Box>
                    <Box>
                        <Box component={'span'} sx={{mx: 1}}>{asset && number_format(asset.total)}</Box>
                        <Box component={'span'}>{t('toman')}</Box>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>{t('trading')}:</Box>
                    <Box>
                        <Box component={'span'} sx={{mx: 1}}>{asset && number_format(asset.trading)}</Box>
                        <Box component={'span'}>{t('toman')}</Box>
                    </Box>
                </Box>
            </Stack>
            <Divider sx={{my: 2}} />
            <Alert
                sx={{bgcolor: 'rgba(254, 176, 25, 0.1)', color: '#FEB019'}}
                icon={<ErrorOutline fontSize="inherit" />}
                severity="warning"
                variant="filled">{t('crypto_deposit_tips')}</Alert>
            <ul style={{paddingRight: '16px'}}>
                <li>{t('crypto_rule_1')}</li>
            </ul>
        </Paper>
    );
}