import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockIcon from "@mui/icons-material/Lock";
import {Chip} from "@mui/material";


export default function SecureLink(props: {url: string}){
    function SslLink(){
        return (
            <Box component='span'><Box component='span' sx={{color: '#58BC7C'}}>https://</Box>{props.url}</Box>
        );
    }
    return (
        <Chip
            dir='ltr'
            sx={{bgcolor: 'rgba(88, 188, 124, 0.3)', color: 'white', height: '34px!important'}}
            avatar={<Avatar sx={{bgcolor: '#58BC7C', ml: '0!important', width: '34px!important', height: '34px!important'}}><LockIcon sx={{ fontSize: 18 }} /></Avatar>}
            label={<SslLink />}
        />
    );
}