import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {makeDense} from "../footer/FooterSlice";
import {Grid, Paper} from "@mui/material";
import TextSwitch from "../../components/TextSwitch";
import FiatForm from "./FiatForm";
import CrypoForm from "./CryptoForm";
import {selectAmount, selectType, setTypeToCrypto, setTypeToFiat} from "./withdrawFormSlice"
import FiatTips from "./FiatTips";
import CryptoTips from "./CryptoTips";


export default function WithdrawForm(){
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const type = useAppSelector(selectType);
    const [value, setValue] = useState(0);

    useEffect(function(){
        dispatch(makeDense())
    }, [])

    function handleChangeValue(v: 0 | 1){
        if(v === 1){
            dispatch(setTypeToCrypto());
        }else{
            dispatch(setTypeToFiat());
        }
        setValue(v);
    }

    return (
        <Paper sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextSwitch
                        offLabel={t('fiat')}
                        onLabel={t('withdraw_coin')}
                        handleChange={(v)=>handleChangeValue(v)}
                        defaultValue={type === 'fiat' ? 0 : 1}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FiatForm value={value} index={0} />
                    <CrypoForm value={value} index={1} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        type === 'fiat' ? <FiatTips /> : <CryptoTips />
                    }
                </Grid>
            </Grid>
        </Paper>
    );
}